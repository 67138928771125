import { 
    GET_INVITATION_TEMPLATES_FAIL, 
    GET_INVITATION_TEMPLATES_LOADING, 
    GET_INVITATION_TEMPLATES_SUCCESS, 
    POST_SET_EVENT_TEMPLATE_FAIL, 
    POST_SET_EVENT_TEMPLATE_LOADING,
    POST_SET_EVENT_TEMPLATE_SUCCESS,
    POST_TEMPLATE_EDITED_COVER_FAIL,
    POST_TEMPLATE_EDITED_COVER_LOADING,
    POST_TEMPLATE_EDITED_COVER_SUCCESS} from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";


export const getInvitationTemplatessAct = ({id}) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_INVITATION_TEMPLATES_LOADING
    })

    AxiosHelper.get(`/templates/`).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_INVITATION_TEMPLATES_SUCCESS,
                payload:res.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_INVITATION_TEMPLATES_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}

export const setEventTemplatessAct = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_SET_EVENT_TEMPLATE_LOADING
    })

    AxiosHelper.post(`/templates/set-to-event`,{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_SET_EVENT_TEMPLATE_SUCCESS,
                payload:res.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_SET_EVENT_TEMPLATE_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}

export const uploadTemplateCover = (formData) => async (dispatch) => {
    dispatch({
        type: POST_TEMPLATE_EDITED_COVER_LOADING
    });

    try {

        const response = await AxiosHelper.post('/templates/edited-cover', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        dispatch({
            type: POST_TEMPLATE_EDITED_COVER_SUCCESS,
            payload: response.data.data
        });

        return response.data; // Return the response data
    } catch (error) {
        dispatch({
            type: POST_TEMPLATE_EDITED_COVER_FAIL,
            payload: error.response
                ? error.response.data
                : { error: 'Something went wrong, try again' }
        });
        throw error; // Rethrow the error to be caught by the caller
    }
};
