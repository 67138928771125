import { 
    GET_EVENT_MENU_ITEMS_FAIL,
    GET_EVENT_MENU_ITEMS_LOADING,
    GET_EVENT_MENU_ITEMS_SUCCESS,
    GET_EVENT_SELECTED_MENU_ITEMS_FAIL,
    GET_EVENT_SELECTED_MENU_ITEMS_LOADING,
    GET_EVENT_SELECTED_MENU_ITEMS_SUCCESS,
    GET_FOOD_MENU_CATEGORY_FAIL, 
    GET_FOOD_MENU_CATEGORY_LOADING, 
    GET_FOOD_MENU_CATEGORY_SUCCESS, 
    GET_FOOD_MENU_ITEMS_FAIL, 
    GET_FOOD_MENU_ITEMS_LOADING, 
    GET_FOOD_MENU_ITEMS_SUCCESS, 
    POST_EVENT_MENU_ITEM_FAIL, 
    POST_EVENT_MENU_ITEM_LOADING, 
    POST_EVENT_MENU_ITEM_SUCCESS, 
    POST_FOOD_MENU_CATEGORY_FAIL, 
    POST_FOOD_MENU_CATEGORY_LOADING, 
    POST_FOOD_MENU_CATEGORY_SUCCESS, 
    POST_FOOD_MENU_ITEM_FAIL, 
    POST_FOOD_MENU_ITEM_LOADING,
    POST_FOOD_MENU_ITEM_SUCCESS,
    POST_PLACE_ORDER_FAIL,
    POST_PLACE_ORDER_LOADING,
    POST_PLACE_ORDER_SUCCESS} from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";

export const createFoodMenuCategories = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_FOOD_MENU_CATEGORY_LOADING
    })

    AxiosHelper.post('/foodMenu/categories',{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_FOOD_MENU_CATEGORY_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_FOOD_MENU_CATEGORY_FAIL,
                payload:err?.response?.data,
            });
        }
    )
}

export const getFoodMenuCategories = ({}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_FOOD_MENU_CATEGORY_LOADING
        })
    
        AxiosHelper.get(`/foodMenu/categories`).then(
            (res) => {
    
                dispatch({
                    type:GET_FOOD_MENU_CATEGORY_SUCCESS,
                    payload:res.data.data,
                });

                resolve(res.data.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_FOOD_MENU_CATEGORY_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const createFoodMenuItem = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_FOOD_MENU_ITEM_LOADING
    })

    AxiosHelper.post('/foodMenu/items',{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_FOOD_MENU_ITEM_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_FOOD_MENU_ITEM_FAIL,
                payload:err?.response?.data,
            });
        }
    )
}

export const getFoodMenuItems = ({}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_FOOD_MENU_ITEMS_LOADING
        })
    
        AxiosHelper.get(`/foodMenu/items`).then(
            (res) => {
    
                dispatch({
                    type:GET_FOOD_MENU_ITEMS_SUCCESS,
                    payload:res.data.data,
                });
                
                resolve(res.data.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_FOOD_MENU_ITEMS_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const getEventMenuItems = (params) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_EVENT_MENU_ITEMS_LOADING
        })
    
        AxiosHelper.get(`/foodMenu/event-menu-items`,{
            params:params
        }).then(
            (res) => {
    
                dispatch({
                    type:GET_EVENT_MENU_ITEMS_SUCCESS,
                    payload:res.data.data,
                });
                
                resolve(res.data.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_EVENT_MENU_ITEMS_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const createEventMenuItems = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_EVENT_MENU_ITEM_LOADING
        })
    
        AxiosHelper.post(`/foodMenu/event-menus`,{payload}).then(
            (res) => {
    
                dispatch({
                    type:POST_EVENT_MENU_ITEM_SUCCESS,
                    payload:res.data.data,
                });
                
                resolve(res.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_EVENT_MENU_ITEM_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const getEventSelectedMenuItems = (params) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_EVENT_SELECTED_MENU_ITEMS_LOADING
        })
    
        AxiosHelper.get(`/foodMenu/event-selected-menu-items`,{
            params:params
        }).then(
            (res) => {
    
                dispatch({
                    type:GET_EVENT_SELECTED_MENU_ITEMS_SUCCESS,
                    payload:res.data.data,
                });
                
                resolve(res.data.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_EVENT_SELECTED_MENU_ITEMS_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const createEventMenuOrder = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_PLACE_ORDER_LOADING
        })
    
        AxiosHelper.post(`/foodMenu/place-order`,{payload}).then(
            (res) => {
    
                dispatch({
                    type:POST_PLACE_ORDER_SUCCESS,
                    payload:res.data.data,
                });
                
                resolve(res.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_PLACE_ORDER_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}