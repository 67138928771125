import { faClipboardList, faEdit, faPlus, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import EventTables from '../Pages/EventTables';

const EventTablesAction = ({
    event,
    selectedCategory,
    setSelectedCategory
})=>{
    const [modal, setModal] = useState(false);

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const onCloseModal = ()=>{}

    return(
        <React.Fragment>
            <Modal
                onClosed={(e)=>onCloseModal()}
                className='custom-modal rounded-0 border-0' 
                size='sm' 
                isOpen={modal}
                toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        <FontAwesomeIcon icon={event?.id? faTable:faTable}/> 
                        <span className='ms-1'>Event table</span>
                    </h5>
                    {/* <span className='ms-3 fs-12'>{selectedCategory?.name && `${selectedCategory?.name}`}</span> */}
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <EventTables selectedEvent={event}/>
                </ModalBody>
                {/* <ModalFooter></ModalFooter> */}
            </Modal>

            <Button
                color="success"
                outline
                className="icon-holder-btn ms-1"
                title="Event table"
                onClick={togOpenModal}
            >
                <FontAwesomeIcon icon={faTable} />
            </Button>
        </React.Fragment>
    )
}

export default EventTablesAction;