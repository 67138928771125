import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { GlobalStore } from '../../StateStores';
import { shareEventInvitationCardsAct } from '../../Actions/Masters/EventCategoriesActs';

const ShareButtons = ({imageUrl, ticket, onShareSuccess})=>{
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent('Check out this invitation: ' + imageUrl)}`;
    const emailUrl = `mailto:?subject=Invitation&body=Check out this invitation: ${imageUrl}`;

    const {
        MasterDispatch,
        MasterState:{
            shareEventCard:{data,error,loading},
        }} = useContext(GlobalStore);

    const handleShareClick = (type) => {
        // Simulate a success callback
        // onShareSuccess(type);

        shareEventInvitationCardsAct(ticket)(MasterDispatch)((res)=>{
            
            if(res.status === 'Success'){

                const userConfirmed = window.confirm('Click OK to open WhatsApp and share the invitation card. Please download the image from the link provided.');
                if (userConfirmed) {
                    // window.open(data.whatsappLink, '_blank');
                    window.open(data.fileUrl, '_blank');
                }

            }
        })

    };

    return(
        <React.Fragment>
            <div className=''>
                <Button 
                    color="success" 
                    size='sm'
                    // href={whatsappUrl} 
                    // target="_blank"
                    className='rounded-0 yellow-background fs-color-black border-0'
                    onClick={() => handleShareClick('whatsapp')}>
                        <span className="icon-circle"><FontAwesomeIcon icon={faWhatsapp}/></span>
                        <span className='ms-1'>Share via WhatsApp</span>
                </Button>
                <Button 
                    color="primary" 
                    hidden
                    size='sm'
                    href={emailUrl} 
                    className='ms-1 rounded-0 yellow-background fs-color-black border-0'
                    target="_blank"
                    onClick={() => handleShareClick('email')}>
                        <span className="icon-circle"><FontAwesomeIcon icon={faEnvelope}/></span>
                        <span className='ms-1'>Share via Email</span>
                </Button>
            </div>
        </React.Fragment>
    )
}

export default ShareButtons;