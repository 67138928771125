import { faBan, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Spinner, Table } from 'reactstrap';
import AxiosHelper from '../../../Helpers/AxiosHelper';
import InvitationPreview from '../InvitationPreview';
import { GlobalStore } from '../../../StateStores';
import { generateEventInvitationCardsAct } from '../../../Actions/Masters/EventCategoriesActs';
import { toast } from 'react-toastify';

const SoldTicketsList = ({soldTickets,eventData })=>{
    const [modal, setModal] = useState(false);
    const [invitationUrl, setInvitationUrl] = useState('');
    const [activeTicket, setActiveTicket] = useState({});

    const {
        MasterDispatch,
        MasterState:{
            generateInvitationCard:{data,error,loading}
        }} = useContext(GlobalStore);

    const toggleModal = () => setModal(!modal);

    const handleGenerateInvitation = async (ticket) => {

        setActiveTicket(ticket)

        generateEventInvitationCardsAct({
            event_id:eventData.id,
            sold_by:ticket.sold_by,
            user_ticket_id:ticket.user_ticket_id})(MasterDispatch)((res)=>{
            if(res.status === 'Failed'){
                toast.error(res.message);
            }else{
                // Create a URL for the generated image blob
                const url = URL.createObjectURL(new Blob([res], { type: 'image/png' }));
                //Set URL for card
                setInvitationUrl(url);
                //Open the image/invitation viewer
                toggleModal();
            }
        })
    };

    return(
        <React.Fragment>
            <InvitationPreview ticketData={activeTicket} isLoading={loading} isOpen={modal} toggle={toggleModal} invitationUrl={invitationUrl}/>
            <Card className='border-0 mt-3'>
                <CardBody className='bg-light-f1 rounded-1'>
                    <div className='d-flex justify-content-between'>
                        <div style={{width:'10%'}}>#</div>
                        <div style={{width:'35%'}}>User</div>
                        <div style={{width:'25%'}} className='text-center'>Ticket Type</div>
                        <div style={{width:'20%'}} className='text-center'>Quantity</div>
                        <div style={{width:'10%'}}></div>
                    </div>
                </CardBody>
            </Card>
            

            {(soldTickets || []).map((ticket, index) => (
                <Card className='border-0 mt-3 boxshadow' key={ticket.user_ticket_id}>
                    <CardBody>
                        <div className='d-flex justify-content-between'>
                            <div style={{width:'10%'}}>{index + 1}</div>
                            <div className="text-wrap" style={{width:'35%'}}>
                                <small className='text-primary'>{ticket.sold_by}</small>
                                <div className='d-flex'>
                                    <small className="text-muted">{moment(ticket.purchased_at).format('LL HH:mm:ssa')}</small>
                                    {/* <small className="text-info text-muted ms-1">({moment(ticket.purchased_at).startOf().fromNow()})</small> */}
                                </div>
                            </div>
                            <div className='text-center' style={{width:'25%'}}>
                                <div><strong>{ticket.type_name}</strong></div>
                                <small className='text-muted fs-11'>{ticket?.card_code}</small>
                            </div>
                            <div style={{width:'20%'}} className='text-center'>{ticket.quantity}</div>
                            <div style={{width:'10%'}}>
                                <div className='d-flex justify-content-end'>

                                    {activeTicket.user_ticket_id === ticket.user_ticket_id && loading && (
                                        <div className='d-flex justify-content-center p-3'>
                                            <Spinner color='dark' size="sm"/>
                                        </div>
                                    )}
                                    
                                    {!loading && (
                                        <Button
                                            color='dark'
                                            outline
                                            className='rounded-0 ms-1'
                                            title='Ticket type'
                                            size='sm'
                                            onClick={(e)=>handleGenerateInvitation(ticket)}
                                            >
                                                <FontAwesomeIcon icon={faIdCard}/>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            ))}

            {soldTickets.length === 0 && (
                <Card className="task-card border-0 mt-2">
                    <CardBody>
                        <small className='text-danger'>
                            <FontAwesomeIcon icon={faBan}/> No Solid Ticket yet
                        </small>
                    </CardBody>
                </Card>
            )}

        </React.Fragment>
    )
}

export default SoldTicketsList;