import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { toast } from 'react-toastify';
import { createEventMenuItems, getEventMenuItems } from '../../../Actions/Masters/FoodMenuActs';
import { Button, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const EventMenuManager = ({selectedEvent})=>{
    const [menuItems, setMenuItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});

    const {
        FoodMenuMasterDispatch,
        FoodMenuMasterState:{
            createEventMenuItem:{data:saveData,error:saveError,loading:saveLoading},
            getEventMenuItem:{data:categories,error,loading},
        }} = useContext(GlobalStore);


    useEffect(()=>{
        const loadEventMenuItems = async ()=>{
            try {
                const response = await getEventMenuItems({event_id:selectedEvent?.id})(FoodMenuMasterDispatch);
                setMenuItems(response)

                 // Initialize selectedItems state with current links
                const selected = {};
                response.forEach((category) => {
                    category.items.forEach((item) => {
                        selected[item.id] = item.isLinked;
                    });
                });
                setSelectedItems(selected);

            } catch (error) {
                toast.error(error.message)
            }
        }

        if(selectedEvent?.id !== undefined) loadEventMenuItems()
    },[selectedEvent])


    const handleCheckboxChange = (itemId) => {
        setSelectedItems({
            ...selectedItems,
            [itemId]: !selectedItems[itemId],
        });
    };

    const handleSave = async () => {
        const linkedItems = Object.keys(selectedItems).filter((id) => selectedItems[id]);

        try {
            const form = {
                event_id:selectedEvent?.id,
                menu_item_ids: linkedItems.map((id) => parseInt(id))
            }
            const response = await createEventMenuItems(form)(FoodMenuMasterDispatch);
            toast.success(response.message)
        } catch (error) {
            toast.error(error.message)
        }

    };

    return(
        <React.Fragment>
            <h6>Manage Menu for Event</h6>
            <hr/>

            {menuItems.map((category) => (
                <div key={category?.category_id}>
                    <hr/>
                    <h6>{category?.category_name}</h6>
                    {category.items.map((item) => (
                        item.id !== null ? (
                            <FormGroup check key={item.id}>
                                <Label check>
                                    <Input
                                    type="checkbox"
                                    id={`item-${item?.id}`}
                                    checked={selectedItems[item?.id] || false}
                                    onChange={() => handleCheckboxChange(item?.id)}
                                    />
                                    <div>{`${item?.item_name}`}</div>
                                    {/* <small className='fs-12 text-muted'>{`${item.item_name}`}</small> */}
                                </Label>
                            </FormGroup>
                        ):(
                            <small className='text-muted'>No item</small>
                        )
                    ))}
                </div>
            ))}

            <hr/>
            <div className='d-flex justify-content-end mt-2'>
                <Button 
                    // disabled={!formValid}
                    onClick={handleSave}
                    color="dark" type="submit" className="custom-button rounded-0">
                        {saveLoading && <Spinner size="sm"/>}
                        {!saveLoading && <FontAwesomeIcon icon={faSave}/>} 
                        <span className='ms-1'>Save Menu</span>
                </Button>
            </div>

        </React.Fragment>
    )
}

export default EventMenuManager;