import React from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { Cell, Label, Pie, PieChart, Tooltip } from 'recharts';

const COLORS = ['#82ca9d', '#ffbb28', '#ff4d4f', '#d3d3d3'];

const RSVPCard = ({rsvpData,totals})=>{

    const renderCustomizedLabel = ({ viewBox }) => {
        const { cx, cy } = viewBox;
        return (
            <g>
            <text x={cx} y={cy - 10} textAnchor="middle" dominantBaseline="central" style={{ fontSize: '14px', fill: '#999' }}>
                RSVP's
            </text>
            <text x={cx} y={cy + 10} textAnchor="middle" dominantBaseline="central" style={{ fontSize: '24px', fill: '#333' }}>
                {totals.confirmed + totals.waiting + totals.declined + totals.notResponded}
            </text>
            </g>
        );
    };

    return(
        <React.Fragment>
            <Card className="mb-3 border-0 boxshadow">
                <CardBody>
                    <div className="d-flex justify-content-center">
                        <PieChart width={200} height={200}>
                            <Pie
                                data={rsvpData}
                                cx={100}
                                cy={100}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="value"
                            >
                                
                                <Label content={renderCustomizedLabel} position="center" />
                                {rsvpData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </div>
                </CardBody>
                <CardFooter>
                    <div className="rsvp-totals">
                        <div className="rsvp-status d-flex justify-content-between">
                            <div style={{ color: COLORS[0] }}>{totals.confirmed}<br />Confirmed</div>
                            <div style={{ color: COLORS[1] }}>{totals.waiting}<br />Waiting</div>
                            <div style={{ color: COLORS[2] }}>{totals.declined}<br />Declined</div>
                            <div style={{ color: COLORS[3] }}>{totals.notResponded}<br />Not Responded</div>
                        </div>
                    </div>
                </CardFooter>
            </Card>
        </React.Fragment>
    )
}

export default RSVPCard;