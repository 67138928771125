import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const RolePermissionCheckbox = ({ rolePermission, permission, checkedPermissions, setCheckedPermissions }) => {
    
    const handleChange = (e) => {
        const { value, checked, dataset } = e.target;
        const perm_id = parseInt(value);
        const r_perm_id = parseInt(dataset.r_perm_id);
    
        setCheckedPermissions((prev) =>
            checked
                ? [...prev, { perm_id, r_perm_id }]
                : prev.filter((perm) => !(perm.perm_id === perm_id && perm.r_perm_id === r_perm_id))
        );
    };
    

    return (
        <div className="col-md-4">
            <FormGroup check>
                <Label check>
                    <Input
                        type="checkbox"
                        value={permission.perm_id}
                        // checked={checkedPermissions.includes(permission.perm_id)}
                        data-r_perm_id={rolePermission.r_perm_id}
                        checked={checkedPermissions.some(
                            (perm) => perm.perm_id === permission.perm_id && perm.r_perm_id === rolePermission.r_perm_id
                        )}
                        onChange={handleChange}
                    />
                    {permission.perm_label}
                </Label>
            </FormGroup>
        </div>
        
    );
};

export default RolePermissionCheckbox;
