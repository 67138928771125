import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import EventCreate from '../Pages/Events/EventCreate';

const EditActionButton = ({
    event,
    selectedCategory, 
    setSelectedCategory,})=>{
    const [modal, setModal] = useState(false);

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const onCloseModal = ()=>{}

    return(
        <React.Fragment>
            <Modal
            onClosed={(e)=>onCloseModal()}
            className='rounded-0 border-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        <FontAwesomeIcon icon={event?.id? faEdit:faPlus}/> 
                        <span className='ms-1'>{event?.id? 'Edit':'Create'} Event</span> {selectedCategory?.name && `- ${selectedCategory?.name}`}</h5>
                </ModalHeader>
                <ModalBody>
                    <Row className='g-3 p-3'>
                        <Col>
                            <EventCreate
                                selectedCategory={event}
                                setSelectedCategory={setSelectedCategory}
                                ctrlModal={togOpenModal}
                                selectedEvent={event}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>

            {/* Caller button */}
            <Button
                color="primary"
                outline
                className="icon-holder-btn"
                title="Edit event"
                onClick={togOpenModal}
            >
                <FontAwesomeIcon icon={faEdit} />
            </Button>
        </React.Fragment>
    )
}

export default EditActionButton;