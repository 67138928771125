export default {
    authState:{
        isLoggedIn:false,
        data:{},
        error:null,
        loading:false,
    },searchUsersState:{
        data:{},
        error:null,
        loading:false,
    },getUsersStates:{
        data:[],
        error:null,
        loading:false,
    },getUserGroupsStates:{
        data:[],
        error:null,
        loading:false,
    },getUserGroupsRightStates:{
        data:[],
        error:null,
        loading:false,
    },postUserGroupsStates:{
        data:[],
        error:null,
        loading:false,
    },postUserStates:{
        data:[],
        error:null,
        loading:false,
    },postBulkUserUploadState:{
        data:[],
        error:null,
        loading:false,
    },deactivateAccntState:{
        data:[],
        error:null,
        loading:false,
    }
}