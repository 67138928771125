import { faList, faMapMarkerAlt, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Button, Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import { SERVER_BASE_URL } from '../../Helpers/AxiosHelper';
import { NavLink } from 'react-router-dom';

const EventCategoryCard = ({category,setSelectedCategory,togOpenModal})=>{
    return(
        <React.Fragment>
            <Card className="event-card boxshadow">
                <div className="event-card-image" 
                    style={{ backgroundImage: `url(${SERVER_BASE_URL}/cdn-template-covers/${encodeURIComponent(category.cover)})` }}>
                    <Badge 
                        color="danger" 
                        className="event-category-badge text-capitalize yellow-background fs-color-black">{category.status}</Badge>
                    <Button className="event-save-button" hidden>
                        <FontAwesomeIcon icon={faUserPlus} />
                    </Button>

                    <div className="event-card-body-container">
                        <CardBody className='bg-white rounded-2'>
                            <CardTitle tag="h5" className='mb-4'>{category.name}</CardTitle>
                            <CardSubtitle hidden className="mb-4 text-muted">By {category.organizer}</CardSubtitle>
                            <CardText>
                                <Button 
                                color='success'
                                size='sm'
                                outline
                                className='w-100 yellow-backgroundd fs-color-black border-1 mb-2'
                                onClick={(e)=>{
                                    togOpenModal()
                                    setSelectedCategory(category)
                                }}>
                                    <div className='d-flex justify-content-between'>
                                        <span className="icon-circle"><FontAwesomeIcon icon={faPlus}/></span>
                                        <span className='ms-1'>Create event</span>
                                    </div>
                                </Button>

                                <NavLink to="/dashboard/events">
                                    <Button 
                                        color='success' 
                                        size='sm'
                                        outline
                                        className='w-100 yellow-backgroundd fs-color-black border-1'>
                                        <div className='d-flex justify-content-between'>
                                            <span className="icon-circle"><FontAwesomeIcon icon={faList}/></span>
                                            <span className='ms-1'>Events</span>
                                        </div>
                                    </Button>
                                </NavLink>
                            </CardText>
                        </CardBody>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    )
}

export default EventCategoryCard;