import { 
    CLEAR_POST_BROADCAST_STATE, 
    POST_BROADCAST_FAIL, 
    POST_BROADCAST_LOADING, 
    POST_BROADCAST_SUCCESS } from "../Constants/ActionTypes";

const Broadcasts = (state,{type,payload}) => {

    switch (type) {
            case POST_BROADCAST_LOADING:
                return{
                    ...state,
                    sendBroadCast:{
                        ...state.sendBroadCast,
                        loading:true,
                    }
                };
            case POST_BROADCAST_SUCCESS:
                return{
                    ...state,
                    sendBroadCast:{
                        ...state.sendBroadCast,
                        loading:false,
                        data:payload,
                    }
                };
            case POST_BROADCAST_FAIL:
                return{
                    ...state,
                    sendBroadCast:{
                        ...state.sendBroadCast,
                        loading:false,
                        error:payload
                    }
                };
        
            case CLEAR_POST_BROADCAST_STATE:
                return {
                    ...state,
                    sendBroadCast:{
                        ...state.sendBroadCast,
                        loading:false,
                        data:null,
                        error:null
                    }
                };

        default:
           return false;
    }

}

export default Broadcasts;