import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import LoginForm from './LoginForm';
import SignUpForm from '../Users/SignUpForm';

const Login = () => {
    // State to toggle between sign in and sign up
    const [isSignIn, setIsSignIn] = useState(true);

    return (
        <React.Fragment>
            <div className="App">
                <Container fluid className="vh-100 d-flex align-items-center justify-content-center gx-0">
                    <Row className="w-100 gx-0" style={{ height: '100%' }}>
                        {/* Left Section */}
                        <Col md="8" className="p-0 black-background d-flex align-items-center">
                            <div className="cover-images w-100 d-flex justify-content-center h-100">
                                <img src="/logo-black-bg.png" alt="Cover" className="img-fluid h-100" />
                            </div>
                        </Col>

                        {/* Right Section with Form */}
                        <Col md="4" className="p-0 yellow-background d-flex flex-column align-items-center justify-content-center">
                            <div className="w-100 text-center mb-4a">
                                {/* Toggle between Sign In and Sign Up */}
                                {/* <Button 
                                    color="black" 
                                    onClick={() => setIsSignIn(true)}
                                    className={isSignIn ? 'active-tab' : ''}
                                >
                                    Sign In
                                </Button>
                                <span>|</span>
                                <Button 
                                    color="black" 
                                    onClick={() => setIsSignIn(false)}
                                    className={!isSignIn ? 'active-tab' : ''}
                                >
                                    Sign Up
                                </Button> */}
                            </div>

                            {/* Conditionally render the LoginForm or SignUpForm */}
                            {isSignIn ? <LoginForm /> : <SignUpForm />}

                            <div className="mt-4 text-center">
                                {/* Display alternate action link */}
                                {isSignIn ? (
                                    <p>
                                        Don't have an account?{' '}
                                        <Button color="black" onClick={() => setIsSignIn(false)}>
                                            Sign Up
                                        </Button>
                                    </p>
                                ) : (
                                    <p>
                                        Already have an account?{' '}
                                        <Button color="black" onClick={() => setIsSignIn(true)}>
                                            Sign In
                                        </Button>
                                    </p>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;