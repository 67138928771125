import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../Layouts/Header';
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GlobalStore } from '../../../StateStores';
import { getEventCategories } from '../../../Actions/Masters/EventCategoriesActs';
import EventCreate from '../Events/EventCreate';
import EventCategoryCard from '../../Common/EventCategoryCard';

const Dashboard = ()=>{
    const [modal, setModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    
    const {
        MasterDispatch,
        MasterState:{
            getEventCategories:{data,error,loading}
        }} = useContext(GlobalStore);

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    useEffect(()=>{
        function fetchCategoriesData(){
            getEventCategories()(MasterDispatch)((res)=>{})
        }
        fetchCategoriesData();
    },[])

    return(
        <React.Fragment>
            <Modal  className='rounded-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'><FontAwesomeIcon icon={faPlus}/> <span>Create Event</span> {selectedCategory?.name && `- ${selectedCategory?.name}`}</h5>
                </ModalHeader>
                <ModalBody>
                    <Row className='g-3 p-3'>
                        <Col>
                            <EventCreate 
                                selectedCategory={selectedCategory} 
                                setSelectedCategory={setSelectedCategory}
                                ctrlModal={togOpenModal}/>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
            <Header/>
            <div className="wrapper-top"></div>
            <Container>
                <Row className='mb-3'>
                    <Col className='text-center'>
                        <h5>Event categories</h5>
                        <small className='text-muted'>
                            Discover your next adventure with <strong>Digikadi!</strong> From weddings to corporate events, and entertainment to seminars, explore our diverse categories and find the perfect event for you.
                        </small>
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col className='d-flex justify-content-center'>
                        {loading && <Spinner size="sm"/>}
                    </Col>
                </Row>

                <Row className='gx-0s mt-2'>
                    {(data || []).map((category,index)=>(
                        <Col key={category.id}>
                            <EventCategoryCard 
                                category={category} 
                                togOpenModal={togOpenModal} 
                                setSelectedCategory={setSelectedCategory} />
                        </Col>
                    ))}
                </Row>

            </Container>
        </React.Fragment>
    )
}

export default Dashboard;