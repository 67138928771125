import { 
    CLEAR_EVENT_TICKET_CARD_INFO_STATE,
    GET_EVENT_APPROVAL_REQUEST_FAIL,
    GET_EVENT_APPROVAL_REQUEST_LOADING,
    GET_EVENT_APPROVAL_REQUEST_SUCCESS,
    GET_EVENT_CATEGORIES_FAIL, 
    GET_EVENT_CATEGORIES_LOADING,
     GET_EVENT_CATEGORIES_SUCCESS, 
     GET_EVENT_DETAILS_FAIL, 
     GET_EVENT_DETAILS_LOADING, 
     GET_EVENT_DETAILS_SUCCESS, 
     GET_EVENT_FAIL, 
     GET_EVENT_LOADING, 
     GET_EVENT_SUCCESS, 
     GET_EVENT_TICKET_CARD_INFO_FAIL, 
     GET_EVENT_TICKET_CARD_INFO_LOADING, 
     GET_EVENT_TICKET_CARD_INFO_SUCCESS, 
     GET_GENERATE_INVITATION_CARD_FAIL, 
     GET_GENERATE_INVITATION_CARD_LOADING, 
     GET_GENERATE_INVITATION_CARD_SUCCESS, 
     GET_SHARE_EVENT_CARD_FAIL, 
     GET_SHARE_EVENT_CARD_LOADING, 
     GET_SHARE_EVENT_CARD_SUCCESS, 
     POST_EVENT_FAIL, 
     POST_EVENT_LOADING,
     POST_EVENT_SUCCESS,
     POST_EVENT_TICKET_CARD_VERIFICATION_FAIL,
     POST_EVENT_TICKET_CARD_VERIFICATION_LOADING,
     POST_EVENT_TICKET_CARD_VERIFICATION_SUCCESS,
     POST_RSVP_FAIL,
     POST_RSVP_LOADING,
     POST_RSVP_SUCCESS} from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";

export const getEventCategories = () =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_EVENT_CATEGORIES_LOADING
    })

    AxiosHelper.get('/event/category/').then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_EVENT_CATEGORIES_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_EVENT_CATEGORIES_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}

export const createEvents = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_EVENT_LOADING
    })

    AxiosHelper.post('/event/',{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_EVENT_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_EVENT_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}

export const getEventsAct = ({approval=0}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_EVENT_LOADING
        })
    
        AxiosHelper.get('/event/',{
            params:{
                approval:approval
            }
        }).then(
            (res) => {
                resolve(res.data);
    
                dispatch({
                    type:GET_EVENT_SUCCESS,
                    payload:res.data.data,
                });
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_EVENT_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}


export const getEventDetailsAct = ({id}) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_EVENT_DETAILS_LOADING
    })

    AxiosHelper.get(`/event/details?event_id=${id}`).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_EVENT_DETAILS_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_EVENT_DETAILS_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}

export const generateEventInvitationCardsAct = ({event_id,sold_by,user_ticket_id}) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_GENERATE_INVITATION_CARD_LOADING
    })

    AxiosHelper.get(`/event/generate-invitation`,{
        responseType: 'blob',
        params:{
            event_id:event_id,
            guest_name:sold_by,
            user_ticket_id:user_ticket_id}
    }).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_GENERATE_INVITATION_CARD_SUCCESS,
                payload:res.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_GENERATE_INVITATION_CARD_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}


export const shareEventInvitationCardsAct = ({user_ticket_id,sold_by,event_id,event_name}) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_SHARE_EVENT_CARD_LOADING
    })

    AxiosHelper.get(`/event/share-invitation-card`,{
        // responseType: 'blob',
        params:{
            user_ticket_id:user_ticket_id,
            guest_name:sold_by,
            event_name:event_name,
            event_id:event_id,
        }
    }).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_SHARE_EVENT_CARD_SUCCESS,
                payload:res.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_SHARE_EVENT_CARD_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}


export const approvalEventRequests = ({event_id}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_EVENT_APPROVAL_REQUEST_LOADING
        })
    
        AxiosHelper.get('/event/approval-request',{
            params:{
                id:event_id
            }
        }).then(
            (res) => {
                resolve(res.data);
    
                dispatch({
                    type:GET_EVENT_APPROVAL_REQUEST_SUCCESS,
                    payload:res.data.data,
                });
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_EVENT_APPROVAL_REQUEST_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const getEventOnlineRSVP = ({id}) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_EVENT_DETAILS_LOADING
    })

    AxiosHelper.get(`/event/rsvp/details?event_id=${id}`).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_EVENT_DETAILS_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_EVENT_DETAILS_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}

export const createEventRsvp = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_RSVP_LOADING
    })

    AxiosHelper.post('/event/rsvp/online',{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_RSVP_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_RSVP_FAIL,
                payload:err?.response?.data,
            });
        }
    )

}

export const getEventCardInfo = ({user_ticket_id,card_code}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_EVENT_TICKET_CARD_INFO_LOADING
        })
        AxiosHelper.get('/event/event-ticket-card-info',{
            params:{
                user_ticket_id:user_ticket_id,
                card_code:card_code
            }
        }).then(
            (res) => {
                dispatch({
                    type:GET_EVENT_TICKET_CARD_INFO_SUCCESS,
                    payload:res.data.data,
                });
                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_EVENT_TICKET_CARD_INFO_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const clearEventCardInfoState = (dispatch)=>{
    dispatch({
        type:CLEAR_EVENT_TICKET_CARD_INFO_STATE
    })
}

export const postEventCardVerification = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_EVENT_TICKET_CARD_VERIFICATION_LOADING
        })
        AxiosHelper.post('/event/event-ticket-card-verification',{payload}).then(
            (res) => {
                dispatch({
                    type:POST_EVENT_TICKET_CARD_VERIFICATION_SUCCESS,
                    payload:res.data.data,
                });
                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_EVENT_TICKET_CARD_VERIFICATION_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}