import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../Layouts/Header';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Common/BreadCrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPlus } from '@fortawesome/free-solid-svg-icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import SimpleBar from "simplebar-react";
import UpcommingEvents from './UpcommingEvents';
import { GlobalStore } from '../../../StateStores';
import { getEventsAct } from '../../../Actions/Masters/EventCategoriesActs';
import moment from 'moment';
import { toast } from 'react-toastify';

const Calendar = ({})=>{
    const [events, setEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);

    const {
        MasterDispatch,
        MasterState:{
            getEvents:{data,error,loading}
        }} = useContext(GlobalStore);

    const handleDateClick = (arg) => {
        alert(arg.dateStr)
      }

    useEffect(()=>{
        const fetchEventList = async ()=>{
            try {
                let response = await getEventsAct({approval:1})(MasterDispatch);

                const upcomingEvents = response.data.filter(event => 
                    moment(event.start).isAfter(moment())
                );
                
                setUpcomingEvents(upcomingEvents);

                setEvents(response.data)
            } catch (error) {
                toast.error(error.message)
            }
        }
        fetchEventList()
    },[])

    /**
     * Function to render custom event content
     * @param {Object} eventInfo - Param that hold the event data
     * @returns UI
     */
    const renderEventContent = (eventInfo) => {
        return (
            <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '10px', backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#e0e7ff', borderRadius: '5px', padding: '2px 5px' }}>
                        {moment(eventInfo.event.start).format('h:mm A')} to {moment(eventInfo.event.end).format('h:mm A')}
                    </div>
                </div>
                <div className='text-wrap' style={{ marginTop: '10px', fontWeight: 'bold' }}>{eventInfo.event.title}</div>
                <div style={{ color: '#6c757d', marginTop: '5px' }}><strong>{eventInfo.event.extendedProps.cat_name}</strong></div>
          </div>
        );
      };

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>

            <Container fluid>
                <BreadCrumb 
                title="Event calendar"
                subTittle="Digikadi calendar viewer"
                pageTitle="Event"/>
                
                <Row>
                    <Col xl={9}>
                        <Card className="border-0 mt-3 card-h-100s bg-light-f1 boxshadow p-2">
                            <CardBody className="bg-light-f1 rounded-1 p-1">
                                <FullCalendar
                                plugins={[ dayGridPlugin, interactionPlugin,bootstrap5Plugin ]}
                                themeSystem='bootstrap5'
                                dateClick={handleDateClick}
                                // editable={true}
                                events={events}
                                eventContent={renderEventContent}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={3}>
                        <div className='mt-3 position-sticky top-20'>
                            <div className=''>
                                <Card className='border-0 boxshadows'>
                                    <CardBody className="bg-info-subtle yellow-background">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <FontAwesomeIcon 
                                                    className="" 
                                                    icon={faCalendar}/>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="fs-15">Welcome to your Calendar!</h6>
                                                <p className="text-muted mb-0">
                                                    Event that applications book will appear here. Click
                                                    on an event to see the details and manage applicants
                                                    event.
                                                </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className='mt-4'>
                                <h5 className="mb-1">Upcoming Events</h5>
                                <p className="text-muted">Don't miss scheduled events</p>
                                <SimpleBar 
                                className="pe-2 me-n1 mb-3"
                                style={{ height: "500px" }}>

                                    <div id="upcoming-event-list">
                                        {(upcomingEvents || []).map((event, key)=>(
                                            <React.Fragment key={key}>
                                                <UpcommingEvents event={event}/>
                                            </React.Fragment>
                                        ))}
                                    </div>

                                </SimpleBar>
                            </div>
                        </div>
                    </Col>
                </Row>
                
                
            </Container>

        </React.Fragment>
    )
}

export default Calendar;