import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import RoutesMaster from "./Constants/RoutesMaster";
import { GlobalStore } from "./StateStores";
import { AccessLocalTokenExpires, AccessLocalUserData } from "./Helpers/LocalService";


const  App = () =>{
  const {UsersDispatch, UsersState:{
    authState:{isLoggedIn}
  }} = useContext(GlobalStore);
  const[isAuthenticated, setIsAuthenticated] = useState(isLoggedIn);
  const[userData,setUserData] = useState({});
  const[tokenExp,setTokenExp] = useState("");


  useEffect(() => {
    async function getUserData() {
      try {
        const user = await AccessLocalUserData();
        const exp = await AccessLocalTokenExpires();
        if (user) {
          setIsAuthenticated(true);
          setUserData(JSON.parse(user));
          setTokenExp(exp);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
    getUserData();
  }, [isLoggedIn]);

  return (
      <React.Fragment>
        <RouterProvider router={RoutesMaster(isAuthenticated,userData)}>
          <BrowserRouter></BrowserRouter>
        </RouterProvider>
      </React.Fragment>
  );
}

export default App;
