import { 
    GET_EVENT_TABLES_FAIL, 
    GET_EVENT_TABLES_LOADING, 
    GET_EVENT_TABLES_SUCCESS, 
    POST_EVENT_TABLE_FAIL, 
    POST_EVENT_TABLE_LOADING, 
    POST_EVENT_TABLE_SUCCESS } from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";

export const createEventTables = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_EVENT_TABLE_LOADING
    })

    AxiosHelper.post('/event/event-tables',{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_EVENT_TABLE_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_EVENT_TABLE_FAIL,
                payload:err?.response?.data,
            });
        }
    )
}

export const getEventTables = ({event_id}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_EVENT_TABLES_LOADING
        })
    
        AxiosHelper.get(`/event/event-tables?event_id=${event_id}`).then(
            (res) => {
    
                dispatch({
                    type:GET_EVENT_TABLES_SUCCESS,
                    payload:res.data.data,
                });
                resolve(res.data.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_EVENT_TABLES_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}