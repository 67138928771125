import React, { useContext, useEffect } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import RolePermissionCheckbox from './RolePermissionCheckbox';
import { GlobalStore } from '../../../StateStores';
import { getUserGroupRights } from '../../../Actions/Users/UsersActs';
import { toast } from 'react-toastify';

const PermissionList = ({form,setForm, permissionList, checkedPermissions, setCheckedPermissions }) => {


    const {
        UsersDispatch,
        UsersState:{
            getUserGroupsRightStates:{
                loading,
                error,
                data
            },
        }} = useContext(GlobalStore);


    useEffect(()=>{
        const loadRoleRigths = async ()=>{
            try {
                let response = await getUserGroupRights({role_id:form?.id})(UsersDispatch);
                if(response.status){
                    // Initialize checkedPermissions from the fetched data
                    const initialChecked = [];
                    response.data.forEach(rolePermission => {
                        rolePermission.permission_rights.forEach(right => {
                            if (right.checked) {
                                initialChecked.push({
                                    r_perm_id: rolePermission.r_perm_id,
                                    perm_id: right.perm_id
                                });
                            }
                        });
                    });

                    setCheckedPermissions(initialChecked);
                }
            } catch (error) {
                toast.error(error.message)
            }
        }

        loadRoleRigths()
    },[form])


    return (
        <div>
            {permissionList.map((rolePermission) => (
                <Card key={rolePermission.r_perm_id} className="mt-3">
                    <CardBody>
                        <Table className="" size='sm' borderless bordered={false}>
                            <tbody>
                                <tr>
                                    <td style={{width:'20%'}}>
                                        <h6><strong>{rolePermission.r_perm_label}</strong></h6>
                                        <small className="text-muted">{rolePermission.icon}</small>
                                    </td>
                                    <td style={{width:'30%'}}>
                                        <div className="d-flex align-content-between flex-wrap">
                                            {rolePermission.permission_rights.map((permission) => (
                                                <RolePermissionCheckbox
                                                    key={permission.perm_id}
                                                    rolePermission={rolePermission}
                                                    permission={permission}
                                                    checkedPermissions={checkedPermissions}
                                                    setCheckedPermissions={setCheckedPermissions}
                                                />
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            ))}
        </div>
    );
};

export default PermissionList;
