import React, { useContext, useState } from "react";
import { Badge, Button, Col, FormGroup, Input, Row, Spinner, Table } from "reactstrap";
import * as XLSX from 'xlsx';
import { GlobalStore } from "../../../StateStores";


const capitalizeName = (name) => {
    return name
        .toLowerCase()
        .split(' ')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' ');
};

const splitName = (fullName) => {
    const parts = fullName?.trim().split(' ');

    if (parts.length >= 3 && parts[0].toUpperCase() === 'MR' && parts[1].toUpperCase() === '&' && parts[2].toUpperCase() === 'MRS') {
        const lastName = parts.slice(3).join(' ') || parts[3]; // Everything after "MR & MRS" as last name
        return ['', 'MR & MRS', capitalizeName(lastName)];
    } else if (parts.length === 3 && parts[0].toUpperCase() === 'MR' && parts[1].toUpperCase() === '&' && parts[2].toUpperCase() === 'MRS') {
        return ['', 'MR & MRS', capitalizeName(parts[3])];
    }

    if (parts.length === 2) {
        return [capitalizeName(parts[0]), '', capitalizeName(parts[1])];
    }

    if (parts.length >= 3) {
        return [
            capitalizeName(parts[0]), 
            capitalizeName(parts.slice(1, parts.length - 1).join(' ')), 
            capitalizeName(parts[parts.length - 1])
        ];
    }

    return [capitalizeName(parts[0]), '', ''];
};

const UserBulkUploadManager = ({file,setFile,data,setData})=>{

    const {
        UsersDispatch,
        UsersState:{
            postBulkUserUploadState:{data:resBulkData,loading:resBulkLoading},
        }
    } = useContext(GlobalStore);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryStr = e.target.result;
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
          // Skip the first row and process the rest of the data
          const processedData = parsedData.slice(1).map((row) => {
            const [firstName, middleName, lastName] = splitName(row[1]);
            return [row[0], firstName, middleName, lastName, row[2], row[3], row[4]];
          });
    
          setData(processedData);
        };

        if (selectedFile) {
          reader.readAsBinaryString(selectedFile);
        }
    };

    return(
        <React.Fragment>
             <div>
              <FormGroup>
                <Input 
                  type="file" 
                  bsSize="sm"
                  className="rounded-0"
                  onChange={handleFileChange} accept=".csv, .xlsx" />
              </FormGroup>

              {data.length > 0 && (
                <Table bordered size="sm">
                  <thead className="bg-light">
                    <tr>
                      <th>SN</th>
                      <th>First Name</th>
                      <th>Middle Name</th>
                      <th>Last Name</th>
                      <th>Gender</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => {
                                
                                if (data[0][cellIndex] === 'Status') {
                                    // Render Badge for specific statuses
                                    if (cell === 'duplicate') {
                                        return <td key={cellIndex}><Badge color="warning">Duplicate</Badge></td>;
                                    } else if (cell === 'skipped') {
                                        return <td key={cellIndex}><Badge color="danger">Skipped</Badge></td>;
                                    } else if (cell === 'inserted') {
                                        return <td key={cellIndex}><Badge color="success">Inserted</Badge></td>;
                                    } else if (cell === 'warning') {
                                        return <td key={cellIndex}><Badge color="warning">Warning</Badge></td>;
                                    } else {
                                        return <td key={cellIndex}>{cell}</td>;
                                    }
                                }
                                return <td key={cellIndex}>{cell}</td>;
                            })}
                        </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
        </React.Fragment>
    )
}

export default UserBulkUploadManager;