import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Badge, Card, CardBody } from "reactstrap";

const UpcommingEvents = ({event})=>{
    return(
        <React.Fragment>
            <Card className="mb-3 border-0 shadow-sm">
                <CardBody>
                    <div className="d-flexs mb-3">
                        <div className="flex-shrink-0">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> 
                            <span className="ms-1 fw-medium">
                                {moment(event?.start).format('DD MMM YYYY')} - {moment(event?.end).format('DD MMM YYYY')}
                            </span>
                        </div>
                        <div className="flex-grow-1">
                            <small className="badge bg-primary-subtle text-primary ms-auto">
                                {moment(event?.start).format('h:mm A')} to {moment(event?.end).format('h:mm A')}
                            </small>
                        </div>
                    </div>
                    <h6 className="card-title fs-16">{event?.title}</h6>
                    <p className="text-muted text-truncate-two-lines mb-0 fs-13">
                        {event?.description}
                    </p>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default UpcommingEvents;