import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-phone-number-input/style.css'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './assets/css/digikadi-min-style.css';
import './assets/fonts/fonts.css';
import 'simplebar-react/dist/simplebar.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StateStores from './StateStores';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StateStores>
    <App />
    <ToastContainer/>
  </StateStores>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
