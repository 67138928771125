import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { UserLoginAct } from '../../../Actions/Users/AuthActs';

const LoginForm = ()=>{
  const [formValid, setFormValid] = useState(false);
  const [form,setForm] = useState({password:'',accountholder:''});
  const [formErrors,setFormErrors] = useState({
    accountholder:'Email, mobile or username is required',
    password:'Password is required'});

  const {UsersDispatch,UsersState:{
    authState:{loading}
  }} = useContext(GlobalStore);

    useEffect(() => {
        // Check if there are any errors in the form
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    const onChange = ({name,e}) => {
      let value = e.target.value;
      setForm({...form,[name]:value});

      if (value !== '') {
        setFormErrors(prev => ({ ...prev, [name]: null }));
      } else {
        setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
      }
    
    }


    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent page reload

      if(formValid){
        UserLoginAct(form)(UsersDispatch)
      }

    }


    return(
        <div className='d-flex justify-content-center'>
      <Form 
      onSubmit={handleSubmit} 
      className="w-100">
        <div className='d-flex justify-content-center mb-4'>
            <div className='text-center'>
                <h2 className="mb-0">Welcome Back !</h2>
                {/* <h2 className="mb-0">Welcome to <strong>Digikadi</strong></h2> */}
                {/* and start the adventure */}
                <small>Please sign-in to your account</small>
            </div>
        </div>
        <FormGroup>
          <Label for="email">
            <FontAwesomeIcon icon={faEnvelope} className="me-2" />
            EMAIL, MOBILE OR USERNAME
          </Label>
          <Input
            type="text"
            name="email"
            id="email"
            placeholder="Enter your email"
            // value={email}
            onChange={(e) => onChange({name:'accountholder',e})}
            // required
            className="custom-input rounded-0"
            valid={formErrors.accountholder === null}
            invalid={formErrors.accountholder !== null}
          />
          <small>{formErrors.accountholder}</small>
        </FormGroup>
        <FormGroup>
          <Label for="password">
            <FontAwesomeIcon icon={faLock} className="me-2" />
            PASSWORD
          </Label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Enter your password"
            // value={password}
            onChange={(e) => onChange({name:'password',e})}
            // required
            className="custom-input rounded-0"
            valid={formErrors.password === null}
            invalid={formErrors.password !== null}
          />
        </FormGroup>
        <Button 
          disabled={!formValid} 
          color="dark" 
          type="submit" 
          block 
          className="custom-button rounded-0">
            {loading && <Spinner size="sm"/>} <span>Login</span>
          </Button>
      </Form>
    </div>
    )
}

export default LoginForm;