import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { SignUpUserAct } from '../../../Actions/Users/AuthActs';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

const SignUpForm = () => {
    const [formValid, setFormValid] = useState(false);
    const [form, setForm] = useState({});
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    const { UsersDispatch, UsersState: { authState: { loading } } } = useContext(GlobalStore);

    const onChange = ({ name, e }) => {
        let value = e.target.value;
        setForm({ ...form, [name]: value });

        // Basic validation: required fields and length checks
        if (value !== '') {
            if (name === 'password' && value.length < 6) {
                setFormErrors((prev) => ({ ...prev, [name]: 'This field needs min 6 characters' }));
            } else if (name === 'username' && value.length < 3) {
                setFormErrors((prev) => ({ ...prev, [name]: 'This field needs min 3 characters' }));
            } else {
                setFormErrors((prev) => ({ ...prev, [name]: null }));
            }
        } else {
            setFormErrors((prev) => ({ ...prev, [name]: 'This field is required' }));
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!form.name) {
            newErrors.name = 'This field is required';
            valid = false;
        }

        if (!form.username) {
            newErrors.username = 'This field is required';
            valid = false;
        } else if (form.username.length < 3) {
            newErrors.username = 'This field needs min 3 characters';
            valid = false;
        }

        if (!form.gender) {
            newErrors.gender = 'This field is required';
            valid = false;
        }

        if (!form.mobile) {
            newErrors.mobile = 'This field is required';
            valid = false;
        }

        if (!form.email) {
            newErrors.email = 'This field is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(form.email)) {
            newErrors.email = 'Email address is invalid';
            valid = false;
        }

        if (!form.password) {
            newErrors.password = 'This field is required';
            valid = false;
        } else if (form.password.length < 6) {
            newErrors.password = 'This field needs min 6 characters';
            valid = false;
        }

        if (!form.confirm_password) {
            newErrors.confirm_password = 'This field is required';
            valid = false;
        } else if (form.confirm_password !== form.password) {
            newErrors.confirm_password = 'Passwords do not match';
            valid = false;
        }

        setFormErrors(newErrors);
        return valid;
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload

        if (validateForm()) {
            const names = form?.name?.split(' ');
            form.fname = names[0];
            form.mname = names[1] || ''; // Handle absence of middle name
            form.lname = names[2] || '';

            try {
                const response = await SignUpUserAct(form)(UsersDispatch);

                if (response.status === 'Success') {
                    toast.success('Registration successful');
                    setForm({})
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    };

    return (
        <React.Fragment>
            <div className='d-flex justify-content-center'>
                <Form onSubmit={onSubmit} className="w-100">
                    <div className='d-flex justify-content-center mb-4'>
                        <div className='text-center'>
                            <h2 className="mb-0">Create New Account</h2>
                            <small>Please fill the form to register</small>
                        </div>
                    </div>
                    <FormGroup>
                        <Input
                            type="text"
                            name="username"
                            placeholder="Username"
                            onChange={(e) => onChange({ name: 'username', e })}
                            className="custom-input rounded-0"
                            valid={formErrors.username === null}
                            invalid={formErrors.username !== null}
                        />
                        <small>{formErrors.username}</small>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="text"
                            name="name"
                            placeholder="Enter fullname e.g (Juma Rashid Juma)"
                            onChange={(e) => onChange({ name: 'name', e })}
                            className="custom-input rounded-0"
                            valid={formErrors.name === null}
                            invalid={formErrors.name !== null}
                        />
                        <small>{formErrors.name}</small>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="select"
                            name="gender"
                            onChange={(e) => onChange({ name: 'gender', e })}
                            className="custom-input rounded-0"
                            valid={formErrors.gender === null}
                            invalid={formErrors.gender !== null}
                        >
                            <option value="">--Gender--</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Input>
                        <small>{formErrors.gender}</small>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="text"
                            name="mobile"
                            placeholder="Mobile"
                            onChange={(e) => onChange({ name: 'mobile', e })}
                            className="custom-input rounded-0"
                            valid={formErrors.mobile === null}
                            invalid={formErrors.mobile !== null}
                        />
                        <small>{formErrors.mobile}</small>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => onChange({ name: 'email', e })}
                            className="custom-input rounded-0"
                            valid={formErrors.email === null}
                            invalid={formErrors.email !== null}
                        />
                        <small>{formErrors.email}</small>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => onChange({ name: 'password', e })}
                            className="custom-input rounded-0"
                            valid={formErrors.password === null}
                            invalid={formErrors.password !== null}
                        />
                        <small>{formErrors.password}</small>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type="password"
                            name="confirm_password"
                            placeholder="Confirm password"
                            onChange={(e) => onChange({ name: 'confirm_password', e })}
                            className="custom-input rounded-0"
                            valid={formErrors.confirm_password === null}
                            invalid={formErrors.confirm_password !== null}
                        />
                        <small>{formErrors.confirm_password}</small>
                    </FormGroup>
                    <Button
                        disabled={!formValid}
                        color="dark"
                        type="submit"
                        block
                        className="custom-button rounded-0"
                    >
                        {loading && <Spinner size="sm" />} <span>Sign Up</span>
                    </Button>
                </Form>
            </div>
        </React.Fragment>
    );
};

export default SignUpForm;