import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { getFoodMenuCategories, getFoodMenuItems } from '../../../Actions/Masters/FoodMenuActs';
import { toast } from 'react-toastify';
import { Badge, Button, Spinner, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import FoodMenuAddItem from './FoodMenuAddItem';

const FoodMenuItems = ()=>{
    const [foodMenuItems, setFoodMenuItems] = useState([]);

    const {
        FoodMenuMasterDispatch,
        FoodMenuMasterState:{
            postFoodMenuItem:{data:saveData,error:saveError,loading:saveLoading},
            foodMenuItems:{data:categories,error,loading},
        }} = useContext(GlobalStore);

    /**
     * Method that used to request the food menu items
     */
    const loadFoodMenuItems = async ()=>{
        try {
            const response = await getFoodMenuItems({})(FoodMenuMasterDispatch);
            setFoodMenuItems(response)
        } catch (error) {
            // toast.error(error.message)
        }
    }

    useEffect(()=>{
        loadFoodMenuItems();
    },[])

    /**
     * Used to refresh the category list
     */
    const reRefreshList = ()=>{
        loadFoodMenuItems()
    }

    return(
        <React.Fragment>
            <FoodMenuAddItem setItems={setFoodMenuItems} itemList={foodMenuItems}/>
            <h6>Added food menu items</h6>
            {loading && <div className='d-flex justify-content-center'><Spinner size="sm"/></div>}
            <Table className="mt-3 table nowrap align-middle table-sm table-hover caption-top custom-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Category</th>
                        <th>Vegan</th>
                        <th>Gluten-free</th>
                        <th>Added by</th>
                        <th style={{width:'80px'}}>
                            <Button 
                                size='sm' 
                                outline
                                onClick={reRefreshList}>
                                <FontAwesomeIcon icon={faRefresh}/>
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(foodMenuItems || []).map((item, index) => (
                        <tr key={index}>
                            <td>{++index}</td>
                            <td>
                                <div><strong>{item.item_name}</strong></div>
                                <small className='text-muted'>{item.description}</small>
                            </td>
                            <td>{item.category_name}</td>
                            <td>
                                <div className='d-flex justify-content-center'>
                                    <Badge color={item.is_vegan?'success':'danger'}>
                                        {item.is_vegan? 'Yes':'No'}
                                    </Badge>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex justify-content-center'>
                                    <Badge color={item.is_gluten_free?'success':'danger'}>
                                        {item.is_gluten_free? 'Yes':'No'}
                                    </Badge>
                                </div>
                            </td>
                            <td className="text-wrap">
                                <small className='text-primary'>{item.createdby}</small>
                                <div className='d-flexa'>
                                    <small className="text-muted">{moment(item.created_at).format('LL HH:mm:ssa')}</small>
                                    {/* <small className="text-info text-muted ms-1">({moment(category.created_at).startOf().fromNow()})</small> */}
                                </div>
                            </td>
                            <td style={{width:'80px'}}></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default FoodMenuItems;