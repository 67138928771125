import { faAddressCard, faBell, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EventAccessManager from '../Pages/EventAccessManager';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const EventAccessManagerAction = ({
    event,
    selectedCategory,
    setSelectedCategory
})=>{
    const [modal, setModal] = useState(false);
    const [offcanvasOpen, setOffcanvasOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const onCloseModal = ()=>{}

    const handleButtonClick = (actionType) => {
        setSelectedAction(actionType);
        setOffcanvasOpen(!offcanvasOpen);
    };

    const toggleOffcanvas = () => {
        // setSelectedAction(actionType);
        setOffcanvasOpen(!offcanvasOpen);
    };

    return(
        <React.Fragment>
            <Modal
                onClosed={(e)=>onCloseModal()}
                className='custom-modal rounded-0 border-0' 
                fullscreen 
                isOpen={modal}
                toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        <FontAwesomeIcon icon={event?.id? faAddressCard:faAddressCard}/> 
                        <span className='ms-1'>Event Access manager</span>
                    </h5>
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <EventAccessManager 
                        selectedEvent={event} 
                        actionType={selectedAction} 
                        offcanvasOpen={offcanvasOpen}
                        setOffcanvasOpen={setOffcanvasOpen}
                        // toggleOffcanvas={toggleOffcanvas}
                        />
                </ModalBody>
                <ModalFooter>
                    <div className=''>
                        <Button 
                            color="success" 
                            size='sm'
                            className='rounded-0 yellow-background fs-color-black border-0'
                            onClick={() => handleButtonClick('whatsapp')}
                            >
                                <span className="icon-circle"><FontAwesomeIcon icon={faWhatsapp}/></span>
                                <span className='ms-1'>Send via WhatsApp</span>
                        </Button>
                        <Button 
                            color="primary"
                            size='sm' 
                            className='ms-1 rounded-0 yellow-background fs-color-black border-0'
                            onClick={() => handleButtonClick('sms')}
                            >
                                <span className="icon-circle"><FontAwesomeIcon icon={faEnvelope}/></span>
                                <span className='ms-1'>Send via SMS</span>
                        </Button>
                        <Button 
                            color="primary"
                            size='sm' 
                            className='ms-1 rounded-0 yellow-background fs-color-black border-0'
                            target="_blank"
                            onClick={() => handleButtonClick('reminder')}
                            >
                                <span className="icon-circle"><FontAwesomeIcon icon={faBell}/></span>
                                <span className='ms-1'>SMS Reminder</span>
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>

            <Button
                color="success"
                outline
                className="icon-holder-btn ms-1"
                title="Event users"
                onClick={togOpenModal}
            >
                <FontAwesomeIcon icon={faAddressCard} />
            </Button>
        </React.Fragment>
    )
}

export default EventAccessManagerAction;