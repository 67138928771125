import React from "react";
import { ReactSession } from 'react-client-session';
import { Navigate } from "react-router-dom";
// import ErrorAuth from "../components/RootComp/ErrorAuth";
import { getLocalAccessPermissions } from "./LocalService";



/**
 * function that will provide the permitted menus/option
 * @returns Array()
 */
export const getPermittedMenuList = async ()=>{
    return await ReactSession.get('permissions');
}


/**
 * Function that will redirect user to assigned screen
 * @param {JSON} param LoggedUserProfile
 * @returns location
 */
export const RouteAuth = ({LoggedUserProfile})=>{
    // return <Navigate to={LoggedUserProfile?.baseroute} />
    return <Navigate to='/dashboard' />
}

export const getBaseRoute = () => {
    try {
        //get current URL
        const currentUrl = window.location.pathname;

        const path = new URL(currentUrl, window.location.origin).pathname;
        const segments = path.split('/').filter(segment => segment);
        return segments.length > 0 ? `/${segments[0]}` : '/';
    } catch (error) {
        console.error('Error extracting base route:', error);
        return '/';
    }
};

const hasRight = (right) => {
    // return rights.includes(right);
};


/**
 * Function that will controll the action unless has the right to perform the action
 * @param {Object} param0 
 * @returns 
 */
export const HasPermission =  ({route="",action})=>{
    try {
        //Getting the base route
        const baseRoute = route || getBaseRoute();

        // Get the list of permissions
        const permittedList = getLocalAccessPermissions().find((item) => item.base_route === baseRoute);

        // If no permissions are found for the route, return false
        if (!permittedList) {
            return false;
        }

        // Check if the action is included in the permissions
        return permittedList.right?.includes(action) || false;
    } catch (error) {
        //console.error('Error checking permissions:', error);
        return false;
    }
}

/**
 * Use to protect the protected routes, unless user logged and permitted to access.
 * @param {*} param0 
 * @returns 
 */
export const Protected = ({isSignedIn,route,action,children })=>{
    if(!isSignedIn) {
       return <Navigate to="/" replace/>
    }else{
        return children;
        /*if(HasPermission({route:route,action:action})){
            
        }else{
            return null;//<ErrorAuth/>
        }*/
    }
}