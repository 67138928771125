import { DEACTIVATE_ACCOUNT_FAIL, DEACTIVATE_ACCOUNT_LOADING, DEACTIVATE_ACCOUNT_SUCCESS, LOGIN_FAIL, LOGIN_LOADING, LOGIN_SUCCESS, LOGOUT_USER, SIGNUP_FAIL, SIGNUP_LOADING, SIGNUP_SUCCESS } from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";
import { RemoveAllLocalData, UpdateLocalAccessToken, UpdateLocalPermission, UpdateLocalProfile } from "../../Helpers/LocalService";


export const UserLoginAct =  ({password,accountholder}) => (dispatch)=>{

    dispatch({
        type:LOGIN_LOADING,
    });

    AxiosHelper.post('/users/login',{accountholder,password}).then(
        (res) => {
            //Destructure of an object
            const {status,message,permissionList} = res.data;

            if(status !== 'Failed'){

                // UpdateLocalIsLoggedIn(true);

                 //Saving data to the local storage
                 UpdateLocalProfile(res.data.data);

                 //Update the localstorage
                 UpdateLocalAccessToken({
                    token:res?.data?.token,
                    tokenExpiresIn:res?.data?.tokenExpiresIn});

                 //Save the permissions list the local storage
                //  UpdateLocalPermission(permissionList);

                dispatch({
                    type:LOGIN_SUCCESS,
                    payload:res.data,
                });

            }else{

                dispatch({
                    type:LOGIN_FAIL,
                    payload:message,
                });
            }
            
        }
    ).catch(
        (err) => {

            if(err.code === 'ERR_NETWORK'){
                dispatch({
                    type:LOGIN_FAIL,
                    payload:{
                        'status':'Failed',
                        'message':'Server is not available or check your internet connection'},
                });
            }else{

                dispatch({
                    type:LOGIN_FAIL,
                    payload:err.response.data,
                });
            }
        }
    )
}

export const UserLogout = (dispatch) => {
    // Remove all local data
    RemoveAllLocalData();
    // Dispatch the logout action
    dispatch({
        type:LOGOUT_USER,
    })
    // Refresh the page
    window.location.reload();
}

export const SignUpUserAct = ({username,fname,mname,lname,email,gender,mobile,password}) =>(dispatch)=> {
    return new Promise((resolve,reject)=>{
        dispatch({
            type:SIGNUP_LOADING,
        });
    
        AxiosHelper.post('users/signup/',{username,fname,mname,lname,email,gender,mobile,password,}).then(
            (res) => {
    
                dispatch({
                    type:SIGNUP_SUCCESS,
                    payload:res.data,
                });
                resolve(res.data)
                
        }).catch(
            (err) => {

                const errorMessage = err.response
                ? err?.response?.data
                : err.response?.data;
    
                dispatch({
                    type:SIGNUP_FAIL,
                    payload:err.response
                    ? err?.response?.data
                    : {error:err.response?.data},
                });

                reject(errorMessage)
        })
    })
}

export const deactivateAccountAct = (dispatch)=> {
    return new Promise((resolve,reject)=>{
        dispatch({
            type:DEACTIVATE_ACCOUNT_LOADING,
        });
    
        AxiosHelper.get('users/deactivate-account').then(
            (res) => {
    
                dispatch({
                    type:DEACTIVATE_ACCOUNT_SUCCESS,
                    payload:res.data,
                });
                resolve(res.data)
                
        }).catch(
            (err) => {

                const errorMessage = err.response
                ? err?.response?.data
                : err.response?.data;
    
                dispatch({
                    type:DEACTIVATE_ACCOUNT_FAIL,
                    payload:err.response
                    ? err?.response?.data
                    : {error:err.response?.data},
                });

                reject(errorMessage)
        })
    })
}