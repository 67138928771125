import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormFeedback, Input, InputGroup, InputGroupText, Label, Row, Spinner, Table } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { debounce } from 'lodash';
import { createUserAcc, getSearchUsersAct } from '../../../Actions/Users/UsersActs';
import { toast } from 'react-toastify';

const UserMasterSearch = ({onSelect,evetData})=>{
    const [query, setQuery] = useState({label:'',value:''});
    const [results, setResults] = useState([]);
    const [isUserSelected, setIsUserSelected] = useState(false);
    const [newProfileForm, setNewProfileForm] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [form,setForm] = useState({fname:'',mname:'',lname:'',mobile:'',gender:''});
    const [formErrors,setFormErrors] = useState({
        fname:'First name is required',mname:'Middle name is required',lname:'Last name is required',mobile:'Mobile is required',
        gender: 'Gender is required',
    });


    const {
        UsersDispatch,
        UsersState:{
            searchUsersState:{data,error,loading},
            postUserStates:{data:postUserData,error:postUserError,loading:postUserLoading},
        }} = useContext(GlobalStore);


    const debouncedSearch = debounce(async (query) => {
        if (!query.label) {
            setResults([]);
            return;
        }

        try {
            const response = await getSearchUsersAct({param:query.label})(UsersDispatch);
            setResults(response.data);
        } catch (err) {
            toast.error(`An error occurred while fetching search results, ${err.message}`)
        }
    }, 300);

    useEffect(() => {
        if (!isUserSelected) {
            debouncedSearch(query);
        } else {
            setIsUserSelected(false);
        }
    }, [query]);

    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    const handleInputChange = (e) => {
        setQuery({...query,label:e.target.value});
        setNewProfileForm(false)
    };

    const onSelectUser = (obj)=>{
        setIsUserSelected(true);
        setQuery(obj);
        setResults([])

        if(obj.value === 0){
            setNewProfileForm(true)
        }

        if (onSelect) {
            onSelect(obj);
        }
    }

    const clearSelectedUser = ()=>{
        let defaultObj = {label:'',value:''};
        setQuery(defaultObj)
        if (onSelect) {
            onSelect(defaultObj);
        }
        setResults([])
        setNewProfileForm(false)
    }

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));

        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }
    };

    const createProfile = async (e) => {
        e.preventDefault();

        if(formValid){
            try {
                const response = await createUserAcc({...form,event_id:evetData?.id})(UsersDispatch);

                if(response.status){
                    toast.success(response.message);
                    //Auto select
                    onSelectUser({
                        ...response.data,
                        value:response?.data?.id,
                        label:response?.data?.fullname});

                }else{
                    toast.error(response.message);
                }

                // Reset the form and states
                setNewProfileForm(false)
                setForm({ fname: '', mname:'', lname: '', mobile: '',gender:'' });
                // clearSelectedUser();
            } catch (error) {
                toast.error(error.message)
            }
        }
    };

    return(
        <React.Fragment>
            <div className='search-container mb-4'>
                <Label>Select invitee</Label>
                <InputGroup className="d-flex">
                    <Input 
                    type='text' 
                    placeholder='Write names or mobile number' 
                    value={query.label}
                    onChange={handleInputChange} 
                    />
                    <InputGroupText 
                        tag="div"
                        className='bg-white p-0'>
                        <Button 
                        disabled={query?.value === ''}
                        size='sm' color='white' className='border-0' outline
                        onClick={clearSelectedUser}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </Button>
                    </InputGroupText>
                </InputGroup>

                <div className='create-profile-box mt-1' hidden={!newProfileForm}>
                    <Card>
                        <CardBody>
                            <h6>Create New User</h6>
                            <Form onSubmit={createProfile}>
                                <Row className='gx-0'>
                                    <Col>
                                        <Input
                                            type='text'
                                            name='fname'
                                            placeholder='First Name'
                                            className="rounded-0" size="sm"
                                            value={form.fname}
                                            onChange={handleProfileChange}
                                            valid={formErrors.fname === ''}
                                            invalid={formErrors.fname !== ''}
                                        />
                                        {formErrors.fname && <FormFeedback>{formErrors.fname}</FormFeedback>}
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            name='mname'
                                            placeholder='Middle Name'
                                            className="rounded-0" size="sm"
                                            value={form.mname}
                                            onChange={handleProfileChange}
                                            valid={formErrors.mname === ''}
                                            invalid={formErrors.mname !== ''}
                                        />
                                        {formErrors.mname && <FormFeedback>{formErrors.mname}</FormFeedback>}
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            name='lname'
                                            placeholder='Last Name'
                                            className="rounded-0" size="sm"
                                            value={form.lname}
                                            onChange={handleProfileChange}
                                            valid={formErrors.lname === ''}
                                            invalid={formErrors.lname !== ''}
                                        />
                                        {formErrors.lname && <FormFeedback>{formErrors.lname}</FormFeedback>}
                                    </Col>
                                    <Col>
                                        <Input
                                            type='select'
                                            name='gender'
                                            placeholder='Gender'
                                            className="rounded-0" size="sm"
                                            value={form.gender}
                                            onChange={handleProfileChange}
                                            valid={formErrors.gender === ''}
                                            invalid={formErrors.gender !== ''}
                                        >
                                            <option>--Select--</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </Input>
                                        {formErrors.gender && <FormFeedback>{formErrors.gender}</FormFeedback>}
                                    </Col>
                                    <Col>
                                        <Input
                                            type='number'
                                            name='mobile'
                                            placeholder='Mobile'
                                            className="rounded-0" size="sm"
                                            value={form.mobile}
                                            onChange={handleProfileChange}
                                            valid={formErrors.mobile === ''}
                                            invalid={formErrors.mobile !== ''}
                                        />
                                        {formErrors.lname && <FormFeedback>{formErrors.mobile}</FormFeedback>}
                                    </Col>
                                </Row>
                                <Row className='gx-0 mt-1'>
                                    <Col>
                                        <div className='d-flex justify-content-end'>
                                        <Button
                                            color="dark"
                                            disabled={!formValid} 
                                            className="custom-button rounded-0"
                                            size='sm'>
                                                {postUserLoading && <Spinner size="sm"/>}
                                                {!postUserLoading && <FontAwesomeIcon icon={faSave} />} 
                                                <span className='ms-1'>Save</span></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </div>


                {/* hidden={results.length === 0 && !creatingProfile} */}
                <div className='searched-results-box' hidden={results.length === 0}>
                    
                    <Table className="table table-sm table-hover caption-top">
                        <caption>
                            <div className='d-flex justify-content-between'>
                                <div>Searched results</div>
                                {loading && <Spinner size="sm"/>}
                            </div>
                        </caption>
                        <tbody>
                            
                            {!loading && (results || []).map((user, index) => (
                                <tr key={user.id} role='button' onClick={(e)=>{
                                    onSelectUser({label:user.fullname,value:user.id})
                                }}>
                                    <td style={{width:'80px'}}>
                                        {user.id !== 0 && (
                                            <img 
                                                src='/50.png' alt={user.fullname} 
                                                style={{ width: '50px', height: '50px', borderRadius: '50%' }} 
                                            />
                                        )}
                                        
                                        {user.id === 0 && (
                                            <div style={{ 
                                                width: '50px', 
                                                height: '50px', 
                                                borderRadius: '50%',
                                                background:"gray"
                                            }}
                                                className='d-flex justify-content-center align-items-center'
                                                >
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <div>{user.fullname}</div>
                                        <div>
                                            <small className='text-muted'>
                                                {`${user.mobile} ${user.id === 0? `: "${query?.label}"`:''}`}
                                            </small>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>

                <hr/>
                </div>

        </React.Fragment>
    )
}

export default UserMasterSearch;