import React, { useContext, useEffect, useState } from "react";
import { Container} from "reactstrap";
import RsvpFormRender from "../../Common/RsvpFormRender";
import { useParams } from "react-router-dom";
import base64 from 'base-64';
import { GlobalStore } from "../../../StateStores";
import {getEventOnlineRSVP } from "../../../Actions/Masters/EventCategoriesActs";
import Swal from "sweetalert2";

const OnlineRsvp = ()=>{
    const { qrcode } = useParams();//exctract the QR code
    const [event, setEvent] = useState({});
    const [soldTicket, setSoldTicket] = useState([]);
    const {
        MasterDispatch,
        MasterState:{
            getEventDetails:{data,error,loading},
        }} = useContext(GlobalStore);
    
        
    useEffect(()=>{
        const loadEvent = ()=>{
            try {
                // Dedode the QR code data
                const decodedQrcode = base64.decode(qrcode);
                //Convert to valid JSON
                const ticket = JSON.parse(decodedQrcode);
                //Request event details
                getEventOnlineRSVP({id:ticket?.event_id})(MasterDispatch)((res)=>{
                    if(res.status === 'Success'){
                        const {solid_events,event} = res.data[0];
                        const cardData = solid_events.find((item)=> item?.user_ticket_id === ticket?.ticket_id);
                        setSoldTicket({...cardData,sold_by_id:ticket?.sold_by});
                        setEvent(event)
                    }else{
                        
                        Swal.fire({
                            title:'RSVP',
                            text:res.message,
                            icon:'error',
                            allowOutsideClick:false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            preConfirm: () => {
                                Swal.showValidationMessage('Check QR code or event card code');
                            }
                        })
                    }
                })
                
            } catch (error) {
                
                Swal.fire({
                    title:'RSVP',
                    text:error.message,
                    icon:'error',
                    allowOutsideClick:false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    preConfirm: () => {
                        Swal.showValidationMessage('Check QR code or event card code');
                    }
                })
            }
        }

        loadEvent()
    },[qrcode])

    return(
        <React.Fragment>
            <Container>
                <RsvpFormRender event={event} ticket={soldTicket}/>
            </Container>
        </React.Fragment>
    )
}

export default OnlineRsvp;