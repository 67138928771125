import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { GlobalStore } from '../../../StateStores';
import { createEventTicketTypes, getEventTicketTypes, getTicketTypes } from '../../../Actions/Masters/TicketTypesActs';
import { toast } from 'react-toastify';
import moment from 'moment';
//selectedEvent = null,addEventTicketType = ()=>{}
    // formValid,setFormValid
const EventTicketTypesForm = ({selectedEvent,addEventTicketType})=>{
    const [formValid, setFormValid] = useState(false);
    const [eventTicketTypes, setEventTicketTypes] = useState([]);
    const [form,setForm] = useState({
        ticket_type_id:'',
        price:'',
        quantity:'',});

    const [formErrors,setFormErrors] = useState({
        ticket_type_id:'Ticket type is required',
        price:'Price is required',
        quantity:'Quantity is required',
    });

    const {
        MasterDispatch,
        MasterState:{
            createEventTicketTypes:{data,error,loading},
            getTicketTypes:{data:ticketData,error:ticketError,loading:ticketLoading},
            getEventTicketTypes:{data:ticketTypeData,error:ticketTypeError,loading:ticketTypeLoading},
        }} = useContext(GlobalStore);


    useEffect(()=>{
        function fetchTicketTypes(){
            getTicketTypes(selectedEvent)(MasterDispatch)((res)=>{})
            if(selectedEvent?.system_id === 0) toast.error('No invitation system was set for this event')
        }
        fetchTicketTypes();
    },[selectedEvent])


    useEffect(()=>{
        function fetchEventTicketTypes(){
            if(selectedEvent?.id !== undefined){
                getEventTicketTypes({event_id:selectedEvent?.id})(MasterDispatch)((res)=>{
                    if(res.status === 'Success'){
                        setEventTicketTypes(res.data)
                    }
                })
            }
        }
        fetchEventTicketTypes()
    },[])


    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    const onChange = ({name,e}) => {
        let value = e.target.value;

        setForm((prev) => ({ ...prev, [name]: value }));

        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }
    }

    const handleAddTicketType = async (e) => {
        e.preventDefault();
        const newTicketType = {
            ...form,
            type_name:ticketData.find(type => type.ticket_type_id === parseInt(form.ticket_type_id)).type_name
        };

        if(formValid){
            createEventTicketTypes({
                ...form,
                event_id:selectedEvent?.id,
                system_id:selectedEvent?.system_id,
            })(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    toast.success(res.message)
                    setEventTicketTypes([...eventTicketTypes,res.data])
                    //Clear the state, ready for new ticket
                    setForm({ticket_type_id:'',price:'',quantity:'',});
                    setFormErrors({
                        ticket_type_id:'Ticket type is required',
                        price:'Price is required',
                        quantity:'Quantity is required',
                    });

                }else{
                    toast.error(res.message)
                }
            })
        }
    }


    return(
        <React.Fragment>
            <Container fluid>
                {/* <h3>Add Ticket Types for Event: {selectedEvent.event_name}</h3> */}
                <Form onSubmit={handleAddTicketType}>
                    <Row className="w-100 align-items-center g-3">
                        <Col>
                            <FormGroup>
                                <Label for="ticketType">Ticket Type</Label>
                                <Input
                                    type="select"
                                    className="rounded-0"
                                    name="ticketType"
                                    id="ticketType"
                                    value={form?.ticket_type_id}
                                    onChange={(e)=>onChange({name:'ticket_type_id',e})}
                                    valid={formErrors.ticket_type_id === ''}
                                    invalid={formErrors.ticket_type_id !== ''}
                                >
                                    <option value="">Select Ticket Type</option>
                                    {(ticketData || []).map((type) => (
                                        <option key={type.ticket_type_id} value={type.ticket_type_id}>
                                            {type.type_name}
                                        </option>
                                    ))}
                                </Input>
                                {formErrors.ticket_type_id && <FormFeedback>{formErrors.ticket_type_id}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="price">Price</Label>
                                <Input
                                    type="number"
                                    className="rounded-0"
                                    placeholder='Price of each ticket'
                                    name="price"
                                    id="price"
                                    value={form?.price}
                                    onChange={(e)=>onChange({name:'price',e})}
                                    valid={formErrors.price === ''}
                                    invalid={formErrors.price !== ''}
                                />
                                {formErrors.price && <FormFeedback>{formErrors.price}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="quantity">Quantity</Label>
                                <Input
                                    type="number"
                                    className="rounded-0"
                                    placeholder='Number of ticket'
                                    name="quantity"
                                    id="quantity"
                                    value={form?.quantity}
                                    onChange={(e)=>onChange({name:'quantity',e})}
                                    valid={formErrors.quantity === ''}
                                    invalid={formErrors.quantity !== ''}
                                />
                                {formErrors.quantity && <FormFeedback>{formErrors.quantity}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col className="d-flex align-items-center">
                            <FormGroup className="w-100 mb-0">
                                <Button 
                                    // size='sm'
                                    color="dark"
                                    disabled={!formValid}
                                    className="custom-button rounded-0 w-100"
                                >
                                    Add Ticket Type
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <h6>Added Ticket Types</h6>
                <Table className="mt-3 table nowrap align-middle table-sm table-hover caption-top custom-table">
                    <thead>
                        <tr>
                            <th>Ticket Type</th>
                            <th className='text-center'>Price</th>
                            <th className='text-center'>Quantity</th>
                            <th style={{width:'400px'}}>Added by</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(eventTicketTypes || []).map((type, index) => (
                            <tr key={index}>
                                <td>{type.type_name}</td>
                                <td className='text-center'>{type.price}</td>
                                <td className='text-center'>{type.quantity}</td>
                                <td className="text-wrap">
                                    <small className='text-primary'>{type.createdby}</small>
                                    <div className='d-flex'>
                                        <small className="text-muted">{moment(type.doc).format('LL HH:mm:ssa')}</small>
                                        <small className="text-info text-muted ms-1">({moment(type.doc).startOf().fromNow()})</small>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </React.Fragment>
    )
}

EventTicketTypesForm.propTypes = {
    selectedEvent: PropTypes.object,
};

export default EventTicketTypesForm;