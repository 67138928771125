import { ReactSession } from 'react-client-session';
import { decryptData, encryptData } from './EncryptionsHelper';

/**
 * Get the token from the localStorage
 * @returns Token
 */
export const AccessLocalToken = async ()=>{
    const token = await ReactSession.get("DigToken");
    return token;
}

/**
 * Get the token Expires In from the localStorage
 * @returns Token
 */
export const AccessLocalTokenExpires = async ()=>{
    const token = await ReactSession.get("DigTokenExpiresIn");
    return token;
}

/**
 * Get the userData from the localStorage
 * @returns Token
 */
export const AccessLocalUserData = async ()=>{
    const encryptedData = await ReactSession.get("DigUserdata");
    if (encryptedData) {
        return decryptData(encryptedData);
    }
    return null;
}

/**
 * Used to store user data into the localstorage
 * @param {Object} userData User data object
 */
export const UpdateLocalProfile = (userData)=>{
    const encryptedData = encryptData(JSON.stringify(userData));
    if(encryptedData){
        //Saving data to the local storage
        ReactSession.set("DigUserdata",encryptedData);
    }
}

/**
 * Used to store user data into the localstorage
 * @param {Object} userData User data object
 */
export const UpdateLocalIsLoggedIn = (isLoggedIn)=>{
    //Saving data to the local storage
    ReactSession.set("DigIsLoggedIn", JSON.stringify(isLoggedIn));
}

/**
 * Used to store user data into the localstorage
 * @param {Object} userData User data object
 */
export const AccessLocalIsLoggedIn = ()=>{
    //Saving data to the local storage
   return ReactSession.get("DigIsLoggedIn");
}

/**
 * Store the token data into local storage
 * @param {Object} tokenData - Token data
 */
export const UpdateLocalAccessToken = (tokenData)=>{
    const { token, tokenExpiresIn} = tokenData;
     //Saving token to the local storage
     ReactSession.set("DigToken", token);

     //Save the token expires
     ReactSession.set('DigTokenExpiresIn',tokenExpiresIn)
}

/**
 * Remove all set local data
 */
export const RemoveAllLocalData = ()=>{
    ReactSession.remove("DigUserdata");
    ReactSession.remove("DigToken");
    ReactSession.remove("DigTokenExpiresIn");
    ReactSession.remove("DigPermissions");
}

export const UpdateLocalPermission = (permission)=>{
    const encryptedData = encryptData(permission);
    if(encryptedData){
        ReactSession.set('DigPermissions',encryptedData);
    }
}

export const getLocalAccessPermissions = ()=>{
    const encryptedData = ReactSession.get('DigPermissions');
    if (encryptedData) {
        return decryptData(encryptedData);
    }
    return null;
}