import React from 'react';
import { Col, Row } from 'reactstrap';


const BreadCrumb = ({title,pageTitle,subTittle})=>{
    return(
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <div>
                        <h5 className='mb-sm-0'>{title}</h5>
                        <small className='text-muted fs-12'>{subTittle}</small>
                        </div>
                        <nav  className='page-title-right' aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">{pageTitle}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {title}
                                </li>
                            </ol>
                        </nav>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BreadCrumb;