import { createContext, useReducer } from "react";
import Users from "./Reducers/Users";
import UsersStates from "./InitialsStates/UsersStates";
import MastersStates from "./InitialsStates/MastersStates";
import Masters from "./Reducers/Masters";
import FoodMenuMasterStates from "./InitialsStates/FoodMenuMasterStates";
import FoodMenus from "./Reducers/FoodMenus";
import BroadcastStates from "./InitialsStates/BroadcastStates";
import Broadcasts from "./Reducers/Broadcasts";


export const GlobalStore = createContext({})

const StateStores = ({children}) => {
    const [UsersState,UsersDispatch] = useReducer(Users,UsersStates);
    const [MasterState,MasterDispatch] = useReducer(Masters,MastersStates);
    const [FoodMenuMasterState,FoodMenuMasterDispatch] = useReducer(FoodMenus,FoodMenuMasterStates);
    const [BroadcastState,BroadcastDispatch] = useReducer(Broadcasts,BroadcastStates);

    return <GlobalStore.Provider 
    value={{
        UsersState,
        MasterState,
        FoodMenuMasterState,
        BroadcastState,
        UsersDispatch,
        MasterDispatch,
        FoodMenuMasterDispatch,
        BroadcastDispatch
    }}>
        {children}
    </GlobalStore.Provider>
}

export default StateStores;