import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, CardText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../../../Layouts/Header';
import BreadCrumb from '../../Common/BreadCrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AccessLocalUserData } from '../../../Helpers/LocalService';
import { GlobalStore } from '../../../StateStores';
import { toast } from 'react-toastify';
import { deactivateAccountAct, UserLogout } from '../../../Actions/Users/AuthActs';

const UserProfile = () => {
    const [userData,setUserData] = useState({});
    const [profilePicture,setProfilePicture] = useState('');
    const [modal, setModal] = useState(false);
    const {UsersDispatch,UsersState} = useContext(GlobalStore);

    // Toggle Modal for Deactivation Confirmation
    const toggle = () => setModal(!modal);

    useEffect(()=>{
        async function fetchLocalData(){
            let user = await AccessLocalUserData();
            if(user){
                let parseData = JSON.parse(user);
                setUserData(parseData);
            }
        }
        fetchLocalData();
    },[])

    const {fname,mname,lname,userid,email,rolename,username} = userData;

    
    const handleDeactivate = async () => {
        try {
            const response = await deactivateAccountAct(UsersDispatch);
            if(response.status){
                UserLogout(UsersDispatch);
            }else{
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <div className="wrapper-top"></div>

            <Container fluids>
                {/* <BreadCrumb title="User Profile" /> */}

                <Row className="justify-content-center">
                    <Col md="8">
                        <Card className="p-3 mb-4 shadow-none border-0">
                            <Row className="align-items-center">
                                <Col md="4" className="text-center">
                                    {/* Profile Picture */}
                                    <img
                                        src="/50.png"
                                        alt="Profile"
                                        className="rounded-circle img-thumbnail"
                                        width="150"
                                        height="150"
                                    />
                                    {/* <Button className="mt-3" color="primary">
                                        Upload Picture
                                    </Button> */}
                                </Col>

                                <Col md="8">
                                    {/* Personal Details Section */}
                                    <CardBody>
                                        <CardTitle tag="h4">Personal Details</CardTitle>
                                        <CardText>
                                            <strong>Name: </strong> {`${fname} ${mname} ${lname}`}
                                        </CardText>
                                        <CardText>
                                            <strong>Email: </strong> {email}
                                        </CardText>
                                        <CardText>
                                            <strong>Role: </strong> {rolename}
                                        </CardText>
                                        <CardText>
                                            <strong>Username: </strong> {username}
                                        </CardText>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>

                        {/* Activity Section */}
                        <Card className="p-3 shadow-none border-0"> 
                            <CardBody>
                                {/* <CardTitle tag="h4">Activity</CardTitle>
                                <CardText>
                                    <strong>Member since: </strong> January 2023
                                </CardText>
                                <CardText>
                                    <strong>Last login: </strong> October 22, 2024
                                </CardText> */}

                                {/* Deactivate Account Button */}
                                <Button 
                                    color="danger" 
                                    className='rounded-0 flex-grow-1 black-backgrounds custom-button d-flex align-items-center justify-content-center'
                                    onClick={toggle}>
                                    <span className="icon-circle yellow-background fs-color-black"><FontAwesomeIcon icon={faTimes}/></span>
                                    <span className='ms-1'>Deactivate Account</span>
                                </Button>
                            </CardBody>
                        </Card>

                        {/* Modal for Confirmation */}
                        <Modal className='rounded-0 custom-modal' isOpen={modal} toggle={toggle}>
                            <ModalHeader className='yellow-background border-0' toggle={toggle}>Confirm Deactivation</ModalHeader>
                            <ModalBody>
                                Are you sure you want to deactivate your account? This action cannot be undone.
                            </ModalBody>
                            <ModalFooter>
                                <Button 
                                color="danger" 
                                className='rounded-0 flex-grow-1 black-backgrounds custom-button d-flex align-items-center justify-content-center'
                                onClick={handleDeactivate}>
                                    <span className="icon-circle yellow-background fs-color-black"><FontAwesomeIcon icon={faTimes}/></span>
                                    <span className='ms-1'>Deactivate</span>
                                </Button>{' '}
                                <Button 
                                    color="danger" 
                                    className='rounded-0 flex-grow-1 black-backgrounds custom-button d-flex align-items-center justify-content-center'
                                    onClick={toggle}>
                                        <span className="icon-circle yellow-background fs-color-black"><FontAwesomeIcon icon={faTimes}/></span>
                                        <span className='ms-1'>Close</span>
                                    </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default UserProfile;