import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../Layouts/Header';
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import TicketTypeCard from './TicketTypeCard';
import SoldTicketsList from './SoldTicketsList';
import { useLocation } from 'react-router-dom';
import { GlobalStore } from '../../../StateStores';
import { getEventDetailsAct } from '../../../Actions/Masters/EventCategoriesActs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBan, faEdit, faFileAlt, faPlus, faTimesCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { NavLink as RouterNavLink  } from 'react-router-dom';
import moment from 'moment';
import EventCreate from '../Events/EventCreate';
import { SERVER_BASE_URL } from '../../../Helpers/AxiosHelper';
import RSVPCard from './RSVPCard';
import RSVPUsers from './RSVPUsers';
import ActionButtons from '../../ActionButtons';

const EventViewer = ()=>{
    const location = useLocation();
    const event = location.state?.event;
    const [ticketTypes, setTicketTypes] = useState([]);
    const [soldTickets, setSoldTickets] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    const [modal, setModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [rsvpData, setRsvpData] = useState([]);
    const [rsvpTotals, setRsvpTotals] = useState({ confirmed: 0, waiting: 0, declined: 0, notResponded: 0 });
    const [rsvpUsers, setRsvpUsers] = useState([]);
    
    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const onCloseModal = ()=>{}

    const {
        MasterDispatch,
        MasterState:{
            getEventDetails:{data,error,loading},
        }} = useContext(GlobalStore);
    
    useEffect(()=>{
        function fetchEventDetails(){
            getEventDetailsAct(event)(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    const {solid_events,ticket_types,event_rsvp} = res.data[0]
                    setSoldTickets(solid_events)
                    setTicketTypes(ticket_types)

                    setRsvpUsers(event_rsvp)

                    const confirmed = event_rsvp.filter(d => d.final_status === 'Confirmed').length;
                    const declined = event_rsvp.filter(d => d.final_status === 'Declined').length;
                    const waiting = event_rsvp.filter(d => d.final_status === 'Waiting').length;
                    const notResponded = event_rsvp.filter(d => d.final_status === 'Not Responded').length;
                    setRsvpTotals({ confirmed, waiting, declined, notResponded });

                    setRsvpData([
                        { name: 'Confirmed', value: confirmed },
                        { name: 'Waiting', value: waiting },
                        { name: 'Declined', value: declined },
                        { name: 'Not Responded', value: notResponded }
                    ]);

                    
                }
            })
        }
        fetchEventDetails()
    },[event])
    

    if (!event) {
        return <div>No event data available</div>;
    }

    return(
        <React.Fragment>
            <Header/>
            <div className="event-dashboard">
                <div className="event-cover-image">
                    <div className="wrapper-top-60"></div>
                    <div className="event-cover-text">
                        <h1>{event?.event_name}</h1>
                        <p className='mb-0'>{event?.description}</p>
                        <div className='mb-1 d-flex justify-content-end'>
                            <small className="text-muted">{moment(event.from_date).format('LL HH:mm:ssa')} ({moment(event.from_date).startOf().fromNow()})</small>
                        </div>
                        {/* <Button
                        color="dark" 
                        onClick={(e)=>{togOpenModal()}}
                        className="custom-button rounded-0 enter-class-button">
                            <FontAwesomeIcon icon={faEdit} /> Edit event
                        </Button> */}
                    </div>
                    <div className='back-button'>
                        <RouterNavLink 
                            to="/dashboard/events"
                            className="fs-4 fs-color-black">
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </RouterNavLink>
                    </div>
                </div>
                <div className="main-content">
                    <Container className="content-container">
                        <Row className="mb-4 task-list-card">
                            {loading && (
                                <div className='d-flex justify-content-center p-3'>
                                    <Spinner color='dark' size="sm"/>
                                </div>
                            )}
                            
                            <Row>
                                <Col>
                                    <RSVPCard totals={rsvpTotals} rsvpData={rsvpData}/>
                                    <p>Action button</p>
                                    <ActionButtons 
                                    event={event}
                                    selectedCategory={event}
                                    setSelectedCategory={setSelectedCategory}
                                    selectedEvent={event}/>
                                </Col>
                                <Col>
                                    {!loading && (ticketTypes || []).map((ticketType,index) => (
                                        <TicketTypeCard key={index} ticketType={ticketType} />
                                    ))}
                                </Col>
                            </Row>

                            {ticketTypes.length === 0 && (
                                <Col>
                                    <Card className="task-card">
                                        <CardBody>
                                            <small className='text-danger'>
                                                <FontAwesomeIcon icon={faBan}/> No Ticket type data
                                            </small>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}


                            {/* <Col md={3}>
                                <Card className="task-card">
                                    <CardBody>
                                    <CardTitle tag="h5">Examen</CardTitle>
                                    <CardText>Literatura Gótica</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card className="task-card">
                                    <CardBody>
                                    <CardTitle tag="h5">Foro de Discusión</CardTitle>
                                    <CardText>Entregar Monografía</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card className="task-card">
                                    <CardBody>
                                    <CardTitle tag="h5">Subir Archivo</CardTitle>
                                    <CardText>Entregar Ensayo</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Card className="task-card">
                                    <CardBody>
                                    <CardTitle tag="h5">Subir Archivo</CardTitle>
                                    <CardText>Franz Kafka</CardText>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink 
                                            href="#"
                                            className={activeTab === '1' ? 'active' : ''}
                                            onClick={() => { toggleTab('1'); }}>
                                            <i className="fa fa-file-waveform"></i> Sold Tickets
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            href="#"
                                            className={activeTab === '2'? 'active':''}
                                            onClick={() => { toggleTab('2'); }}>
                                            <i className="fa fa-money-bill"></i> Invitation/Ticket types
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            href="#"
                                            className={activeTab === '3'? 'active':''}
                                            onClick={() => { toggleTab('3'); }}>
                                            <i className="fa fa-money-bill"></i> RSVP
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} >
                                    <TabPane tabId="1">
                                        <SoldTicketsList eventData={event} soldTickets={soldTickets} />
                                    </TabPane>
                                    <TabPane tabId="2"></TabPane>
                                    <TabPane tabId="3">
                                        <RSVPUsers rsvpData={rsvpUsers}/>
                                    </TabPane>
                                </TabContent>


                            </Col>
                            <Col md={4}>
                                <div className='position-sticky top-20'>
                                    <Card className="border-0 boxshadow materials-card mb-3">
                                        <CardBody>

                                            {loading && (
                                                <div className='d-flex justify-content-center p-3'>
                                                    <Spinner color='dark' size="sm"/>
                                                </div>
                                            )}

                                            {!loading && data[0]?.template?.edited_template_cover? (
                                                <>
                                                    <h5 className='text-capitalize fs-12'>{data[0]?.template?.template_name}</h5>
                                                    <img
                                                    src={`${SERVER_BASE_URL}/cdn-template-sample-edited/${encodeURIComponent(data[0]?.template?.edited_template_cover)}`}
                                                    crossorigin="anonymous"
                                                    alt={data[0]?.template?.edited_template_cover} 
                                                    className="template-image card-img-top" />
                                                </>
                                            ):(
                                                <small className='text-danger'><FontAwesomeIcon icon={faBan}/> No template</small>
                                            )}
                                        </CardBody>
                                    </Card>

                                    <Card className="border-0 boxshadow materials-card">
                                        <CardBody>

                                            {loading && (
                                                <div className='d-flex justify-content-center p-3'>
                                                    <Spinner color='dark' size="sm"/>
                                                </div>
                                            )}

                                            {!loading && data[0]?.template?.template_name? (
                                                <>
                                                    <h5 className='text-capitalize fs-12'>{data[0]?.template?.template_name}</h5>
                                                    <img
                                                    src={`${SERVER_BASE_URL}/cdn-template-sample/${encodeURIComponent(data[0]?.template?.template_image)}`}
                                                    crossorigin="anonymous"
                                                    alt={data[0]?.template?.template_name} 
                                                    className="template-image card-img-top" />
                                                </>
                                            ):(
                                                <small className='text-danger'><FontAwesomeIcon icon={faBan}/> No template</small>
                                            )}
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>



















            {/* <Container>
                <Row>
                    {ticketTypes.map(ticketType => (
                    <Col key={ticketType.id}>
                        <TicketTypeCard ticketType={ticketType} />
                    </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <h3>Sold Tickets</h3>
                        <SoldTicketsList soldTickets={soldTickets} />
                    </Col>
                </Row>
            </Container> */}
        </React.Fragment>
    )
}

export default EventViewer;