import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Form, FormFeedback, FormGroup, Input, InputGroup, Label, Row, Spinner } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { toast } from 'react-toastify';
import { createEventTables, getEventTables } from '../../../Actions/Masters/EventTablesActs';
import QRCode from 'qrcode.react';
import { EVENT_MENU_ORDER } from '../../../Helpers/AxiosHelper';

const EventTables = ({selectedEvent})=>{
    const [formValid, setFormValid] = useState(false);
    const [form,setForm] = useState({quantity:'',});
    const [formErrors,setFormErrors] = useState({quantity:'Quantity is required',});

    const {
        MasterDispatch,
        MasterState:{
            generateEventTableState:{data:postTableData,error:postTableError,loading:postTableLoading},
            eventTableState:{data:tableListData,error:tableListError,loading:tableListLoading},
        }} = useContext(GlobalStore);

    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    useEffect(()=>{
        const loadEventTables = async ()=>{
            if(selectedEvent?.id !== undefined){
                try {
                    const response = await getEventTables({event_id:selectedEvent?.id})(MasterDispatch);
                } catch (error) {
                    toast.error(error.message)
                }
            }
        }
        loadEventTables()
    },[])

    const onChange = ({name,e}) => {
        let value = e.target.value;

        setForm((prev) => ({ ...prev, [name]: value }));

        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }
    }

    const handleAddEventTable = async (e) => {
        e.preventDefault();

        if(formValid){
            createEventTables({
                ...form,
                event_id:selectedEvent?.id,
            })(MasterDispatch)((res)=>{
                if(res.status){
                    toast.success(res.message)
                    // setEventTicketTypes([...eventTicketTypes,res.data])
                    //Clear the state, ready for new ticket
                    setForm({quantity:'',});
                    setFormErrors({
                        quantity:'Quantity is required',
                    });

                }else{
                    toast.error(res.message)
                }
            })
        }
    }

    return(
        <React.Fragment>
            <Form onSubmit={handleAddEventTable} className='mb-3'>
                <Row className="align-items-center">
                    <Col>
                        <Label for="quantity">Quantity</Label>
                        <InputGroup>
                            <Input
                                type="number"
                                className="rounded-0"
                                placeholder='Number'
                                name="quantity"
                                id="quantity"
                                value={form?.quantity}
                                onChange={(e)=>onChange({name:'quantity',e})}
                                valid={formErrors.quantity === ''}
                                invalid={formErrors.quantity !== ''}
                            />
                            <Button
                                color="dark"
                                disabled={!formValid}
                                className="custom-button rounded-0"
                            >
                                {postTableLoading && <Spinner size="sm"/>}
                               {!postTableLoading && 'Generate'}
                            </Button>
                            {formErrors.quantity && <FormFeedback>{formErrors.quantity}</FormFeedback>}
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
            <h6>Added / Generated event table</h6>
            <hr/>

            {tableListLoading && <div className='d-flex justify-content-center p-2'><Spinner size="sm"/></div>}

            {!tableListLoading && tableListData.length > 0 && (
                <div className='d-flex justify-content-center'>
                    <div>
                        {(tableListData || []).map((table,index) => (
                            <Card key={index} className='mb-2'>
                                <CardBody>
                                    <QRCode value={`${EVENT_MENU_ORDER}${
                                        btoa(JSON.stringify({
                                            id:table?.id,
                                            name:table?.name,
                                            event_id:table?.event_id
                                        }))
                                    }`}/>
                                </CardBody>
                                <CardFooter>{table.name}</CardFooter>
                            </Card>
                        ))}
                    </div>
                </div>
            )}

        </React.Fragment>
    )
}

export default EventTables;