export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';

export const SIGNUP_LOADING="SIGNUP_LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const CLEAR_SIGNUP_STATE = "CLEAR_SIGNUP_STATE";

export const DEACTIVATE_ACCOUNT_LOADING="DEACTIVATE_ACCOUNT_LOADING";
export const DEACTIVATE_ACCOUNT_SUCCESS = "DEACTIVATE_ACCOUNT_SUCCESS";
export const DEACTIVATE_ACCOUNT_FAIL = "DEACTIVATE_ACCOUNT_FAIL";

export const GET_EVENT_CATEGORIES_LOADING = 'GET_EVENT_CATEGORIES_LOADING';
export const GET_EVENT_CATEGORIES_SUCCESS = 'GET_EVENT_CATEGORIES_SUCCESS';
export const GET_EVENT_CATEGORIES_FAIL = 'GET_EVENT_CATEGORIES_FAIL';
export const CLEAR_GET_EVENT_CATEGORIES_STATE = 'CLEAR_GET_EVENT_CATEGORIES_STATE';

export const POST_EVENT_LOADING = 'POST_EVENT_LOADING';
export const POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS';
export const POST_EVENT_FAIL = 'POST_EVENT_FAIL';
export const CLEAR_POST_EVENT_STATE = 'CLEAR_POST_EVENT_STATE';

export const GET_EVENT_LOADING = 'GET_EVENT_LOADING';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';
export const CLEAR_GET_EVENT_STATE = 'CLEAR_GET_EVENT_STATE';

export const GET_TICKET_TYPES_LOADING = 'GET_TICKET_TYPES_LOADING';
export const GET_TICKET_TYPES_SUCCESS = 'GET_TICKET_TYPES_SUCCESS';
export const GET_TICKET_TYPES_FAIL = 'GET_TICKET_TYPES_FAIL';

export const GET_EVENT_TICKET_TYPES_LOADING = 'GET_EVENT_TICKET_TYPES_LOADING';
export const GET_EVENT_TICKET_TYPES_SUCCESS = 'GET_EVENT_TICKET_TYPES_SUCCESS';
export const GET_EVENT_TICKET_TYPES_FAIL = 'GET_EVENT_TICKET_TYPES_FAIL';

export const POST_EVENT_TICKET_TYPES_LOADING = 'POST_EVENT_TICKET_TYPES_LOADING';
export const POST_EVENT_TICKET_TYPES_SUCCESS = 'POST_EVENT_TICKET_TYPES_SUCCESS';
export const POST_EVENT_TICKET_TYPES_FAIL = 'POST_EVENT_TICKET_TYPES_FAIL';

export const POST_ORDER_EVENT_TICKET_TYPES_LOADING = 'POST_ORDER_EVENT_TICKET_TYPES_LOADING';
export const POST_ORDER_EVENT_TICKET_TYPES_SUCCESS = 'POST_ORDER_EVENT_TICKET_TYPES_SUCCESS';
export const POST_ORDER_EVENT_TICKET_TYPES_FAIL = 'POST_ORDER_EVENT_TICKET_TYPES_FAIL';

export const GET_EVENT_DETAILS_LOADING = 'GET_EVENT_DETAILS_LOADING';
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';
export const GET_EVENT_DETAILS_FAIL = 'GET_EVENT_DETAILS_FAIL';

export const GET_GENERATE_INVITATION_CARD_LOADING = 'GET_GENERATE_INVITATION_CARD_LOADING';
export const GET_GENERATE_INVITATION_CARD_SUCCESS = 'GET_GENERATE_INVITATION_CARD_SUCCESS';
export const GET_GENERATE_INVITATION_CARD_FAIL = 'GET_GENERATE_INVITATION_CARD_FAIL';

export const GET_INVITATION_TEMPLATES_LOADING = 'GET_INVITATION_TEMPLATES_LOADING';
export const GET_INVITATION_TEMPLATES_SUCCESS = 'GET_INVITATION_TEMPLATES_SUCCESS';
export const GET_INVITATION_TEMPLATES_FAIL = 'GET_INVITATION_TEMPLATES_FAIL';

export const POST_SET_EVENT_TEMPLATE_LOADING = 'POST_SET_EVENT_TEMPLATE_LOADING';
export const POST_SET_EVENT_TEMPLATE_SUCCESS = 'POST_SET_EVENT_TEMPLATE_SUCCESS';
export const POST_SET_EVENT_TEMPLATE_FAIL = 'POST_SET_EVENT_TEMPLATE_FAIL';

export const GET_SHARE_EVENT_CARD_LOADING = 'GET_SHARE_EVENT_CARD_LOADING';
export const GET_SHARE_EVENT_CARD_SUCCESS = 'GET_SHARE_EVENT_CARD_SUCCESS';
export const GET_SHARE_EVENT_CARD_FAIL = 'GET_SHARE_EVENT_CARD_FAIL';

export const GET_SEARCH_USER_LOADING = 'GET_SEARCH_USER_LOADING';
export const GET_SEARCH_USER_SUCCESS = 'GET_SEARCH_USER_SUCCESS';
export const GET_SEARCH_USER_FAIL = 'GET_SEARCH_USER_FAIL';

export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const POST_USER_LOADING = 'POST_USER_LOADING';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAIL = 'POST_USER_FAIL';

export const POST_TEMPLATE_EDITED_COVER_LOADING = 'POST_TEMPLATE_EDITED_COVER_LOADING';
export const POST_TEMPLATE_EDITED_COVER_SUCCESS = 'POST_TEMPLATE_EDITED_COVER_SUCCESS';
export const POST_TEMPLATE_EDITED_COVER_FAIL = 'POST_TEMPLATE_EDITED_COVER_FAIL';

export const GET_EVENT_APPROVAL_REQUEST_LOADING = 'GET_EVENT_APPROVAL_REQUEST_LOADING';
export const GET_EVENT_APPROVAL_REQUEST_SUCCESS = 'GET_EVENT_APPROVAL_REQUEST_SUCCESS';
export const GET_EVENT_APPROVAL_REQUEST_FAIL = 'GET_EVENT_APPROVAL_REQUEST_FAIL';

export const GET_USER_GROUPS_LOADING = 'GET_USER_GROUPS_LOADING';
export const GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS';
export const GET_USER_GROUPS_FAIL = 'GET_USER_GROUPS_FAIL';

export const GET_USER_GROUP_RIGHTS_LOADING = 'GET_USER_GROUP_RIGHTS_LOADING';
export const GET_USER_GROUP_RIGHTS_SUCCESS = 'GET_USER_GROUP_RIGHTS_SUCCESS';
export const GET_USER_GROUP_RIGHTS_FAIL = 'GET_USER_GROUP_RIGHTS_FAIL';

export const POST_USER_GROUP_RIGHTS_LOADING = 'POST_USER_GROUP_RIGHTS_LOADING';
export const POST_USER_GROUP_RIGHTS_SUCCESS = 'POST_USER_GROUP_RIGHTS_SUCCESS';
export const POST_USER_GROUP_RIGHTS_FAIL = 'POST_USER_GROUP_RIGHTS_FAIL';

export const POST_RSVP_LOADING = 'POST_RSVP_LOADING';
export const POST_RSVP_SUCCESS = 'POST_RSVP_SUCCESS';
export const POST_RSVP_FAIL = 'POST_RSVP_FAIL';
export const CLEAR_POST_RSVP_STATE = 'CLEAR_POST_RSVP_STATE';

export const GET_EVENT_TICKET_CARD_INFO_LOADING = 'GET_EVENT_TICKET_CARD_INFO_LOADING';
export const GET_EVENT_TICKET_CARD_INFO_SUCCESS = 'GET_EVENT_TICKET_CARD_INFO_SUCCESS';
export const GET_EVENT_TICKET_CARD_INFO_FAIL = 'GET_EVENT_TICKET_CARD_INFO_FAIL';
export const CLEAR_EVENT_TICKET_CARD_INFO_STATE = "CLEAR_EVENT_TICKET_CARD_INFO_STATE";

export const POST_EVENT_TICKET_CARD_VERIFICATION_LOADING = 'POST_EVENT_TICKET_CARD_VERIFICATION_LOADING';
export const POST_EVENT_TICKET_CARD_VERIFICATION_SUCCESS = 'POST_EVENT_TICKET_CARD_VERIFICATION_SUCCESS';
export const POST_EVENT_TICKET_CARD_VERIFICATION_FAIL = 'POST_EVENT_TICKET_CARD_VERIFICATION_FAIL';
export const CLEAR_EVENT_TICKET_CARD_VERIFICATION_STATE = "CLEAR_EVENT_TICKET_CARD_VERIFICATION_STATE";

export const POST_EVENT_TABLE_LOADING = 'POST_EVENT_TABLE_LOADING';
export const POST_EVENT_TABLE_SUCCESS = 'POST_EVENT_TABLE_SUCCESS';
export const POST_EVENT_TABLE_FAIL = 'POST_EVENT_TABLE_FAIL';
export const CLEAR_POST_EVENT_TABLE_STATE = 'CLEAR_POST_EVENT_TABLE_STATE';

export const GET_EVENT_TABLES_LOADING = 'GET_EVENT_TABLES_LOADING';
export const GET_EVENT_TABLES_SUCCESS = 'GET_EVENT_TABLES_SUCCESS';
export const GET_EVENT_TABLES_FAIL = 'GET_EVENT_TABLES_FAIL';
export const CLEAR_GET_EVENT_TABLES_STATE = 'CLEAR_GET_EVENT_TABLES_STATE';

export const GET_FOOD_MENU_CATEGORY_LOADING = 'GET_FOOD_MENU_CATEGORY_LOADING';
export const GET_FOOD_MENU_CATEGORY_SUCCESS = 'GET_FOOD_MENU_CATEGORY_SUCCESS';
export const GET_FOOD_MENU_CATEGORY_FAIL = 'GET_FOOD_MENU_CATEGORY_FAIL';
export const CLEAR_GET_FOOD_MENU_CATEGORY_STATE = 'CLEAR_GET_FOOD_MENU_CATEGORY_STATE';

export const POST_FOOD_MENU_CATEGORY_LOADING = 'POST_FOOD_MENU_CATEGORY_LOADING';
export const POST_FOOD_MENU_CATEGORY_SUCCESS = 'POST_FOOD_MENU_CATEGORY_SUCCESS';
export const POST_FOOD_MENU_CATEGORY_FAIL = 'POST_FOOD_MENU_CATEGORY_FAIL';
export const CLEAR_POST_FOOD_MENU_CATEGORY_STATE = 'CLEAR_POST_FOOD_MENU_CATEGORY_STATE';

export const GET_FOOD_MENU_ITEMS_LOADING = 'GET_FOOD_MENU_ITEMS_LOADING';
export const GET_FOOD_MENU_ITEMS_SUCCESS = 'GET_FOOD_MENU_ITEMS_SUCCESS';
export const GET_FOOD_MENU_ITEMS_FAIL = 'GET_FOOD_MENU_ITEMS_FAIL';
export const CLEAR_GET_FOOD_MENU_ITEMS_STATE = 'CLEAR_GET_FOOD_MENU_ITEMS_STATE';

export const POST_FOOD_MENU_ITEM_LOADING = 'POST_FOOD_MENU_ITEM_LOADING';
export const POST_FOOD_MENU_ITEM_SUCCESS = 'POST_FOOD_MENU_ITEM_SUCCESS';
export const POST_FOOD_MENU_ITEM_FAIL = 'POST_FOOD_MENU_ITEM_FAIL';
export const CLEAR_POST_FOOD_MENU_ITEM_STATE = 'CLEAR_POST_FOOD_MENU_ITEM_STATE';

export const GET_EVENT_MENU_ITEMS_LOADING = 'GET_EVENT_MENU_ITEMS_LOADING';
export const GET_EVENT_MENU_ITEMS_SUCCESS = 'GET_EVENT_MENU_ITEMS_SUCCESS';
export const GET_EVENT_MENU_ITEMS_FAIL = 'GET_EVENT_MENU_ITEMS_FAIL';
export const CLEAR_GET_EVENT_MENU_ITEMS_STATE = 'CLEAR_GET_EVENT_MENU_ITEMS_STATE';

export const POST_EVENT_MENU_ITEM_LOADING = 'POST_EVENT_MENU_ITEM_LOADING';
export const POST_EVENT_MENU_ITEM_SUCCESS = 'POST_EVENT_MENU_ITEM_SUCCESS';
export const POST_EVENT_MENU_ITEM_FAIL = 'POST_EVENT_MENU_ITEM_FAIL';
export const CLEAR_POST_EVENT_MENU_ITEM_STATE = 'CLEAR_POST_EVENT_MENU_ITEM_STATE';

export const GET_EVENT_SELECTED_MENU_ITEMS_LOADING = 'GET_EVENT_SELECTED_MENU_ITEMS_LOADING';
export const GET_EVENT_SELECTED_MENU_ITEMS_SUCCESS = 'GET_EVENT_SELECTED_MENU_ITEMS_SUCCESS';
export const GET_EVENT_SELECTED_MENU_ITEMS_FAIL = 'GET_EVENT_SELECTED_MENU_ITEMS_FAIL';
export const CLEAR_GET_EVENT_SELECTED_MENU_ITEMS_STATE = 'CLEAR_GET_EVENT_SELECTED_MENU_ITEMS_STATE';

export const POST_PLACE_ORDER_LOADING = 'POST_PLACE_ORDER_LOADING';
export const POST_PLACE_ORDER_SUCCESS = 'POST_PLACE_ORDER_SUCCESS';
export const POST_PLACE_ORDER_FAIL = 'POST_PLACE_ORDER_FAIL';
export const CLEAR_POST_PLACE_ORDER_STATE = 'CLEAR_POST_PLACE_ORDER_STATE';

export const GET_BANNERS_LOADING = 'GET_BANNERS_LOADING';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAIL = 'GET_BANNERS_FAIL';
export const CLEAR_GET_BANNERS_STATE = 'CLEAR_GET_BANNERS_STATE';

export const POST_BANNERS_LOADING = 'POST_BANNERS_LOADING';
export const POST_BANNERS_SUCCESS = 'POST_BANNERS_SUCCESS';
export const POST_BANNERS_FAIL = 'POST_BANNERS_FAIL';
export const CLEAR_POST_BANNERS_STATE = 'CLEAR_POST_BANNERS_STATE';

export const POST_USERS_BULK_UPLOAD_LOADING="POST_USERS_BULK_UPLOAD_LOADING";
export const POST_USERS_BULK_UPLOAD_SUCCESS = "POST_USERS_BULK_UPLOAD_SUCCESS";
export const POST_USERS_BULK_UPLOAD_FAIL = "POST_USERS_BULK_UPLOAD_FAIL";
export const CLEAR_POST_USERS_BULK_UPLOAD_STATE = "CLEAR_POST_USERS_BULK_UPLOAD_STATE";

export const POST_BROADCAST_LOADING = 'POST_BROADCAST_LOADING';
export const POST_BROADCAST_SUCCESS = 'POST_BROADCAST_SUCCESS';
export const POST_BROADCAST_FAIL = 'POST_BROADCAST_FAIL';
export const CLEAR_POST_BROADCAST_STATE = 'CLEAR_POST_BROADCAST_STATE';