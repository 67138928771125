import React, { useState } from "react";
import { DateRangePicker } from "react-dates";

const DateFilter = ({ startDate, endDate, onDatesChange })=>{
    const [focusedInput, setFocusedInput] = useState(null);

    const handleDatesChange = ({ startDate, endDate }) => {
        onDatesChange({ startDate, endDate });
    };

    return(
        <React.Fragment>
            <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                startDateId="start_date"
                endDateId="end_date"
                isOutsideRange={() => false} // Allow selection of any date
                displayFormat="DD-MM-YYYY"
            />
        </React.Fragment>
    )
}

export default DateFilter;