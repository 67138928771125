import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormFeedback, Input, InputGroup, Label, Row, Spinner, Table } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { toast } from 'react-toastify';
import { createFoodMenuCategories, getFoodMenuCategories } from '../../../Actions/Masters/FoodMenuActs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

const FoodMenuCategory = ()=>{
    const [formValid, setFormValid] = useState(false);
    const [foodMenuCategories, setFoodMenuCategories] = useState([]);
    const [form,setForm] = useState({category_name:'',});
    const [formErrors,setFormErrors] = useState({
        category_name:'Category name is required',
    });

    const {
        FoodMenuMasterDispatch,
        FoodMenuMasterState:{
            postFoodMenuCategory:{data:saveData,error:saveError,loading:saveLoading},
            foodMenuCategory:{data:categories,error,loading},
        }} = useContext(GlobalStore);

    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    /**
     * Method that used to request the food menu category
     */
    const loadFoodMenuCategories = async ()=>{
        try {
            const response = await getFoodMenuCategories({})(FoodMenuMasterDispatch);
            setFoodMenuCategories(response)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(()=>{
        loadFoodMenuCategories()
    },[])

    const onChange = ({name,e}) => {
        let value = e.target.value;

        setForm((prev) => ({ ...prev, [name]: value }));

        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }
    }

    /**
     * Method for submit the category
     * @param {Event} e Object
     */
    const handleAddCategory = async (e) => {
        e.preventDefault();

        if(formValid){
            createFoodMenuCategories(form)(FoodMenuMasterDispatch)((res)=>{
                if(res.status){
                    toast.success(res.message);
                    //Pre-Append category to the main state/list
                    setFoodMenuCategories([res.data[0],...foodMenuCategories])
                    setForm({category_name:'',});
                    setFormErrors({
                        category_name:'Category name is required',
                    });

                }else{
                    toast.error(res.message)
                }
            })
        }
    }

    /**
     * Used to refresh the category list
     */
    const reRefreshList = ()=>{
        loadFoodMenuCategories()
    }

    return(
        <React.Fragment>
            <Form onSubmit={handleAddCategory} className='mb-3'>
                <Row className="align-items-center g-3">
                    <Col>
                        <Label for="quantity">Name</Label>
                        <InputGroup>
                            <Input
                                type="text"
                                className="rounded-0"
                                placeholder='Name'
                                name="category_name"
                                id="category_name"
                                value={form?.category_name}
                                onChange={(e)=>onChange({name:'category_name',e})}
                                valid={formErrors.category_name === ''}
                                invalid={formErrors.category_name !== ''}
                            />
                            <Button
                                color="dark"
                                disabled={!formValid}
                                className="custom-button rounded-0"
                            >
                                {saveLoading && <Spinner size="sm"/>}
                                {!saveLoading && 'Save'}
                            </Button>
                            {formErrors.category_name && <FormFeedback>{formErrors.category_name}</FormFeedback>}
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
            <h6>Added categories</h6>
            {loading && <div className='d-flex justify-content-center'><Spinner size="sm"/></div>}
            <Table className="mt-3 table nowrap align-middle table-sm table-hover caption-top custom-table">
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Added by</th>
                        <th>
                            <Button 
                                size='sm' 
                                outline
                                onClick={reRefreshList}>
                                <FontAwesomeIcon icon={faRefresh}/>
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(foodMenuCategories || []).map((category, index) => (
                        <tr key={index}>
                            <td>{category.category_name}</td>
                            <td className="text-wrap">
                                <small className='text-primary'>{category.createdby}</small>
                                <div className='d-flexa'>
                                    <small className="text-muted">{moment(category.created_at).format('LL HH:mm:ssa')}</small>
                                    {/* <small className="text-info text-muted ms-1">({moment(category.created_at).startOf().fromNow()})</small> */}
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default FoodMenuCategory;