import axios from 'axios';
import axiosRetry from 'axios-retry';
import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';
import { AccessLocalToken } from './LocalService';

ReactSession.setStoreType("sessionStorage");


// export const SERVER_BASE_URL_END_POINT = 'http://localhost:8500/digikadi/api';
// export const SERVER_BASE_URL = 'http://localhost:8500';

// export const EVENT_MENU_ORDER = 'http://localhost:3000/event-menu/';
export const EVENT_MENU_ORDER = 'https://app.digikadi.co.tz/event-menu/';

export const SERVER_BASE_URL_END_POINT = 'https://api.digikadi.co.tz';
export const SERVER_BASE_URL = 'https://api.digikadi.co.tz';

const AxiosHelper = axios.create({
    baseURL:SERVER_BASE_URL_END_POINT
});

// Enable retry on network errors
axiosRetry(AxiosHelper, {
    retries: 3, // Number of retries
    retryCondition: (error) => {
        // Retry only on network errors
        return error.code === 'ECONNABORTED' || error.message === 'Network Error';
    },
    retryDelay: (retryCount) => {
        return retryCount * 1000;// Exponential backoff (1s, 2s, 4s)
    },
});

const handleRequest = async (config) => {
    const token = await AccessLocalToken()
    if (token) {
        config.headers.authorization = token;
    }
    return config;
};

const handleResponse = (response) => {
    return Promise.resolve(response);
};

const handleError = (error) => {

    if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
        Swal.fire({
            icon: 'error',
            title: 'Server Unavailable',
            text: 'The server is currently unavailable. Please try again later.',
        });
    }else if (error.response) {
        const { title, status, message } = error.response.data;

        switch (status) {
            case 401:
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized',
                    text: message,
                });
                break;
            case 403:
                Swal.fire({
                    icon: 'error',
                    title: 'Forbidden',
                    text: message,
                }).then(() => {
                    // window.location.href = '/logout'; // Uncomment if logout is needed
                });
                break;
            case 400:
                Swal.fire({
                    title: title || 'Error',
                    html: `<p><strong>${message}</strong></p>`,
                    icon: 'error',
                });
                break;
            case 404:
                Swal.fire({
                    icon: 'error',
                    title: 'Not Found',
                    text: 'The requested resource was not found',
                });
                break;
            default:
                console.error('Error:', error.response.data);
                // text: 'An error occurred. Please try again later.',
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: message,
                });
        }
        
    } else {
        console.error('Network Error:', error.message);
        Swal.fire({
            icon: 'error',
            title: 'Network Error',
            text: 'An error occurred. Please check your network connection and try again.',
        });
    }

    return Promise.reject(error);
};

AxiosHelper.interceptors.request.use(handleRequest, (error) => Promise.reject(error));
AxiosHelper.interceptors.response.use(handleResponse, handleError);

export default AxiosHelper;
