import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, Input, Label, Row, Spinner } from "reactstrap";
import { GlobalStore } from "../../StateStores";
import { createEventRsvp } from "../../Actions/Masters/EventCategoriesActs";
import Swal from "sweetalert2";

const RsvpFormRender = ({event,ticket})=>{
    const [form,setForm] = useState({is_attend:1,full_name:"",total_guests:"",notes:"",food_prereference:""});
    const [formErrors,setFormErrors] = useState({
        full_name:'Full name is required',
        is_attend:'Attendence name is required',
        total_guests:'Guests is required',
        notes:'Notes is required',
        food_prereference:'Food is required',
    });
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);


    const {
        MasterDispatch,
        MasterState:{
            postRsvpState:{data,error,loading},
        }} = useContext(GlobalStore);


    useEffect(() => {
        if (ticket && Object.keys(ticket).length > 0) {
            setForm({
                full_name: ticket.sold_by || '',
                total_guests:"",
                notes:"",
                food_prereference:""
            });

            setFormErrors({
                full_name: "",
                is_attend:'Attendence name is required',
                total_guests:'Guests is required',
                notes:'Notes is required',
                food_prereference:'Food is required',
            });
        }
    }, [ticket]);

    const onChange = ({name,e}) => {
        let value;
        if(name === 'from_date' || name === 'to_date' || name === 'contact_mobile'){
            value = e;
        }else{
            value = e.target.value;
        }

        setForm((prev) => ({ ...prev, [name]: value }));

        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }

    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if(formValid){
            
            createEventRsvp({...form,...ticket})(MasterDispatch)((res)=>{
                if(res.status){
                    Swal.fire({
                        title:'RSVP',
                        text:res.message,
                        icon:'success'
                    }).then((result)=>{
                      setForm({is_attend:1,full_name:"",total_guests:"",notes:"",food_prereference:""});
                    })
                }else{	
                    Swal.fire({
                        title:'RSVP',
                        text:res.message,
                        icon:'error'
                    })
                }
            })
        }
    }

    return(
        <React.Fragment>
            <Card className="mt-2">
                <CardBody className="p-0">
                    <div style={{
                        height: '187px',
                        backgroundSize:"inherit",
                        backgroundPosition: "center",
                        position: 'relative',
                        backgroundImage:"url('/logo-black-bg.png')",
                        backgroundColor:"#07080b",
                        backgroundRepeat:"no-repeat",
                        borderRadius:5
                    }}>
                    </div>
                </CardBody>
            </Card>
            <Form onSubmit={onSubmit}>
                <Card className="mt-2">
                    <CardHeader>{event?.event_name}</CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <p className="text-primary text-center mb-0">{moment(event?.from_date).format('LL HH:mm:ssa')} {moment(event?.from_date).startOf().fromNow()}</p>
                                <p className="text-muted text-center mb-0">{event?.description}</p>
                                <p className="text-muted text-center">{event?.location}</p>
                                <hr/>
                                <h5>{ticket?.sold_by}</h5>
                                <p className="mb-0 text-muted">{ticket?.type_name}</p>
                                <p className="text-muted">{ticket?.card_code}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mt-3">
                    <CardBody>
                        <Row>
                            <Col>
                                <Label className="text-muted">Please write your full name</Label>
                                <Input 
                                    type='text'
                                    placeholder='Your answer'
                                    className="rounded-0 bg-light"
                                    readOnly
                                    disabled
                                    value={form?.full_name}
                                    onChange={(e) => onChange({name:'full_name',e})}
                                    valid={formErrors.full_name === ''}
                                    invalid={formErrors.full_name !== ''}
                                    />
                                {formErrors.full_name && <FormFeedback>{formErrors.full_name}</FormFeedback>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mt-3">
                    <CardBody>
                        <Row>
                            <Col>
                                <div><Label className="text-muted">Can you attend ?</Label></div>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        checked={parseInt(form?.is_attend) === 1}
                                        value="1"
                                        onChange={(e) => onChange({name:'is_attend',e})}
                                        valid={formErrors.is_attend === ''}
                                        invalid={formErrors.is_attend !== ''}
                                    />
                                    <span className="ms-2">Accept with pleasure</span>
                                </Label>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        checked={parseInt(form?.is_attend) === 0}
                                        value="0"
                                        onChange={(e) => onChange({name:'is_attend',e})}
                                        valid={formErrors.is_attend === ''}
                                        invalid={formErrors.is_attend !== ''}
                                    />
                                    <span className="ms-2">Decline with regret</span>
                                </Label>
                            </Col>
                        </Row>
                        {formErrors.is_attend && <FormFeedback>{formErrors.is_attend}</FormFeedback>}
                    </CardBody>
                </Card>

                <Card className="mt-3">
                    <CardBody>
                        <Row>
                            <Col>
                                <Label className="text-muted">How many guests will be attending? </Label>
                                <Input 
                                    type='select'
                                    placeholder='Your answer'
                                    className="rounded-0"
                                    value={form?.total_guests}
                                    onChange={(e) => onChange({name:'total_guests',e})}
                                    valid={formErrors.total_guests === ''}
                                    invalid={formErrors.total_guests !== ''}
                                >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </Input>

                                    {formErrors.total_guests && <FormFeedback>{formErrors.total_guests}</FormFeedback>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mt-3">
                    <CardBody>
                        <Row>
                            <Col>
                                <Label className="text-muted">Would you like to leave a note for the couple / event ?</Label>
                                <Input 
                                    type='textarea'
                                    placeholder='Your answer'
                                    className="rounded-0"
                                    value={form?.notes}
                                    onChange={(e) => onChange({name:'notes',e})}
                                    valid={formErrors.notes === ''}
                                    invalid={formErrors.notes !== ''}
                                    />
                                    {formErrors.notes && <FormFeedback>{formErrors.notes}</FormFeedback>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mt-3">
                    <CardBody>
                        <Row>
                            <Col>
                                <Label className="text-muted">Food preference</Label>
                                <Input 
                                    type='textarea'
                                    placeholder='Your answer'
                                    className="rounded-0"
                                    value={form?.food_prereference}
                                    onChange={(e) => onChange({name:'food_prereference',e})}
                                    valid={formErrors.food_prereference === ''}
                                    invalid={formErrors.food_prereference !== ''}
                                    />
                                    {formErrors.food_prereference && <FormFeedback>{formErrors.food_prereference}</FormFeedback>}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mt-2">
                    <CardBody>
                        <Row>
                            <Col>
                                <p className="text-muted text-center">
                                    We cannot wait to share our day with you. if you have any questions please contact <br/> 
                                    <strong>{event?.contact_name} {event?.contact_mobile}</strong>
                                </p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Row className='mt-2 mb-5'>
                    <Col>
                        <Button 
                        disabled={!formValid} 
                        color="dark" type="submit" 
                        className="custom-button rounded-0">
                            {loading && <Spinner size="sm"/>}
                            {!loading && <FontAwesomeIcon icon={faSave}/>} Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default RsvpFormRender;