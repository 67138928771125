import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ShareButtons from '../../Common/ShareButtons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvitationPreview = ({isOpen,ticketData, toggle, invitationUrl, isLoading})=>{

    return(
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} size="md" className='custom-modal'>
                <ModalHeader className='yellow-background border-0' toggle={toggle}>Invitation Preview</ModalHeader>
                <ModalBody className="text-center p-0">
                    {invitationUrl ? (
                        <img src={invitationUrl} alt="Invitation" className="img-fluid" />
                    ) : (
                        <p>Loading...</p>
                    )}
                </ModalBody>
                <ModalFooter className='gx-0 p-0'>
                    <ShareButtons ticket={ticketData}/>
                    <Button 
                        outline 
                        color="danger"  
                        size='sm' className='rounded-0 black-backgrounds custom-button' onClick={toggle}>
                            <span className="icon-circle"><FontAwesomeIcon icon={faTimes}/></span>
                            <span className='ms-1'>Close</span>
                        </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default InvitationPreview;