import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../Layouts/Header';
import { Badge, Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from 'reactstrap';
import BreadCrumb from '../../Common/BreadCrumb';
import { faEdit, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalStore } from '../../../StateStores';
import { getUserGroupRights, getUserGroupsList, postUserGroupAct } from '../../../Actions/Users/UsersActs';
import { toast } from 'react-toastify';
import PermissionList from './PermissionList';
import moment from 'moment';

const UserGroups = ()=>{
    const[roles,setRoles] = useState([])
    const [modal, setModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState({});
    const [selectedRoleRights, setRolesRights] = useState({});
    const [form,setForm] = useState({rolename: '',is_for_customer_default:'no'});
    const [formErrors,setFormErrors] = useState({
      rolename: 'Name is required',
      description: 'Description is required'
    });
    const [checkedPermissions, setCheckedPermissions] = useState([]);
    const [formValid, setFormValid] = useState(false);

    const {
        UsersDispatch,
        UsersState:{
            getUserGroupsStates:{data,error,loading},
            postUserGroupsStates:{data:roleData,error:roleError,loading:roleLoading},
            getUserGroupsRightStates:{
                loading:permissionLoading,
                error:permissionError,
                data:permissionList
            },
        }} = useContext(GlobalStore);

    useEffect(()=>{
        const loadUserGroups = async ()=>{
            try {
                let response = await getUserGroupsList({})(UsersDispatch);
                setRoles(response.data)
            } catch (error) {
                toast.error(error.message)
            }
        }
        loadUserGroups()
    },[])


    useEffect(()=>{
        const loadUserGroupRights = async ()=>{
            try {
                let response = await getUserGroupRights({})(UsersDispatch);
                setRolesRights(response.data)
            } catch (error) {
                toast.error(error.message)
            }
        }
        loadUserGroupRights()
    },[])


    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const onCloseModal = ()=>{
        setSelectedRole({})
        setForm({rolename: '',is_for_customer_default:'no'});
        setCheckedPermissions([])

        //Set the error, again
      setFormErrors({
        rolename: 'Name is required',
        description: 'Description is required',
      });
    }

    useEffect(() => {
        // Check if there are any errors in the form
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    const onChange = ({name,e}) => {
        let value = e.target.value;
        setForm({...form,[name]:value});
    
        if(value !== ''){
    
          setFormErrors((prev) => {
            return {...prev, [name]: null};
          });
    
        }else{
    
          setFormErrors((prev) => {
              return {...prev, [name]: 'This field is required'};
          });
    
        }
      }

    // Function to toggle all permissions' rights across all permissions
    const toggleAllPermissionsRights = () => {
        // Create an array of objects with perm_id and r_perm_id
        const allPermissions = permissionList.flatMap(rolePermission =>
            rolePermission.permission_rights.map(permission => ({
                perm_id: permission.perm_id,
                r_perm_id: rolePermission.r_perm_id
            }))
        );
    
        // Check if all permissions are already checked
        const allChecked = allPermissions.every(perm =>
            checkedPermissions.some(checkedPerm => 
                checkedPerm.perm_id === perm.perm_id && checkedPerm.r_perm_id === perm.r_perm_id
            )
        );
    
        // Toggle permissions
        setCheckedPermissions(allChecked ? [] : allPermissions);
    };
    

    const onSubmit = async () => {

        if(checkedPermissions.length < 1){
          toast.success('Select at least one permission');
          setFormErrors((prev) => {
              return {...prev, permissionList: 'Select at least one permission'};
          });
        }else{
            form.permision_list = checkedPermissions;
        }

        if (formValid) {
            try {
                let response = await postUserGroupAct(form)(UsersDispatch);
                if(response.status){
                    toast.success(response.message)
                    togOpenModal();
                    onCloseModal();
                }else{
                    toast.error(response.message)
                }
            } catch (error) {
                toast.error(error.message)
            }
        }
    }

    const handleGroupSelect = (role) => {
        setForm({
            id: role.id || '',
            rolename: role.rolename || '',
            is_for_customer_default: role.is_for_customer_default || '',
            description: role.description || '',
        });
  
        // Clear form errors
        setFormErrors({
            rolename: '',
            terminal_type_id: '',
            description: '',
        });
      };

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>
            <Modal
            onClosed={(e)=>onCloseModal()}
            className='custom-modal rounded-0 border-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        <FontAwesomeIcon icon={form.id? faEdit:faPlus}/>
                        <span className='ms-1'>{form.id? 'Edit':'Create'} User group</span></h5>
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <Row>
                        <Col>
                            <Label>Group / Role Name</Label>
                            <Input
                                value={form?.rolename}
                                onChange={(e) => onChange({name:'rolename',e})}
                                type="text"  
                                placeholder="Enter a role name" 
                                valid={formErrors.rolename === null}
                                invalid={formErrors.rolename !== null}/>
                                <small className="text-danger">{formErrors.rolename}</small>
                        </Col>
                        <Col>
                            <Label>Default for customer</Label>
                            <Input
                                value={form?.is_for_default}
                                onChange={(e) => onChange({name:'is_for_customer_default',e})}
                                type="select"
                                >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                            </Input>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Label>Description</Label>
                            <Input
                                value={form?.description}
                                onChange={(e) => onChange({name:'description',e})}
                                type="textarea"  
                                placeholder="Enter a role name" 
                                valid={formErrors.description === null}
                                invalid={formErrors.description !== null}/>
                                <small className="text-danger">{formErrors.description}</small>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <Table className="caption-top" size='sm' bordered={false} borderless> 
                                <caption>Set role permission</caption>
                                <tbody>
                                    <tr>
                                        <td style={{width:'20%'}} className="text-nowrap">
                                            <h5 className='mb-0'>Role Permissions</h5>
                                            <small>Administrator Access </small>
                                        </td>
                                        <td style={{width:'30%'}}>
                                            <div className="form-check d-flex justify-content-end">
                                                <Input 
                                                className="form-check-input" 
                                                onChange={toggleAllPermissionsRights}
                                                type="checkbox" id="selectAll"/>
                                                <Label className="ms-1 form-check-label" for="selectAll">
                                                    Select All
                                                </Label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <div>
                        <PermissionList
                            form={form}
                            setForm={setForm}
                            permissionList={permissionList}
                            checkedPermissions={checkedPermissions}
                            setCheckedPermissions={setCheckedPermissions}
                        />
                    </div>


                </ModalBody>
                <ModalFooter>
                    <Button 
                        disabled={!formValid}
                        color="dark" 
                        className="custom-button rounded-0"
                        size="sm"
                        onClick={onSubmit}><FontAwesomeIcon icon={faSave}/> {`${form?.id?'Update':'Save'}`}</Button>
                </ModalFooter>
            </Modal>

            <Container>
                <BreadCrumb
                    title="User groups" 
                    subTittle="List of created user groups"
                    pageTitle="Users"/>

                <Card className='border-0 mt-3'>
                    <CardBody className='bg-light-f1 rounded-1 p-1'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{ width: '2%' }}>#</div>
                            <div style={{ width: '25%' }}>Name</div>
                            <div style={{ width: '10%' }}>User (s)</div>
                            <div style={{ width: '10%' }}>Default</div>
                            <div style={{ width: '20%' }}>Added At</div>
                            <div style={{ width: '20%' }}>Added By</div>
                            <div style={{ width: '5%' }}>Status</div>
                            <div style={{ width: '10%' }}>
                                <div className='d-flex justify-content-end'>
                                    <Button
                                        size='sm' 
                                        color='success'
                                        outline
                                        className='rounded-0'
                                        onClick={(e) => togOpenModal()}
                                        >
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                {loading && (
                    <div className='d-flex justify-content-center p-3'>
                        <Spinner color='dark' size="sm"/>
                    </div>
                )}

                {!loading && (data || []).map((group, index) => (
                    <Card className="border-0 mt-3 boxshadow" key={group.id}>
                        <CardBody className="p-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <div style={{ width: '2%' }}>{index + 1}</div>
                                <div style={{ width: '25%' }}>{group.rolename}</div>
                                <div style={{ width: '10%' }}>{group.usercount}</div>
                                <div style={{ width: '10%' }} className='text-capitalize'>
                                    <Badge className='yellow-background fs-color-black'>
                                        {group.is_for_customer_default}
                                    </Badge>
                                </div>
                                <div className="text-wrap" style={{ width: '20%' }}>
                                    <div className=''>
                                        <small className="text-primary">{moment(group.doc).format('LL HH:mm:ssa')}</small> 
                                        <div>
                                            <small className="text-info text-muted ms-1">({moment(group.doc).startOf().fromNow()})</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-wrap" style={{ width: '20%' }}>
                                    <small className="text-primary">{group.createdby}</small>
                                </div>
                                <div className="text-wrap" style={{ width: '5%' }}>
                                    <Badge color={group.status?'success':'danger'}>
                                        {group.status? 'Active':'In-Active'}</Badge>
                                </div>
                                <div className="text-wrap" style={{ width: '10%' }}>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                                color="primary"
                                                outline
                                                className="rounded-0"
                                                title="Edit event"
                                                size="sm"
                                                onClick={(e) => { 
                                                    togOpenModal()
                                                    handleGroupSelect(group) 
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                ))}


            </Container>
        </React.Fragment>
    )
}

export default UserGroups;