import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import Header from "../../../Layouts/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faEye, faMapPin, faPlus } from "@fortawesome/free-solid-svg-icons";
import EventCreate from "./EventCreate";
import { GlobalStore } from "../../../StateStores";
import { approvalEventRequests, getEventsAct } from "../../../Actions/Masters/EventCategoriesActs";
import { toast } from "react-toastify";
import moment from "moment";

const EventApprovals = ({})=>{
    const [currentView, setCurrentView] = useState('event');
    const [modal, setModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedEvent, setSelectedEvent] = useState({});

    const {
        MasterDispatch,
        MasterState:{
            getEvents:{data,error,loading},
            eventApprovalState:{data:approvalData,error:approvalError,loading:approvalLoading}
        }} = useContext(GlobalStore);

    useEffect(()=>{
        const fetchEventList = async ()=>{
            try {
                let response = await getEventsAct({approval:0})(MasterDispatch)
            } catch (error) {
                toast.error(error.message)
            }
        }
        fetchEventList()
    },[])

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const setlectEventAct = (event,type)=>{
        setSelectedEvent(event)
        togOpenModal(type)
    }


    const onCloseModal = ()=>{
        setSelectedEvent({})
        setCurrentView('event');
    }

    const submitApprovalRequest = async ()=>{
        if(selectedEvent?.id){
            try {
                const response = await approvalEventRequests({event_id:selectedEvent?.id})(MasterDispatch);
                if(response.status){
                    togOpenModal()
                    toast.success(response.message);
                }else{
                    toast.error(response.message)
                }
            } catch (error) {
                toast.error(error.message)
            }
        }
    }

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>
            <Modal
            onClosed={(e)=>onCloseModal()}
            className='custom-modal rounded-0 border-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        <FontAwesomeIcon icon={faEye}/><span className="ms-1">Event viewer</span></h5>
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <Row className='g-3 p-3'>
                        <Col>
                            <EventCreate
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                ctrlModal={togOpenModal}
                                selectedEvent={selectedEvent}
                                forApproval={true}
                                />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        // disabled={!formValid} 
                        onClick={submitApprovalRequest}
                        color="dark" type="submit" className="custom-button rounded-0">
                            {approvalLoading && <Spinner size="sm"/>}
                            {!approvalLoading && <FontAwesomeIcon icon={faCheck}/>} Approval
                        </Button>
                </ModalFooter>
            </Modal>

            <Container fluid>
                <BreadCrumb 
                    title="Un-Approved list"
                    subTittle="List of events which is not approved yet"
                    pageTitle="Event"/>

                    <Card className="border-0 mt-3">
                        <CardBody className="bg-light-f1 rounded-1 p-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <div style={{ width: '2%' }}>#</div>
                                <div style={{ width: '20%' }}>Event name</div>
                                <div style={{ width: '15%' }}>Contact Personal</div>
                                <div style={{ width: '10%' }}>Category</div>
                                {/* <div style={{ width: '20%' }}>Event dates</div> */}
                                <div style={{ width: '18%' }}>Location</div>
                                <div style={{ width: '15%' }}>Added by</div>
                                <div style={{ width: '15%' }}>Approval</div>
                                <div style={{ width: '5%' }}>
                                    <div className="d-flex justify-content-end">
                                        <Button 
                                            size="sm" 
                                            color="success"
                                            outline
                                            className="rounded-0"
                                            onClick={(e) => togOpenModal()}>
                                        <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                {loading && (
                    <div className='d-flex justify-content-center p-3'>
                        <Spinner color='dark' size="sm"/>
                    </div>
                )}

                {!loading && (data || []).map((event, index) => (
                    <Card className="border-0 mt-3 boxshadow" key={event.id}>
                        <CardBody className="p-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <div style={{ width: '2%' }}>{index + 1}</div>
                                <div style={{ width: '20%' }}>
                                    <strong>{event.event_name}</strong>
                                    <div>
                                        <Badge className='bg-light text-muted'>
                                        <small className="text-primary">{moment(event.from_date).format('LL HH:mm:ssa')}</small>
                                        <small className="text-info text-muted ms-1">({moment(event.from_date).startOf().fromNow()})</small>
                                        </Badge>
                                    </div>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <div className="text-capitalize">{event.contact_name}</div>
                                    <small className="fs-12 text-muted">{event.contact_mobile}</small>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <Badge className='yellow-background fs-color-black'>{event.cat_name}</Badge>
                                </div>
                                {/* <div className="text-wrap" style={{ width: '20%' }}>
                                    <div>
                                        <small className="text-primary">{moment(event.from_date).format('LL HH:mm:ssa')}</small>
                                        <div><small className="text-info text-muted ms-1">({moment(event.from_date).startOf().fromNow()})</small></div>
                                    </div>
                                </div> */}
                                <div className="text-wrap" style={{ width: '18%' }}>
                                    <div className="d-flex text-wrap">
                                        <small className="text-muted"><FontAwesomeIcon icon={faMapPin} /> {event.location}</small>
                                    </div>
                                </div>
                                <div className="text-wrap" style={{ width: '15%' }}>
                                    <small className="text-primary">{event.createdby}</small>
                                    <div className="d-flexs">
                                        <Badge className='bg-light text-muted'>
                                            <small className="text-muted">{moment(event.doc).format('LL HH:mm:ssa')}</small>
                                            <small className="text-info text-muted ms-1">({moment(event.doc).startOf().fromNow()})</small>
                                        </Badge>
                                    </div>
                                </div>
                                <div style={{ width: '15%' }}>
                                    <Badge color="danger">Need Approval</Badge>
                                </div>
                                <div className="text-wrap" style={{ width: '5%' }}>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            color="primary"
                                            outline
                                            className="rounded-0"
                                            title="Approval event"
                                            size="sm"
                                            onClick={(e) => { setlectEventAct(event, 'event') }}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                ))}

            </Container>
        </React.Fragment>
    )
}

export default EventApprovals;