import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../Layouts/Header';
import { Badge, Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { getUsersList } from '../../../Actions/Users/UsersActs';
import { toast } from 'react-toastify';
import BreadCrumb from '../../Common/BreadCrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import CreateUser from './CreateUser';

const UsersList = ()=>{
    const[users,setUsers] = useState([]);
    const[modal, setModal] = useState(false);
    
    const {
        UsersDispatch,
        UsersState:{
            getUsersStates:{data,error,loading}
        }} = useContext(GlobalStore);

    useEffect(()=>{
        const loadUsers = async ()=>{
            
            try {
                let response = await getUsersList({})(UsersDispatch);
                setUsers(response.data)
            } catch (error) {
                toast.error(error.message)
            }
        }
        loadUsers()
    },[])

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>

            <Modal
            // onClosed={(e)=>onCloseModal()}
            className='custom-modal rounded-0 border-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        {/* faEdit */}
                        <FontAwesomeIcon icon={faPlus}/>
                        {/* {form.id? 'Edit':'Create'} */}
                        <span className='ms-1'>Create User</span></h5>
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <CreateUser toggleModal={togOpenModal}/>
                </ModalBody>
                {/* <ModalFooter></ModalFooter> */}
            </Modal>
            <Container fluid>
                <BreadCrumb 
                    title="Users List"
                    subTittle="List of created users"
                    pageTitle="Users"/>
                
                <Card className="border-0 mt-3">
                    <CardBody className="bg-light-f1 rounded-1 p-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{ width: '2%' }}>#</div>
                            <div style={{ width: '5%' }}>ID</div>
                            <div style={{ width: '20%' }}>Full name</div>
                            <div style={{ width: '5%' }}>Gender</div>
                            <div style={{ width: '10%' }}>Mobile</div>
                            <div style={{ width: '13%' }}>Email</div>
                            <div style={{ width: '20%' }}>Referre From</div>
                            <div style={{ width: '15%' }}>Created At</div>
                            <div style={{ width: '10%' }}>
                                <div className="d-flex justify-content-end">
                                    <Button 
                                    size="sm" 
                                    color="success"
                                    outline
                                    className="rounded-0"
                                    onClick={(e) => togOpenModal()}
                                    >
                                    <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                {loading && (
                    <div className='d-flex justify-content-center p-3'>
                        <Spinner color='dark' size="sm"/>
                    </div>
                )}

                {!loading && (data || []).map((user, index) => (
                    <Card className="border-0 mt-3 boxshadow" key={user.id}>
                        <CardBody className="p-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <div style={{ width: '2%' }}>{index + 1}</div>
                                <div style={{ width: '5%' }}>{user.id}</div>
                                <div style={{ width: '20%' }}>
                                    <div><strong>{user.fullname}</strong></div>
                                    <small className='text-muted'>{user.username}</small>
                                </div>
                                <div style={{ width: '5%' }}>
                                    <Badge className='bg-light text-muted text-capitalize'>{user.gender}</Badge>
                                </div>
                                <div style={{ width: '10%' }}>{user.mobile}</div>
                                <div style={{ width: '13%' }}>{user.email}</div>
                                <div style={{ width: '20%' }} className='text-wrap'>{user.event_name}</div>
                                <div className="text-wrap" style={{ width: '15%' }}>
                                    <small className="text-primary">{user.createdby}</small>
                                    <div className="d-flexs">
                                        <Badge className='bg-light text-muted'>
                                        <small className="text-muted">{moment(user.doc).format('LL HH:mm:ssa')}</small>
                                        <small className="text-info text-muted ms-1">({moment(user.doc).startOf().fromNow()})</small>
                                        </Badge>
                                    </div>
                                </div>
                                <div className="text-wrap" style={{ width: '10%' }}>
                                    <div className="d-flex justify-content-end"></div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                ))}

            </Container>
        </React.Fragment>
    )
}

export default UsersList;