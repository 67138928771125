import React, { useContext, useEffect, useState } from "react";
import BannerUpload from "./BannerUpload";
import { Button, Spinner, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { GlobalStore } from "../../../StateStores";
import { getBannersAct } from "../../../Actions/Masters/BannerManagerActs";
import moment from "moment";
import { toast } from "react-toastify";
import { SERVER_BASE_URL } from "../../../Helpers/AxiosHelper";

const BannerManager = ()=>{
    const [uploadedBanners, setUploadedBanners] = useState([]);

    const {
        MasterDispatch,
        MasterState:{
            getBannersState:{data,error,loading},
        }} = useContext(GlobalStore);


    const loadUploadedBanners = async ()=>{
        try {
            const response = await getBannersAct({})(MasterDispatch)
            if(response.status){
                setUploadedBanners(response.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(()=>{
        loadUploadedBanners();
    },[])

    const reRefreshList = ()=>{
        loadUploadedBanners()
    }

    return(
        <React.Fragment>
            <BannerUpload/>
            <h6>Uploaded banners</h6>
            {loading && <div className='d-flex justify-content-center'><Spinner size="sm"/></div>}
            <Table className="mt-3 table nowrap align-middle table-sm table-hover caption-top custom-table">
                <caption>
                    <div className="d-flex justify-content-end">
                        <Button 
                            size='sm' 
                            outline
                            onClick={reRefreshList}>
                            <FontAwesomeIcon icon={faRefresh}/>
                        </Button>
                    </div>
                </caption>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>file</th>
                        <th>location</th>
                        <th>Media</th>
                        <th>Added by</th>
                    </tr>
                </thead>
                <tbody>
                    {(uploadedBanners || []).map((banner,index)=>(
                        <tr key={index}>
                            <td>{++index}</td>
                            <td>
                                {banner.filename && (
                                    <img src={`${SERVER_BASE_URL}/cdn-template-banners/${encodeURIComponent(banner.filename)}`} alt="Banner Preview" style={{ width: '350px', height:'150'}} />
                                )}
                            </td>
                            <td>{banner.location}</td>
                            <td>{banner.media}</td>
                            <td className="text-wrap">
                                <small className='text-primary'>{banner.createdby}</small>
                                <div className='d-flexa'>
                                    <small className="text-muted">{moment(banner.created_at).format('LL HH:mm:ssa')}</small>
                                    {/* <small className="text-info text-muted ms-1">({moment(category.created_at).startOf().fromNow()})</small> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default BannerManager;