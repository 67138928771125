import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Card, CardBody } from 'reactstrap';

const RSVPUsers = ({rsvpData})=>{
    return(
        <React.Fragment>
            <Card className='border-0 mt-3'>
                <CardBody className='bg-light-f1 rounded-1'>
                    <div className='d-flex justify-content-between'>
                        <div style={{width:'10%'}}>#</div>
                        <div style={{width:'35%'}}>User</div>
                        <div style={{width:'25%'}} className='text-center'>Ticket Type</div>
                        <div style={{width:'30%'}} className='text-center'>Status</div>
                    </div>
                </CardBody>
            </Card>

            {(rsvpData || []).map((ticket, index) => (
                <Card className='border-0 mt-3 boxshadow' key={ticket.user_ticket_id}>
                    <CardBody>
                        <div className='d-flex justify-content-between'>
                            <div style={{width:'10%'}}>{index + 1}</div>
                            <div className="text-wrap" style={{width:'35%'}}>
                                <small className='text-primary'>{ticket.sold_by}</small>
                                <div className='d-flex'>
                                    <small className="text-muted">{moment(ticket.purchased_at).format('LL HH:mm:ssa')}</small>
                                    {/* <small className="text-info text-muted ms-1">({moment(ticket.purchased_at).startOf().fromNow()})</small> */}
                                </div>
                            </div>
                            <div className='text-center' style={{width:'25%'}}>
                                <div><strong>{ticket.type_name}</strong></div>
                            </div>
                            <div style={{width:'30%'}} className='text-center'>{ticket.final_status}</div>
                        </div>
                    </CardBody>
                </Card>
            ))}

            {rsvpData.length === 0 && (
                <Card className="task-card border-0 mt-2">
                    <CardBody>
                        <small className='text-danger'>
                            <FontAwesomeIcon icon={faBan}/> No ticket response
                        </small>
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    )
}

export default RSVPUsers;