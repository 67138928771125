import { 
    CLEAR_EVENT_TICKET_CARD_INFO_STATE,
    CLEAR_EVENT_TICKET_CARD_VERIFICATION_STATE,
    CLEAR_GET_BANNERS_STATE,
    CLEAR_GET_EVENT_CATEGORIES_STATE, 
    CLEAR_GET_EVENT_STATE, 
    CLEAR_GET_EVENT_TABLES_STATE, 
    CLEAR_POST_BANNERS_STATE, 
    CLEAR_POST_EVENT_STATE, 
    CLEAR_POST_EVENT_TABLE_STATE, 
    CLEAR_POST_RSVP_STATE, 
    GET_BANNERS_FAIL, 
    GET_BANNERS_LOADING, 
    GET_BANNERS_SUCCESS, 
    GET_EVENT_APPROVAL_REQUEST_FAIL, 
    GET_EVENT_APPROVAL_REQUEST_LOADING, 
    GET_EVENT_APPROVAL_REQUEST_SUCCESS, 
    GET_EVENT_CATEGORIES_FAIL, 
    GET_EVENT_CATEGORIES_LOADING, 
    GET_EVENT_CATEGORIES_SUCCESS, 
    GET_EVENT_DETAILS_FAIL, 
    GET_EVENT_DETAILS_LOADING, 
    GET_EVENT_DETAILS_SUCCESS, 
    GET_EVENT_FAIL, 
    GET_EVENT_LOADING, 
    GET_EVENT_SUCCESS, 
    GET_EVENT_TABLES_FAIL, 
    GET_EVENT_TABLES_LOADING, 
    GET_EVENT_TABLES_SUCCESS, 
    GET_EVENT_TICKET_CARD_INFO_FAIL, 
    GET_EVENT_TICKET_CARD_INFO_LOADING, 
    GET_EVENT_TICKET_CARD_INFO_SUCCESS, 
    GET_EVENT_TICKET_TYPES_FAIL, 
    GET_EVENT_TICKET_TYPES_LOADING, 
    GET_EVENT_TICKET_TYPES_SUCCESS, 
    GET_GENERATE_INVITATION_CARD_FAIL, 
    GET_GENERATE_INVITATION_CARD_LOADING, 
    GET_GENERATE_INVITATION_CARD_SUCCESS, 
    GET_INVITATION_TEMPLATES_FAIL, 
    GET_INVITATION_TEMPLATES_LOADING, 
    GET_INVITATION_TEMPLATES_SUCCESS, 
    GET_SHARE_EVENT_CARD_FAIL, 
    GET_SHARE_EVENT_CARD_LOADING, 
    GET_SHARE_EVENT_CARD_SUCCESS, 
    GET_TICKET_TYPES_FAIL, 
    GET_TICKET_TYPES_LOADING, 
    GET_TICKET_TYPES_SUCCESS, 
    POST_BANNERS_FAIL, 
    POST_BANNERS_LOADING, 
    POST_BANNERS_SUCCESS, 
    POST_EVENT_FAIL, 
    POST_EVENT_LOADING,
    POST_EVENT_SUCCESS,
    POST_EVENT_TABLE_FAIL,
    POST_EVENT_TABLE_LOADING,
    POST_EVENT_TABLE_SUCCESS,
    POST_EVENT_TICKET_CARD_VERIFICATION_FAIL,
    POST_EVENT_TICKET_CARD_VERIFICATION_LOADING,
    POST_EVENT_TICKET_CARD_VERIFICATION_SUCCESS,
    POST_EVENT_TICKET_TYPES_FAIL,
    POST_EVENT_TICKET_TYPES_LOADING,
    POST_EVENT_TICKET_TYPES_SUCCESS,
    POST_ORDER_EVENT_TICKET_TYPES_FAIL,
    POST_ORDER_EVENT_TICKET_TYPES_LOADING,
    POST_ORDER_EVENT_TICKET_TYPES_SUCCESS,
    POST_RSVP_FAIL,
    POST_RSVP_LOADING,
    POST_RSVP_SUCCESS,
    POST_SET_EVENT_TEMPLATE_FAIL,
    POST_SET_EVENT_TEMPLATE_LOADING,
    POST_SET_EVENT_TEMPLATE_SUCCESS,
    POST_TEMPLATE_EDITED_COVER_FAIL,
    POST_TEMPLATE_EDITED_COVER_LOADING,
    POST_TEMPLATE_EDITED_COVER_SUCCESS} from "../Constants/ActionTypes";


const Masters = (state,{type,payload}) => {

    switch (type) {
        case GET_EVENT_CATEGORIES_LOADING:
          return{
              ...state,
              getEventCategories:{
                ...state.getEventCategories,
                loading:true,
            }
          };
        case GET_EVENT_CATEGORIES_SUCCESS:
            return{
                ...state,
                getEventCategories:{
                    ...state.getEventCategories,
                    loading:false,
                    data:payload,
                }
            };
        case GET_EVENT_CATEGORIES_FAIL:
            return{
                ...state,
                getEventCategories:{
                    ...state.getEventCategories,
                    loading:false,
                    error:payload
                }
            };

        case CLEAR_GET_EVENT_CATEGORIES_STATE:
            return {
                ...state,
                getEventCategories:{
                    ...state.getEventCategories,
                    loading:false,
                    data:null,
                    error:null
                }
            };

        case POST_EVENT_LOADING:
            return{
                ...state,
                createEvent:{
                    ...state.createEvent,
                    loading:true,
                }
            };
        case POST_EVENT_SUCCESS:
            return{
                ...state,
                createEvent:{
                    ...state.createEvent,
                    loading:false,
                    data:payload,
                }
            };
        case POST_EVENT_FAIL:
            return{
                ...state,
                createEvent:{
                    ...state.createEvent,
                    loading:false,
                    error:payload
                }
            };
    
        case CLEAR_POST_EVENT_STATE:
            return {
                ...state,
                createEvent:{
                    ...state.createEvent,
                    loading:false,
                    data:null,
                    error:null
                }
            };

        case GET_EVENT_LOADING:
            return{
                ...state,
                getEvents:{
                    ...state.getEvents,
                    loading:true,
                }
            };
        case GET_EVENT_SUCCESS:
            return{
                ...state,
                getEvents:{
                    ...state.getEvents,
                    loading:false,
                    data:payload,
                }
            };
        case GET_EVENT_FAIL:
            return{
                ...state,
                getEvents:{
                    ...state.getEvents,
                    loading:false,
                    error:payload
                }
            };

        case CLEAR_GET_EVENT_STATE:
            return {
                ...state,
                getEvents:{
                    ...state.getEvents,
                    loading:false,
                    data:null,
                    error:null
                }
            };

        case GET_TICKET_TYPES_LOADING:
            return{
                ...state,
                getTicketTypes:{
                    ...state.getTicketTypes,
                    loading:true,
                }
            };
        case GET_TICKET_TYPES_SUCCESS:
            return{
                ...state,
                getTicketTypes:{
                    ...state.getTicketTypes,
                    loading:false,
                    data:payload,
                }
            };
        case GET_TICKET_TYPES_FAIL:
            return{
                ...state,
                getTicketTypes:{
                    ...state.getTicketTypes,
                    loading:false,
                    error:payload
                }
            };

        case POST_EVENT_TICKET_TYPES_LOADING:
            return{
                ...state,
                createEventTicketTypes:{
                    ...state.createEventTicketTypes,
                    loading:true,
                }
            };
        case POST_EVENT_TICKET_TYPES_SUCCESS:
            return{
                ...state,
                createEventTicketTypes:{
                    ...state.createEventTicketTypes,
                    loading:false,
                    data:payload,
                }
            };
        case POST_EVENT_TICKET_TYPES_FAIL:
            return{
                ...state,
                createEventTicketTypes:{
                    ...state.createEventTicketTypes,
                    loading:false,
                    error:payload
                }
            };

        case GET_EVENT_TICKET_TYPES_LOADING:
            return{
                ...state,
                getEventTicketTypes:{
                    ...state.getEventTicketTypes,
                    loading:true,
                }
            };
        case GET_EVENT_TICKET_TYPES_SUCCESS:
            return{
                ...state,
                getEventTicketTypes:{
                    ...state.getEventTicketTypes,
                    loading:false,
                    data:payload,
                }
            };
        case GET_EVENT_TICKET_TYPES_FAIL:
            return{
                ...state,
                getEventTicketTypes:{
                    ...state.getEventTicketTypes,
                    loading:false,
                    error:payload
                }
            };
        case POST_ORDER_EVENT_TICKET_TYPES_LOADING:
            return{
                ...state,
                orderEventTicketTypes:{
                    ...state.orderEventTicketTypes,
                    loading:true,
                }
            };
        case POST_ORDER_EVENT_TICKET_TYPES_SUCCESS:
            return{
                ...state,
                orderEventTicketTypes:{
                    ...state.orderEventTicketTypes,
                    loading:false,
                    data:payload,
                }
            };
        case POST_ORDER_EVENT_TICKET_TYPES_FAIL:
            return{
                ...state,
                orderEventTicketTypes:{
                    ...state.orderEventTicketTypes,
                    loading:false,
                    error:payload
                }
            };
        case GET_EVENT_DETAILS_LOADING:
            return{
                ...state,
                getEventDetails:{
                    ...state.getEventDetails,
                    loading:true,
                }
            };
        case GET_EVENT_DETAILS_SUCCESS:
            return{
                ...state,
                getEventDetails:{
                    ...state.getEventDetails,
                    loading:false,
                    data:payload,
                }
            };
        case GET_EVENT_DETAILS_FAIL:
            return{
                ...state,
                getEventDetails:{
                    ...state.getEventDetails,
                    loading:false,
                    error:payload
                }
            };
        case GET_GENERATE_INVITATION_CARD_LOADING:
            return{
                ...state,
                generateInvitationCard:{
                    ...state.generateInvitationCard,
                    loading:true,
                }
            };
        case GET_GENERATE_INVITATION_CARD_SUCCESS:
            return{
                ...state,
                generateInvitationCard:{
                    ...state.generateInvitationCard,
                    loading:false,
                    data:payload,
                }
            };
        case GET_GENERATE_INVITATION_CARD_FAIL:
            return{
                ...state,
                generateInvitationCard:{
                    ...state.generateInvitationCard,
                    loading:false,
                    error:payload
                }
            };
        case GET_INVITATION_TEMPLATES_LOADING:
            return{
                ...state,
                getInvitationTemplates:{
                    ...state.getInvitationTemplates,
                    loading:true,
                }
            };
        case GET_INVITATION_TEMPLATES_SUCCESS:
            return{
                ...state,
                getInvitationTemplates:{
                    ...state.getInvitationTemplates,
                    loading:false,
                    data:payload,
                }
            };
        case GET_INVITATION_TEMPLATES_FAIL:
            return{
                ...state,
                getInvitationTemplates:{
                    ...state.getInvitationTemplates,
                    loading:false,
                    error:payload
                }
            };


        case POST_SET_EVENT_TEMPLATE_LOADING:
            return{
                ...state,
                setEventTemplate:{
                    ...state.setEventTemplate,
                    loading:true,
                }
            };
        case POST_SET_EVENT_TEMPLATE_SUCCESS:
            return{
                ...state,
                getEvents: {
                    ...state.getEvents,
                    loading: false,
                    data: state.getEvents.data.map(event => 
                        event.id === payload?.data?.event_id ? { 
                            ...event, 
                            template_id: payload?.data?.template_id } : event
                    ),
                },
                setEventTemplate:{
                    ...state.setEventTemplate,
                    loading:false,
                    data:payload,
                }
            };
        case POST_SET_EVENT_TEMPLATE_FAIL:
            return{
                ...state,
                setEventTemplate:{
                    ...state.setEventTemplate,
                    loading:false,
                    error:payload
                }
            };

        case GET_SHARE_EVENT_CARD_LOADING:
            return{
                ...state,
                shareEventCard:{
                    ...state.shareEventCard,
                    loading:true,
                }
            };
        case GET_SHARE_EVENT_CARD_SUCCESS:
            return{
                ...state,
                shareEventCard:{
                    ...state.shareEventCard,
                    loading:false,
                    data:payload,
                }
            };
        case GET_SHARE_EVENT_CARD_FAIL:
            return{
                ...state,
                shareEventCard:{
                    ...state.shareEventCard,
                    loading:false,
                    error:payload
                }
            };
        case POST_TEMPLATE_EDITED_COVER_LOADING:
            return{
                ...state,
                uploadInvitationEditedTemplates:{
                    ...state.uploadInvitationEditedTemplates,
                    loading:true,
                }
            };
        case POST_TEMPLATE_EDITED_COVER_SUCCESS:
            return{
                ...state,
                uploadInvitationEditedTemplates:{
                    ...state.uploadInvitationEditedTemplates,
                    loading:false,
                    data:payload,
                }
            };
        case POST_TEMPLATE_EDITED_COVER_FAIL:
            return{
                ...state,
                uploadInvitationEditedTemplates:{
                    ...state.uploadInvitationEditedTemplates,
                    loading:false,
                    error:payload
                }
            };
        case GET_EVENT_APPROVAL_REQUEST_LOADING:
            return{
                ...state,
                eventApprovalState:{
                    ...state.eventApprovalState,
                    loading:true,
                }
            };
        case GET_EVENT_APPROVAL_REQUEST_SUCCESS:

            const approvedEventId = payload.event_id;

            // Filter out the approved event from the getEvents data
            const updatedEvents = state.getEvents.data.filter(event => event.id !== approvedEventId);

            return{
                ...state,
                eventApprovalState:{
                    ...state.eventApprovalState,
                    loading:false,
                    data:payload,
                },
                getEvents: {
                    ...state.getEvents,
                    loading: false,
                    data: updatedEvents,
                }
            };
        case GET_EVENT_APPROVAL_REQUEST_FAIL:
            return{
                ...state,
                eventApprovalState:{
                    ...state.eventApprovalState,
                    loading:false,
                    error:payload
                }
            };
        case POST_RSVP_LOADING:
            return{
                ...state,
                postRsvpState:{
                    ...state.postRsvpState,
                    loading:true,
                }
            };
        case POST_RSVP_SUCCESS:
            return{
                ...state,
                postRsvpState:{
                    ...state.postRsvpState,
                    loading:false,
                    data:payload,
                }
            };
        case POST_RSVP_FAIL:
            return{
                ...state,
                postRsvpState:{
                    ...state.postRsvpState,
                    loading:false,
                    error:payload
                }
            };

        case CLEAR_POST_RSVP_STATE:
            return {
                ...state,
                postRsvpState:{
                    ...state.postRsvpState,
                    loading:false,
                    data:null,
                    error:null
                }
            };
        case GET_EVENT_TICKET_CARD_INFO_LOADING:
            return{
                ...state,
                eventTicketCardInfo:{
                    ...state.eventTicketCardInfo,
                    loading:true,
                    error:null,
                }
            }
        case GET_EVENT_TICKET_CARD_INFO_SUCCESS:
            return {
                ...state,
                eventTicketCardInfo:{
                    ...state.eventTicketCardInfo,
                    loading:false,
                    data:payload,
                    error:null,
                }
            }
        case GET_EVENT_TICKET_CARD_INFO_FAIL:
            return {
                ...state,
                eventTicketCardInfo:{
                    ...state.eventTicketCardInfo,
                    loading:false,
                    data:payload,
                    error:payload,
                }
            }
        case CLEAR_EVENT_TICKET_CARD_INFO_STATE:
            return{
                ...state,
                eventTicketCardInfo:{
                    data:[],
                    error:null,
                    loading:false,
                },
            };
        case POST_EVENT_TICKET_CARD_VERIFICATION_LOADING:
            return{
                ...state,
                eventTicketCardVerification:{
                    ...state.eventTicketCardVerification,
                    loading:true,
                    error:null,
                }
            }
        case POST_EVENT_TICKET_CARD_VERIFICATION_SUCCESS:
            return {
                ...state,
                eventTicketCardVerification:{
                    ...state.eventTicketCardVerification,
                    loading:false,
                    data:payload,
                    error:null,
                }
            }
        case POST_EVENT_TICKET_CARD_VERIFICATION_FAIL:
            return {
                ...state,
                eventTicketCardVerification:{
                    ...state.eventTicketCardVerification,
                    loading:false,
                    data:payload,
                    error:payload,
                }
            }
        case CLEAR_EVENT_TICKET_CARD_VERIFICATION_STATE:
            return{
                ...state,
                eventTicketCardVerification:{
                    data:[],
                    error:null,
                    loading:false,
                },
            };
        case POST_EVENT_TABLE_LOADING:
            return{
                ...state,
                generateEventTableState:{
                    ...state.generateEventTableState,
                    loading:true,
                    error:null,
                }
            }
        case POST_EVENT_TABLE_SUCCESS:
            return {
                ...state,
                generateEventTableState:{
                    ...state.generateEventTableState,
                    loading:false,
                    data:payload,
                    error:null,
                }
            }
        case POST_EVENT_TABLE_FAIL:
            return {
                ...state,
                generateEventTableState:{
                    ...state.generateEventTableState,
                    loading:false,
                    data:payload,
                    error:payload,
                }
            }
        case CLEAR_POST_EVENT_TABLE_STATE:
            return{
                ...state,
                generateEventTableState:{
                    data:[],
                    error:null,
                    loading:false,
                },
            };
        case GET_EVENT_TABLES_LOADING:
            return{
                ...state,
                eventTableState:{
                    ...state.eventTableState,
                    loading:true,
                    error:null,
                }
            }
        case GET_EVENT_TABLES_SUCCESS:
            return {
                ...state,
                eventTableState:{
                    ...state.eventTableState,
                    loading:false,
                    data:payload,
                    error:null,
                }
            }
        case GET_EVENT_TABLES_FAIL:
            return {
                ...state,
                eventTableState:{
                    ...state.eventTableState,
                    loading:false,
                    data:payload,
                    error:payload,
                }
            }
        case CLEAR_GET_EVENT_TABLES_STATE:
            return{
                ...state,
                eventTableState:{
                    data:[],
                    error:null,
                    loading:false,
                },
            };
        case GET_BANNERS_LOADING:
            return{
                ...state,
                getBannersState:{
                    ...state.getBannersState,
                    loading:true,
                    error:null,
                }
            }
        case GET_BANNERS_SUCCESS:
            return {
                ...state,
                getBannersState:{
                    ...state.getBannersState,
                    loading:false,
                    data:payload,
                    error:null,
                }
            }
        case GET_BANNERS_FAIL:
            return {
                ...state,
                getBannersState:{
                    ...state.getBannersState,
                    loading:false,
                    data:payload,
                    error:payload,
                }
            }
        case CLEAR_GET_BANNERS_STATE:
            return{
                ...state,
                getBannersState:{
                    data:[],
                    error:null,
                    loading:false,
                },
            };

        case POST_BANNERS_LOADING:
            return{
                ...state,
                uploadBannerState:{
                    ...state.uploadBannerState,
                    loading:true,
                    error:null,
                }
            }
        case POST_BANNERS_SUCCESS:
            return {
                ...state,
                uploadBannerState:{
                    ...state.uploadBannerState,
                    loading:false,
                    data:payload,
                    error:null,
                }
            }
        case POST_BANNERS_FAIL:
            return {
                ...state,
                uploadBannerState:{
                    ...state.uploadBannerState,
                    loading:false,
                    data:payload,
                    error:payload,
                }
            }
        case CLEAR_POST_BANNERS_STATE:
            return{
                ...state,
                uploadBannerState:{
                    data:[],
                    error:null,
                    loading:false,
                },
            };

        default:
           return false;
    }

}

export default Masters;