import { Outlet, createBrowserRouter } from "react-router-dom"
import Login from "../Components/Pages/Login"
import Dashboard from "../Components/Pages/Dashboard";
import UsersList from "../Components/Pages/Users/UsersList";
import Categories from "../Components/Pages/Category/Categories";
import EventList from "../Components/Pages/Events/EventList";
import { Protected, RouteAuth } from "../Helpers/permissionHandler";
import EventViewer from "../Components/Pages/EventViewer";
import Calendar from "../Components/Pages/Calendar";
import EventApprovals from "../Components/Pages/Events/EventApprovals";
import UserGroups from "../Components/Pages/Usergroup/UserGroups";
import OnlineRsvp from "../Components/Pages/OnlineRsvp";
import EventOnlineMenuOrder from "../Components/Pages/FoodMenuManager/EventOnlineMenuOrder";
import UserProfile from "../Components/Pages/Users/UserProfile";
import DeactivateAccountDescription from "../Components/Pages/Users/DeactivateAccountDescription";

const RoutesMaster = (isAuthenticated,userData)=>{
    return createBrowserRouter([
        {
            path:'/',
            element:isAuthenticated? <RouteAuth LoggedUserProfile={userData}/>:<Login/>,
            children:[
                {
                    path:'/login',
                    element:<Login/>
                },{
                    path:'/logout',
                    element:<Login/>,
                },{
                    path:'/',
                    element:<Login/>,
                }
            ]
        },{
            path:'/dashboard',
            element:<Protected
            isSignedIn={isAuthenticated}>
                    <Dashboard/>
                </Protected>
        },{
            path:'/dashboard/users',
            element:<Outlet/>,
            children:[
                {
                    path:'/dashboard/users',
                    element:<Protected 
                    isSignedIn={isAuthenticated}>
                        <UsersList/>
                    </Protected>
                },{
                    path:'/dashboard/users/groups',
                    element:<Protected 
                    isSignedIn={isAuthenticated}>
                        <UserGroups/>
                    </Protected>
                },{
                    path:'/dashboard/users/profile',
                    element:<Protected 
                    isSignedIn={isAuthenticated}>
                        <UserProfile/>
                    </Protected>
                },
            ]
        },{
            path:'/dashboard/categories',
            element:<Protected
            isSignedIn={isAuthenticated}>
                <Categories/>
            </Protected>
        },{
            path:'/dashboard/events',
            element:<Outlet/>,
            children:[
                {
                    path:'/dashboard/events',
                    element:<Protected 
                    isSignedIn={isAuthenticated}>
                        <EventList/>
                    </Protected>,
                },
                {
                    path:'/dashboard/events/viewer',
                    element:<Protected 
                    isSignedIn={isAuthenticated}>
                        <EventViewer/>
                    </Protected>
                },{
                    path:'/dashboard/events/calendar',
                    element:<Protected 
                    isSignedIn={isAuthenticated}>
                        <Calendar/>
                    </Protected>
                },{
                    path:'/dashboard/events/approvals',
                    element:<Protected 
                    isSignedIn={isAuthenticated}>
                        <EventApprovals/>
                    </Protected>
                }
            ]
        },{
            path:'/rsvp',
            children:[
                {
                    path:'/rsvp/online/:qrcode',
                    element:<OnlineRsvp/>
                }
            ]
        },{
            path:'event-menu/:tableNumber?',
            element:<EventOnlineMenuOrder/>
        },{
            path:'/deactivate-account',
            element:<DeactivateAccountDescription/>,
        }
    ])
}

export default RoutesMaster;