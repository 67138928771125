import { 
    CLEAR_GET_EVENT_MENU_ITEMS_STATE,
    CLEAR_GET_EVENT_SELECTED_MENU_ITEMS_STATE,
    CLEAR_GET_FOOD_MENU_CATEGORY_STATE, 
    CLEAR_GET_FOOD_MENU_ITEMS_STATE, 
    CLEAR_POST_EVENT_MENU_ITEM_STATE, 
    CLEAR_POST_FOOD_MENU_CATEGORY_STATE, 
    CLEAR_POST_FOOD_MENU_ITEM_STATE, 
    CLEAR_POST_PLACE_ORDER_STATE, 
    GET_EVENT_MENU_ITEMS_FAIL, 
    GET_EVENT_MENU_ITEMS_LOADING, 
    GET_EVENT_MENU_ITEMS_SUCCESS, 
    GET_EVENT_SELECTED_MENU_ITEMS_FAIL, 
    GET_EVENT_SELECTED_MENU_ITEMS_LOADING, 
    GET_EVENT_SELECTED_MENU_ITEMS_SUCCESS, 
    GET_FOOD_MENU_CATEGORY_FAIL, 
    GET_FOOD_MENU_CATEGORY_LOADING, 
    GET_FOOD_MENU_CATEGORY_SUCCESS, 
    GET_FOOD_MENU_ITEMS_FAIL, 
    GET_FOOD_MENU_ITEMS_LOADING, 
    GET_FOOD_MENU_ITEMS_SUCCESS, 
    POST_EVENT_MENU_ITEM_FAIL, 
    POST_EVENT_MENU_ITEM_LOADING, 
    POST_EVENT_MENU_ITEM_SUCCESS, 
    POST_FOOD_MENU_CATEGORY_FAIL, 
    POST_FOOD_MENU_CATEGORY_LOADING, 
    POST_FOOD_MENU_CATEGORY_SUCCESS, 
    POST_FOOD_MENU_ITEM_FAIL, 
    POST_FOOD_MENU_ITEM_LOADING,
    POST_FOOD_MENU_ITEM_SUCCESS,
    POST_PLACE_ORDER_FAIL,
    POST_PLACE_ORDER_LOADING,
    POST_PLACE_ORDER_SUCCESS} from "../Constants/ActionTypes";

const FoodMenus = (state,{type,payload}) => {

    switch (type) {
        case GET_FOOD_MENU_CATEGORY_LOADING:
          return{
              ...state,
              foodMenuCategory:{
                ...state.foodMenuCategory,
                loading:true,
            }
          };
        case GET_FOOD_MENU_CATEGORY_SUCCESS:
            return{
                ...state,
                foodMenuCategory:{
                    ...state.foodMenuCategory,
                    loading:false,
                    data:payload,
                }
            };
        case GET_FOOD_MENU_CATEGORY_FAIL:
            return{
                ...state,
                foodMenuCategory:{
                    ...state.foodMenuCategory,
                    loading:false,
                    error:payload
                }
            };

        case CLEAR_GET_FOOD_MENU_CATEGORY_STATE:
            return {
                ...state,
                foodMenuCategory:{
                    ...state.foodMenuCategory,
                    loading:false,
                    data:null,
                    error:null
                }
            };

        case POST_FOOD_MENU_CATEGORY_LOADING:
            return{
                ...state,
                postFoodMenuCategory:{
                    ...state.postFoodMenuCategory,
                    loading:true,
                }
            };
        case POST_FOOD_MENU_CATEGORY_SUCCESS:
            return{
                ...state,
                postFoodMenuCategory:{
                    ...state.postFoodMenuCategory,
                    loading:false,
                    data:payload,
                }
            };
        case POST_FOOD_MENU_CATEGORY_FAIL:
            return{
                ...state,
                postFoodMenuCategory:{
                    ...state.postFoodMenuCategory,
                    loading:false,
                    error:payload
                }
            };
    
        case CLEAR_POST_FOOD_MENU_CATEGORY_STATE:
            return {
                ...state,
                postFoodMenuCategory:{
                    ...state.postFoodMenuCategory,
                    loading:false,
                    data:null,
                    error:null
                }
            };
        case GET_FOOD_MENU_ITEMS_LOADING:
            return{
                ...state,
                foodMenuItems:{
                    ...state.foodMenuItems,
                    loading:true,
                }
            };
            case GET_FOOD_MENU_ITEMS_SUCCESS:
                return{
                    ...state,
                    foodMenuItems:{
                        ...state.foodMenuItems,
                        loading:false,
                        data:payload,
                    }
                };
            case GET_FOOD_MENU_ITEMS_FAIL:
                return{
                    ...state,
                    foodMenuItems:{
                        ...state.foodMenuItems,
                        loading:false,
                        error:payload
                    }
                };
    
            case CLEAR_GET_FOOD_MENU_ITEMS_STATE:
                return {
                    ...state,
                    foodMenuItems:{
                        ...state.foodMenuItems,
                        loading:false,
                        data:null,
                        error:null
                    }
                };
            case POST_FOOD_MENU_ITEM_LOADING:
                return{
                    ...state,
                    postFoodMenuItem:{
                        ...state.postFoodMenuItem,
                        loading:true,
                    }
                };
            case POST_FOOD_MENU_ITEM_SUCCESS:
                return{
                    ...state,
                    postFoodMenuItem:{
                        ...state.postFoodMenuItem,
                        loading:false,
                        data:payload,
                    }
                };
            case POST_FOOD_MENU_ITEM_FAIL:
                return{
                    ...state,
                    postFoodMenuItem:{
                        ...state.postFoodMenuItem,
                        loading:false,
                        error:payload
                    }
                };
        
            case CLEAR_POST_FOOD_MENU_ITEM_STATE:
                return {
                    ...state,
                    postFoodMenuCategory:{
                        ...state.postFoodMenuCategory,
                        loading:false,
                        data:null,
                        error:null
                    }
                };
            case GET_EVENT_MENU_ITEMS_LOADING:
                return{
                    ...state,
                    getEventMenuItem:{
                        ...state.getEventMenuItem,
                        loading:true,
                    }
                };
            case GET_EVENT_MENU_ITEMS_SUCCESS:
                return{
                    ...state,
                    getEventMenuItem:{
                        ...state.getEventMenuItem,
                        loading:false,
                        data:payload,
                    }
                };
            case GET_EVENT_MENU_ITEMS_FAIL:
                return{
                    ...state,
                    getEventMenuItem:{
                        ...state.getEventMenuItem,
                        loading:false,
                        error:payload
                    }
                };
            case CLEAR_GET_EVENT_MENU_ITEMS_STATE:
                return {
                    ...state,
                    getEventMenuItem:{
                        ...state.getEventMenuItem,
                        loading:false,
                        data:null,
                        error:null
                    }
                };
    
            case POST_EVENT_MENU_ITEM_LOADING:
                return{
                    ...state,
                    createEventMenuItem:{
                        ...state.createEventMenuItem,
                        loading:true,
                    }
                };
            case POST_EVENT_MENU_ITEM_SUCCESS:
                return{
                    ...state,
                    createEventMenuItem:{
                        ...state.createEventMenuItem,
                        loading:false,
                        data:payload,
                    }
                };
            case POST_EVENT_MENU_ITEM_FAIL:
                return{
                    ...state,
                    createEventMenuItem:{
                        ...state.createEventMenuItem,
                        loading:false,
                        error:payload
                    }
                };
        
            case CLEAR_POST_EVENT_MENU_ITEM_STATE:
                return {
                    ...state,
                    createEventMenuItem:{
                        ...state.createEventMenuItem,
                        loading:false,
                        data:null,
                        error:null
                    }
                };

            case GET_EVENT_SELECTED_MENU_ITEMS_LOADING:
                return{
                    ...state,
                    getEventSelectedMenuItems:{
                        ...state.getEventSelectedMenuItems,
                        loading:true,
                    }
                };
            case GET_EVENT_SELECTED_MENU_ITEMS_SUCCESS:
                return{
                    ...state,
                    getEventSelectedMenuItems:{
                        ...state.getEventSelectedMenuItems,
                        loading:false,
                        data:payload,
                    }
                };
            case GET_EVENT_SELECTED_MENU_ITEMS_FAIL:
                return{
                    ...state,
                    getEventSelectedMenuItems:{
                        ...state.getEventSelectedMenuItems,
                        loading:false,
                        error:payload
                    }
                };
            case CLEAR_GET_EVENT_SELECTED_MENU_ITEMS_STATE:
                return {
                    ...state,
                    getEventSelectedMenuItems:{
                        ...state.getEventSelectedMenuItems,
                        loading:false,
                        data:null,
                        error:null
                    }
                };
    
            case POST_PLACE_ORDER_LOADING:
                return{
                    ...state,
                    placeOrder:{
                        ...state.placeOrder,
                        loading:true,
                    }
                };
            case POST_PLACE_ORDER_SUCCESS:
                return{
                    ...state,
                    placeOrder:{
                        ...state.placeOrder,
                        loading:false,
                        data:payload,
                    }
                };
            case POST_PLACE_ORDER_FAIL:
                return{
                    ...state,
                    placeOrder:{
                        ...state.placeOrder,
                        loading:false,
                        error:payload
                    }
                };
        
            case CLEAR_POST_PLACE_ORDER_STATE:
                return {
                    ...state,
                    placeOrder:{
                        ...state.placeOrder,
                        loading:false,
                        data:null,
                        error:null
                    }
                };

        default:
           return false;
    }

}

export default FoodMenus;