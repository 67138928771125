import { 
    POST_BROADCAST_FAIL, 
    POST_BROADCAST_LOADING, 
    POST_BROADCAST_SUCCESS } from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";

export const BroadcastSender = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_BROADCAST_LOADING
        })
    
        AxiosHelper.post(`/broadcast/send`,{payload}).then(
            (res) => {
    
                dispatch({
                    type:POST_BROADCAST_SUCCESS,
                    payload:res.data.data,
                });
                
                resolve(res.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_BROADCAST_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}