import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { getEventDetailsAct } from '../../../Actions/Masters/EventCategoriesActs';
import { toast } from 'react-toastify';
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader, Spinner, Table } from 'reactstrap';
import moment from 'moment';
import { faBullhorn, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BroadcastOptions from '../BroadcastOptions';

const EventAccessManager = ({selectedEvent,actionType,offcanvasOpen,setOffcanvasOpen})=>{
    const [eventData, setEventData] = useState({});
    const [soldTickets, setSoldTickets] = useState([]);
    const {
        MasterDispatch,
        MasterState:{
            getEventDetails:{data,error,loading},
        }} = useContext(GlobalStore);

    const fetchEventDetails = ()=>{
        try {
            getEventDetailsAct(selectedEvent)(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    const {event,solid_events,ticket_types,event_rsvp} = res.data[0];
                    setSoldTickets(solid_events)
                    setEventData(event)
                }
            })
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(()=>{
        fetchEventDetails()
    },[selectedEvent])
    
    return(
        <React.Fragment>
            <Offcanvas isOpen={offcanvasOpen} direction="end"  className="custom-offcanvas border-bottom">
                <OffcanvasHeader toggle={()=>setOffcanvasOpen(!offcanvasOpen)}><FontAwesomeIcon icon={faBullhorn} /> Broadcast manager</OffcanvasHeader>
                <OffcanvasBody className='bg-light p-0'>
                    <BroadcastOptions event={eventData} actionType={actionType} recipients={soldTickets}/>
                </OffcanvasBody>
            </Offcanvas>
            <div className='d-flex justify-content-between mb-3'>
                <h6>{selectedEvent?.event_name}</h6>
                <div>
                    {loading && <Spinner size="sm"/>}
                    {!loading && <Button 
                        size='sm' 
                        outline
                        onClick={fetchEventDetails}>
                        <FontAwesomeIcon icon={faRefresh}/>
                    </Button>}
                </div>
            </div>
            <Table bordered size="sm">
                <thead className="bg-light">
                    <tr>
                        <th>SN</th>
                        <th>Attendee</th>
                        <th>Mobile</th>
                        <th>Card type</th>
                        <th className='text-center'>Card code</th>
                        <th className='text-center'>Quantity</th>
                        <th className='text-center'>Scan count</th>
                        <th>Issue date</th>
                    </tr>
                </thead>
                <tbody>
                    {(soldTickets || []).map((ticket,index)=>(
                        <tr key={index}>
                            <td>{++index}</td>
                            <td>{ticket?.sold_by}</td>
                            <td>{ticket?.mobile}</td>
                            <td>{ticket?.type_name}</td>
                            <td className='text-center'>{ticket?.card_code}</td>
                            <td className='text-center'>{ticket?.quantity}</td>
                            <td className='text-center'>{ticket?.scan_count}</td>
                            <td>
                                <small>{moment(ticket?.purchased_at).format('LL HH:mm:ssa')}</small>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default EventAccessManager;