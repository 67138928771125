import React, { useContext, useEffect } from 'react';
import Header from '../../../Layouts/Header';
import { Badge, Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { getEventCategories } from '../../../Actions/Masters/EventCategoriesActs';
import moment from 'moment';
import BreadCrumb from '../../Common/BreadCrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Categories = ()=>{
    const {
        MasterDispatch,
        MasterState:{
            getEventCategories:{data,error,loading}
        }} = useContext(GlobalStore);

    useEffect(()=>{
        function fetchCategoriesData(){
            getEventCategories()(MasterDispatch)((res)=>{})
        }
        fetchCategoriesData();
    },[])

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>
            <Container>
                <BreadCrumb 
                    title="Category List" 
                    subTittle="List of created events"
                    pageTitle="Category"/>
                <Card className='border-0 mt-3'>
                    <CardBody className='bg-light-f1 rounded-1 p-1'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{ width: '2%' }}>#</div>
                            <div style={{ width: '25%' }}>Category name</div>
                            <div style={{ width: '15%' }}>Invitation system</div>
                            <div style={{ width: '20%' }}>Added At</div>
                            <div style={{ width: '20%' }}>Added By</div>
                            <div style={{ width: '10%' }}>Status</div>
                            <div style={{ width: '10%' }}>
                                <div className='d-flex justify-content-end'>
                                    {/* <Button 
                                        size='sm' 
                                        color='success'
                                        outline
                                        className='rounded-0'
                                        >
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                {(data || []).map((category, index) => (
                    <Card className='border-0 mt-3 boxshadow' key={category.id}>
                        <CardBody className='p-2'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div style={{ width: '2%' }}>{index + 1}</div>
                                <div style={{ width: '25%' }}>
                                    <Badge className='yellow-background fs-color-black'>{category.name}</Badge>
                                </div>
                                <div style={{ width: '15%' }}>{category.system_name}</div>
                                <div style={{ width: '20%' }} className="text-wrap">
                                    <div className=''>
                                        <small className="text-primary">{moment(category.doc).format('LL HH:mm:ssa')}</small> 
                                        <small className="text-info text-muted ms-1">({moment(category.doc).startOf().fromNow()})</small>
                                    </div>
                                </div>
                                <div style={{ width: '20%' }} className="text-wrap">
                                    <small className='text-primary'>{category.createdby}</small>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <Badge
                                    color={category.status === 'active'?'success':'danger'}
                                    className={`rounded-0 text-capitalize badge ${category.status === 'active' ? 'badge-outline-success' : 'badge-outline-danger'}`}>{category.status}</Badge>
                                </div>
                                <div style={{ width: '10%' }}></div>
                            </div>
                        </CardBody>
                    </Card>
                ))}
            </Container>
        </React.Fragment>
    )
}

export default Categories;