import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, InputGroup, Label, Row, Spinner } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { faCarrot, faCoffee, faDrumstickBite, faMinus, faPizzaSlice, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { createEventMenuOrder, getEventSelectedMenuItems } from '../../../Actions/Masters/FoodMenuActs';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const categoryIcons = {
    1: faPizzaSlice,
    2: faDrumstickBite,
    3: faCarrot,
    4: faCoffee
  };

const EventOnlineMenuOrder = ({event})=>{
    const { tableNumber } = useParams();
    const [menuItems, setMenuItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState({});
    const hasSelectedItems = Object.values(selectedItems).some(item => item.quantity > 0);

    const {
        FoodMenuMasterDispatch,
        FoodMenuMasterState:{
            placeOrder:{data:saveData,error:saveError,loading:saveLoading},
            getEventSelectedMenuItems:{data:categories,error,loading},
        }} = useContext(GlobalStore);



    useEffect(()=>{
        const loadEventMenuItems = async ()=>{
            try {
                const response = await getEventSelectedMenuItems(JSON.parse(atob(tableNumber)))(FoodMenuMasterDispatch);
                setMenuItems(response)
            } catch (error) {
                toast.error(error.message)
            }
        }

        loadEventMenuItems()
    },[])


    const incrementCountBtn = (item) => {
        setSelectedItems((prevItems) => ({
            ...prevItems,
            [item.item_id]: {
            ...item,
            quantity: (prevItems[item.item_id]?.quantity || 0) + 1
            }
        }));
    };

    const decrementCountBtn = (itemId) => {
        setSelectedItems((prevItems) => {
          const newQuantity = (prevItems[itemId]?.quantity || 0) - 1;
          if (newQuantity <= 0) {
            const { [itemId]: removed, ...rest } = prevItems;
            return rest;
          }
          return {
            ...prevItems,
            [itemId]: {
              ...prevItems[itemId],
              quantity: newQuantity
            }
          };
        });
      };

    const handleOrderSubmit = async () => {
        const orderedItems = Object.values(selectedItems)
        .filter(item => item.quantity > 0)
        .map(({ item_id, quantity }) => ({ item_id, quantity }));

        try {

            const form = {
                event_id:14,
                orderedItems
            }

            const response = await createEventMenuOrder(form)(FoodMenuMasterDispatch);
            toast.success(response.message)
            setSelectedItems({})
        } catch (error) {
            toast.error(error.message)
        }
    };

    return(
        <React.Fragment>
            <Container>
                <Card className="mt-2">
                    <CardBody className="p-0">
                        <div style={{
                            height: '187px',
                            backgroundSize:"inherit",
                            backgroundPosition: "center",
                            position: 'relative',
                            backgroundImage:"url('/logo-black-bg.png')",
                            backgroundColor:"#07080b",
                            backgroundRepeat:"no-repeat",
                            borderRadius:5
                        }}>
                        </div>
                    </CardBody>
                </Card>
                <Card className="mt-2 shadow-sm border-0">
                    <CardHeader className='border-0'>Test</CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <p className="text-primary text-center mb-0">{moment(event?.from_date).format('LL HH:mm:ssa')} {moment(event?.from_date).startOf().fromNow()}</p>
                                <p className="text-muted text-center mb-0">{event?.description}</p>
                                <p className="text-muted text-center">{event?.location}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <div className='mt-2'>
                    {/* <h2>Order Menu for Event</h2> */}
                    {menuItems.map((category, idx) => (
                        <Card key={idx} className='mb-4 shadow-sm border-0'>
                            <CardHeader className='border-0'>
                                <FontAwesomeIcon icon={categoryIcons[category.category_id]} /> {category.category_name}
                            </CardHeader>
                            <CardBody>
                                {category.items.map((item) => (
                                    <FormGroup row key={item.item_id}>
                                        <Label sm={10}>{item.item_name}</Label>
                                        <Col sm={2}>
                                            <InputGroup size="sm">
                                                <Button
                                                    color="danger"
                                                    // className='border-0'
                                                    outline
                                                    onClick={() => decrementCountBtn(item.item_id)}
                                                >
                                                    <FontAwesomeIcon icon={faMinus}/>
                                                </Button>
                                                <Input
                                                    className="border-0 text-center"
                                                    placeholder="0"
                                                    type="number"
                                                    value={selectedItems[item.item_id]?.quantity || ''}
                                                    readOnly
                                                    bsSize="sm"
                                                />
                                                <Button
                                                    color="success"
                                                    // className='border-0'
                                                    outline
                                                    onClick={() => incrementCountBtn(item)}
                                                >
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                ))}
                            </CardBody>
                        </Card>
                    ))}
                </div>

                <Row className='mt-2 mb-5'>
                    <Col>
                        <Button 
                        disabled={!hasSelectedItems || saveLoading} 
                        onClick={handleOrderSubmit}
                        color="dark" type="submit" 
                        className="custom-button rounded-0">
                            {saveLoading && <Spinner size="sm"/>}
                            {!saveLoading && <FontAwesomeIcon icon={faSave}/>} Submit Order
                        </Button>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    )
}

export default EventOnlineMenuOrder;