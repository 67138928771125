import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

const DeactivateAccountDescription = () => {
    return (
        <React.Fragment>
            <div className="App">
                <Container fluid className="vh-100 d-flex align-items-center justify-content-center gx-0">
                    <Row className="w-100 gx-0" style={{ height: '100%' }}>
                        {/* Left Section (Image Section) */}
                        <Col md="6" className="p-0 black-background d-flex align-items-center justify-content-center">
                            <div className="cover-images w-100 d-flex justify-content-center h-100">
                                <img src="/logo-black-bg.png" alt="Cover" className="img-fluid h-100" />
                            </div>
                        </Col>

                        {/* Right Section (Instructions) */}
                        <Col md="6" className="p-0 yellow-background d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center">
                                <div className='w-50'>
                                    <h2 className="mb-4 text-center">How to Deactivate Your Account</h2>
                                    <p className="lead text-center">
                                        Follow the steps below to deactivate your account:
                                    </p>
                                    <ol className="text-left">
                                        <li>Login to your account using your credentials.</li>
                                        <li>Go to your <strong>Profile</strong> page by clicking on your username or avatar in the top-right corner.</li>
                                        <li>Scroll down to the bottom of your profile page where you will find the <strong>"Deactivate Account"</strong> button.</li>
                                        <li>Click the <strong>Deactivate Account</strong> button.</li>
                                        <li>Confirm your decision in the pop-up window to deactivate your account.</li>
                                    </ol>
                                    <p className='text-center'>
                                        If you change your mind later, you can contact our support team to reactivate your account.
                                    </p>
                                    <Link to="/login">
                                        <div className="d-grid">
                                            <Button 
                                                color="dark"
                                                className="custom-button rounded-0"
                                            >Go to Login</Button>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default DeactivateAccountDescription;