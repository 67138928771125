import { 
    GET_BANNERS_FAIL,
    GET_BANNERS_LOADING,
    GET_BANNERS_SUCCESS,
    POST_BANNERS_FAIL, 
    POST_BANNERS_LOADING, 
    POST_BANNERS_SUCCESS } from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";


export const getBannersAct = ({location,media}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_BANNERS_LOADING
        })
    
        AxiosHelper.get(`/templates/uploaded-banners`,{
            params:{
                location:location,
                media:media
            }
        }).then(
            (res) => {
                dispatch({
                    type:GET_BANNERS_SUCCESS,
                    payload:res.data,
                });
                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_BANNERS_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const uploadBannersAct = (formData) => async (dispatch) => {
    dispatch({
        type: POST_BANNERS_LOADING
    });

    try {

        const response = await AxiosHelper.post('/templates/upload-banners', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        dispatch({
            type: POST_BANNERS_SUCCESS,
            payload: response.data.data
        });

        return response.data; // Return the response data
    } catch (error) {
        dispatch({
            type: POST_BANNERS_FAIL,
            payload: error.response
                ? error.response.data
                : { error: 'Something went wrong, try again' }
        });
        throw error; // Rethrow the error to be caught by the caller
    }
};