import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, CardText, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { GlobalStore } from "../../../StateStores";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uploadBannersAct } from "../../../Actions/Masters/BannerManagerActs";

const BannerUpload = ()=>{
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [form,setForm] = useState({location:'',media:''});
    const [formErrors,setFormErrors] = useState({
        location:'Event name is required',
        media:'Contact name is required',
    });

    const {
        MasterDispatch,
        MasterState:{
            uploadBannerState:{data,error,loading},
        }} = useContext(GlobalStore);

    const handleFileImageChange = (e) => {
        let selectedImage = e.target.files[0]
        const allowedTypes = ["image/jpeg","image/png"];

        if(!allowedTypes.includes(selectedImage?.type)){
            toast.error("Only JPEG and PNG images are allowed.");
        }else{
            const MIN_FILE_SIZE = 26556 // 25 KB
            const MAX_FILE_SIZE = 464696 // 453 KB

            if (MAX_FILE_SIZE >= selectedImage?.size) {
                const files = Array.from(e.target.files);
                //Create the copy
                const newSelectedFiles = [...selectedFiles, ...files];
                //Validation
                if (newSelectedFiles.length > 4) {
                    toast.error("You can only upload up to 4 images.");
                } else {
                    // Read and preview images
                    const previews = files.map((file) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            setImagePreviews([...imagePreviews, reader.result]);
                        };
                    });
                    setSelectedFiles(newSelectedFiles);
                    setFormValid(newSelectedFiles.length > 0);
                }
                
            }else{
                toast.error("File size is too large.");
            }
        }

    };

    const handleDelete = (index) => {
        const updatedFiles = [...selectedFiles];
        const updatedPreviews = [...imagePreviews];
        updatedFiles.splice(index, 1);
        updatedPreviews.splice(index, 1);
        setSelectedFiles(updatedFiles);
        setImagePreviews(updatedPreviews);
        setFormValid(updatedFiles.length > 0);
      };

    const onChange = ({name,value}) => {
        setForm((prev) => ({ ...prev, [name]: value }));
        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        // Append product data as fields to the FormData object
        Object.keys(form).forEach(key => {
            formData.append(key, form[key]);
        });

        if(formValid){
            try {
                //Post the image to the sefver
                const response = await uploadBannersAct(formData)(MasterDispatch);
    
                if(response.status){
                    toast.success(response.message);
                }else{
                    toast.error(response.message);
                }
    
            } catch (error) {
                toast.error(error);
            }
        }

    }

    return(
        <React.Fragment>
            <Form onSubmit={onSubmit} encType="multipart/form-data">
                <Row>
                    <Col xl={8}>
                        <div className="d-flex justify-content-center p-">
                            {selectedFiles.map((file,index)=>(
                                <Card className="template-card rounded-0 p-0 border-0">
                                    <CardBody className='p-0'>
                                        <div className="template-image-container">
                                        {imagePreviews[index] && 
                                                <img src={imagePreviews[index]} alt="Preview" className="template-image card-img-top" />}
                                        </div>
                                        <CardText className='p-1 text-capitalize fs-12'>{file.name}</CardText>
                                        <Button 
                                            size="sm" 
                                            color="danger"
                                            className='rounded-0 custom-close-button'
                                            onClick={() => handleDelete(index)}>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </CardBody>
                                    <CardFooter className='bg-white p-0 border-0'></CardFooter>
                                </Card>
                            ))}
                        </div>
                    </Col>
                    <Col xl={4}>
                       <React.Fragment>
                            <Row>
                                <Col>
                                    <FormGroup className="">
                                        <Label for="fileUpload">Select Image / file</Label>
                                        <Input type="file" 
                                            size="sm"
                                            id="fileUpload" 
                                            multiple 
                                            accept="image/png, image/jpeg"
                                            onChange={handleFileImageChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="">
                                        <Input type="select" 
                                            size="sm"
                                            onChange={(e)=>onChange({name:'media',value:e.target.value})}>
                                                <option value="">-- Media --</option>
                                                <option value="app">App</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="">
                                        <Input type="select" 
                                            size="sm"
                                            onChange={(e)=>onChange({name:'location',value:e.target.value})}>
                                                <option value="">-- Location --</option>
                                                <option value="home">home</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="mt-2 form-group">
                                <div className="col-md-2s">
                                    <Button 
                                    color="dark" 
                                    className="custom-button rounded-0" 
                                    disabled={!formValid}
                                    size="sm">
                                        {loading && <Spinner size="sm"/>}
                                        {!loading && <FontAwesomeIcon icon={faUpload}/>} Upload</Button>
                                </div>
                            </div>
                        </React.Fragment>

                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default BannerUpload;