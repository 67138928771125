import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { createFoodMenuItem, getFoodMenuCategories } from '../../../Actions/Masters/FoodMenuActs';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const FoodMenuAddItem = ({setItems,itemList})=>{
    const [formValid, setFormValid] = useState(false);
    const [foodMenuCategories, setFoodMenuCategories] = useState([]);
    const [form,setForm] = useState({
        item_name:'',
        description:'',
        category_id:'',
        is_vegan:false,
        is_gluten_free:false});
    const [formErrors,setFormErrors] = useState({
        item_name:'Item name name is required',
        description:'Description name is required',
        category_id:'Category is required',
    });

    const {
        FoodMenuMasterDispatch,
        FoodMenuMasterState:{
            postFoodMenuItem:{data:saveData,error:saveError,loading:saveLoading},
            foodMenuCategory:{data:categories,error,loading},
        }} = useContext(GlobalStore);

    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    /**
     * Method that used to request the food menu category
     */
    const loadFoodMenuCategories = async ()=>{
        try {
            const response = await getFoodMenuCategories({})(FoodMenuMasterDispatch);
            setFoodMenuCategories(response)
        } catch (error) {
            // toast.error(error.message)
        }
    }

    useEffect(()=>{
        loadFoodMenuCategories();
    },[])


    const onChange = ({name,value}) => {
        setForm((prev) => ({ ...prev, [name]: value }));
        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(formValid){
            createFoodMenuItem(form)(FoodMenuMasterDispatch)((res)=>{
                if(res.status){
                    toast.success(res.message);
                    //Pre-Append category to the main state/list
                    setItems([res.data[0],...itemList])
                    setForm({
                        item_name:'',
                        description:'',
                        category_id:'',
                        is_vegan:false,
                        is_gluten_free:false
                    });
                    setFormErrors({
                        item_name:'Item name name is required',
                        description:'Description name is required',
                        category_id:'Category is required',
                    });

                }else{
                    toast.error(res.message)
                }
            })
        }
    };

    return(
        <React.Fragment>
            <h6>Add New Menu Item</h6>
            <hr/>
            <Form onSubmit={handleSubmit}>
                <FormGroup row>
                    <Label for="itemName" sm={2}>Item Name</Label>
                    <Col sm={10}>
                        <Input
                            type="text"
                            name="itemName"
                            id="itemName"
                            className="rounded-0"
                            placeholder="Enter item name"
                            value={form?.item_name}
                            onChange={(e) => onChange({name:'item_name',value:e.target.value})}
                            valid={formErrors.item_name === ''}
                            invalid={formErrors.item_name !== ''}
                        />
                        {formErrors.item_name && <FormFeedback>{formErrors.item_name}</FormFeedback>}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="description" sm={2}>Description</Label>
                    <Col sm={10}>
                        <Input
                            type="textarea"
                            name="description"
                            id="description"
                            className="rounded-0"
                            placeholder="Enter item description"
                            value={form?.description}
                            onChange={(e) => onChange({name:'description',value:e.target.value})}
                            valid={formErrors.description === ''}
                            invalid={formErrors.description !== ''}
                        />
                        {formErrors.description && <FormFeedback>{formErrors.description}</FormFeedback>}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for="category" sm={2}>Category</Label>
                    <Col sm={10}>
                        <Input
                            type="select"
                            name="category"
                            id="category"
                            className="rounded-0"
                            value={form?.category_id}
                            onChange={(e) => onChange({name:'category_id',value:e.target.value})}
                            valid={formErrors.category_id === ''}
                            invalid={formErrors.category_id !== ''}
                        >
                            <option value="">Select a category</option>
                            {(categories || []).map((cat) => (
                                <option key={cat.category_id} value={cat.category_id}>
                                    {cat.category_name}
                                </option>
                            ))}
                        </Input>
                        {formErrors.category_id && <FormFeedback>{formErrors.category_id}</FormFeedback>}
                    </Col>
                </FormGroup>

                <FormGroup check>
                    <Label check>
                    <Input
                        type="checkbox"
                        className="rounded-0"
                        value={form?.is_vegan}
                        onChange={(e) => onChange({name:'is_vegan',value:e.target.checked})}
                    />{' '}
                    Vegan
                    </Label>
                </FormGroup>

                <FormGroup check>
                    <Label check>
                    <Input
                        type="checkbox"
                        className="rounded-0"
                        value={form?.is_gluten_free}
                        onChange={(e) => onChange({name:'is_gluten_free',value:e.target.checked})}
                    />{' '}
                    Gluten-Free
                    </Label>
                </FormGroup>
                <hr/>
                <div className='d-flex justify-content-end mt-2'>
                    <Button 
                        disabled={!formValid} 
                        color="dark" type="submit" className="custom-button rounded-0">
                            {saveLoading && <Spinner size="sm"/>}
                            {!saveLoading && <FontAwesomeIcon icon={faSave}/>} 
                            <span className='ms-1'>Save</span>
                    </Button>
                </div>
            </Form>
        </React.Fragment>
    )
}

export default FoodMenuAddItem;