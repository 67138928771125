import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Button, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { GlobalStore } from "../../../StateStores";
import { BroadcastSender } from "../../../Actions/Masters/BroadcastManagerActs";
import { toast } from "react-toastify";

const BroadcastOptions = ({event,actionType,recipients})=>{
    const [message, setMessage] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState([]);

    const {
        BroadcastDispatch,
        BroadcastState:{
            sendBroadCast:{data,error,loading},
        }} = useContext(GlobalStore);

    const handleCheckboxChange = (recipient) => {
        setSelectedRecipients((prev) =>
            prev.includes(recipient)
                ? prev.filter((r) => r !== recipient)
                : [...prev, recipient]
        );
    };

    const handleSelectAll = () => {
        if (selectedRecipients.length === recipients.length) {
            setSelectedRecipients([]); // Uncheck all
        } else {
            setSelectedRecipients(recipients); // Check all
        }
    };

    const handleBroadcast = async () => {
        
        try {
            const response = await BroadcastSender({
                event_date:event?.start,
                event_name:event?.event_name,
                channel:actionType,
                message:message,
                recipients:selectedRecipients
            })(BroadcastDispatch);

            if(response.status){
                toast.success(response.message)
            }
        } catch (error) {
            toast.error(error.message)
        }
    };

    return(
        <React.Fragment>
            <div className="d-flex flex-column h-100">
                <div className="p-3">
                    <h6>Broadcast via {actionType === 'whatsapp' ? 'WhatsApp' : actionType === 'sms' ? 'SMS' : 'SMS Reminder'}</h6>
                    <Form>
                        <FormGroup>
                            <Label for="message" className="text-muted fs-12">Message</Label>
                            <Input
                                type="textarea"
                                name="message"
                                id="message"
                                readOnly={actionType === 'sms'}
                                className={actionType === 'sms' && 'bg-light'}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                </div>

                <div className="flex-grow-1 overflow-auto px-3">
                    <FormGroup>
                        <div className="d-flex justify-content-between">
                            <Label>Recipients</Label>
                            <Button color="link" onClick={handleSelectAll}>
                                {selectedRecipients.length === recipients.length ? "Unselect All" : "Select All"}
                            </Button>
                        </div>
                        <ListGroup>
                            {recipients.map((recipient) => (
                                <ListGroupItem key={recipient.user_ticket_id} className="d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange(recipient)}
                                        checked={selectedRecipients.includes(recipient)}
                                        className="me-2"
                                    />
                                    <div>
                                        <div><strong>{recipient.sold_by}</strong></div>
                                        <small className="text-muted">{recipient.mobile}</small><br/>
                                        <small className="text-muted">{recipient.card_code}</small>
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </FormGroup>
                </div>

                <div className="mt-auto p-3a border-top">
                    <Button 
                        color="primary"
                        size='sm' 
                        disabled={selectedRecipients.length === 0}
                        className='w-100 rounded-0 yellow-background fs-color-black border-0'
                        onClick={handleBroadcast}
                        >
                            {loading && <Spinner size="sm"/>}
                            {!loading && <span className="icon-circle"><FontAwesomeIcon icon={faBullhorn}/></span>}
                            <span className='ms-1'>Broadcast</span>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BroadcastOptions;