export default {
    postFoodMenuCategory:{
        data:[],
        error:null,
        loading:false,
    },foodMenuCategory:{
        data:[],
        error:null,
        loading:false,
    },postFoodMenuItem:{
        data:[],
        error:null,
        loading:false,
    },foodMenuItems:{
        data:[],
        error:null,
        loading:false,
    },getEventMenuItem:{
        data:[],
        error:null,
        loading:false,
    },createEventMenuItem:{
        data:[],
        error:null,
        loading:false,
    },getEventSelectedMenuItems:{
        data:[],
        error:null,
        loading:false,
    },placeOrder:{
        data:[],
        error:null,
        loading:false,
    }
}