import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../Layouts/Header';
import { Badge, Button, Card, CardBody, CardText, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, UncontrolledDropdown } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { getEventsAct } from '../../../Actions/Masters/EventCategoriesActs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faEdit, faEye, faMap, faMapPin, faPlus, faStickyNote, faTicket, faTicketAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import EventCreate from './EventCreate';
import EventTicketTypesForm from './EventTicketTypesForm';
import UserTicketsForm from './OrderTicketsForm';
import { NavLink } from 'react-router-dom';
import InvitationTemplateViewer from '../InvitationTemplates/InvitationTemplateViewer';
import BreadCrumb from '../../Common/BreadCrumb';
import EventCardList from '../../Common/EventCardList';
import { toast } from 'react-toastify';

const EventList = ()=>{
    const [currentView, setCurrentView] = useState('event');
    const [modal, setModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedEvent, setSelectedEvent] = useState({});
    const [futureEvents, setFutureEvents] = useState([]);
    const [todayEvents, setTodayEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [filter, setFilter] = useState('Upcoming Events');

    const {
        MasterDispatch,
        MasterState:{
            getEvents:{data,error,loading}
        }} = useContext(GlobalStore);

    const togOpenModal = (type)=>{
        setCurrentView(type);
        setModal(!modal);
    }

    useEffect(()=>{
        const fetchEventList = async ()=>{
            try {
                let response = await getEventsAct({approval:1})(MasterDispatch);
                if(response.status === 'Success'){
                    const currentDate = moment().startOf('day');  // Start of today's date

                    // Separate events into todayEvents and futureEvents
                    const todayEvents = [];
                    const futureEvents = [];

                    response?.data?.forEach(event => {
                        const eventDate = moment(event.from_date).startOf('day');

                        if (eventDate.isSame(currentDate)) {
                            todayEvents.push(event);
                        } else if (eventDate.isAfter(currentDate)) {
                            futureEvents.push(event);
                        }
                    });
                    

                    setTodayEvents(todayEvents);
                    setFutureEvents(futureEvents);
                    setFilteredEvents(futureEvents);
                }
            } catch (error) {
                toast.error(error.message)
            }
        }
        fetchEventList()
    },[])

    const setlectEventAct = (event,type)=>{
        setSelectedEvent(event)
        togOpenModal(type)
    }

    const onCloseModal = ()=>{
        setSelectedEvent({})
        setCurrentView('event');
    }

    useEffect(() => {
        applyFilter();
    }, [filter, futureEvents]);


    const applyFilter = () => {
        const currentDate = moment().startOf('day');
        let filtered;
    
        switch (filter) {
            case 'Next Week Events':
                filtered = futureEvents.filter(event =>
                    moment(event.from_date).isBetween(currentDate, moment().add(1, 'week').endOf('day'))
                );
                break;
            case 'Next Month Events':
                const startOfNextMonth = currentDate.clone().add(1, 'month').startOf('month');
                const endOfNextMonth = currentDate.clone().add(1, 'month').endOf('month');
                filtered = futureEvents.filter(event =>
                    moment(event.from_date).isBetween(startOfNextMonth, endOfNextMonth)
                );
                break;
            case 'Upcoming Events':
            default:
                filtered = futureEvents;
                break;
        }
    
        setFilteredEvents(filtered);
    };

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>
            <Modal
            onClosed={(e)=>onCloseModal()}
            className='custom-modal rounded-0 border-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        <FontAwesomeIcon icon={selectedEvent.id? faEdit:faPlus}/> 
                        <span className='ms-1'>{selectedEvent.id? 'Edit':'Create'} Event</span> {selectedCategory?.name && `- ${selectedCategory?.name}`}</h5>
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <Row className='g-3 p-3'>
                        <Col>

                        {currentView === 'event' && (
                            <EventCreate
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                ctrlModal={togOpenModal}
                                selectedEvent={selectedEvent}
                                />
                        )}
                        
                        {currentView === 'ticket_types' && (
                            <EventTicketTypesForm
                                selectedEvent={selectedEvent}
                            />
                        )}

                        {currentView === 'sell_ticket' && (
                            <UserTicketsForm
                                selectedEvent={selectedEvent}
                            />
                        )}

                        {currentView === 'event_template' && (
                            <InvitationTemplateViewer 
                                selectedEvent={selectedEvent}/>
                        )}
                            
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>

            <Container fluid>
                <BreadCrumb 
                title="Event List"
                subTittle="List of created events"
                pageTitle="Event"/>

                {loading && (
                    <div className='d-flex justify-content-center p-3'>
                        <Spinner color='dark' size="sm"/>
                    </div>
                )}

                <p>Today</p>

                <div>
                    {todayEvents.length ? todayEvents.map((event, index) => (
                        <EventCardList key={event.id} event={event} setlectEventAct={setlectEventAct}/>
                    )) : <p className='text-muted'>No events today.</p>}
                </div>

                <div className='d-flex justify-content-between mt-2'>
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="div" className="text-reset dropdown-btn" role='button'>
                                <div>{filter}</div>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setFilter('Next Week Events')}>Next Week Events</DropdownItem>
                                <DropdownItem onClick={() => setFilter('Next Month Events')}>Next Month Events</DropdownItem>
                                <DropdownItem onClick={() => setFilter('Upcoming Events')}>Upcoming Events</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div>
                        <div className="d-flex justify-content-end">
                            {/* <Button 
                                size="sm" 
                                color="success"
                                outline
                                className="rounded-0"
                                onClick={(e) => togOpenModal('event')}
                                >
                            <FontAwesomeIcon icon={faPlus} />
                            </Button> */}
                        </div>
                    </div>
                </div>

                <div>
                    {filteredEvents.length ? filteredEvents.map((event, index) => (
                        <EventCardList key={event.id} event={event} setlectEventAct={setlectEventAct}/>
                    )) : <p className='text-muted'>No {filter.toLowerCase()}.</p>}
                </div>

            </Container>
        </React.Fragment>
    )
}

export default EventList;