import React from 'react';
import EditActionButton from './EditActionButton';
import TicketTypesAction from './TicketTypesAction';
import SellTicketAction from './SellTicketAction';
import EventTemplateAction from './EventTemplateAction';
import EventTablesAction from './EventTablesAction';
import EventMenuItemAction from './EventMenuItemAction';
import EventAccessManagerAction from './EventAccessManagerAction';

const ActionButtons = ({
    event,
    selectedCategory, 
    setSelectedCategory,})=>{

    return(
        <React.Fragment>
            <div className='d-flex'>
                <EditActionButton 
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>

                <TicketTypesAction
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>

                <SellTicketAction 
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>

                <EventTemplateAction 
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>

                <EventTablesAction
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>

                <EventMenuItemAction
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>

                <EventAccessManagerAction
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>
            </div>
        </React.Fragment>
    )
}

export default ActionButtons;